import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import Home from "./index";
import VoucherList from "./VoucherList";
import CustomHeader from '../../shared/CustomHeader';
import WalletRecords from "./WalletRecords";
import { useSelector } from 'react-redux';
const Navigation = () => {
    const NavigationStack = createStackNavigator();
    const { strings } = useSelector(state => state.locale)

    return (
        <NavigationStack.Navigator
            initialRouteName='LoyaltyHome'
            screenOptions={{
                header: () => <CustomHeader title={strings?.["Wallet"]} basic />,
            }}
        >
            <NavigationStack.Screen name="LoyaltyHome"
                component={Home}
                options={{
                    header: () => <CustomHeader title={strings?.["Wallet"]} basic />,
                }} />
            <NavigationStack.Screen name="VoucherList"
                component={VoucherList}
                options={{
                    header: (e) => <CustomHeader title={strings?.[e?.route?.params?.title] || 'Vouchers'} basic />,
                }} />
            <NavigationStack.Screen name="WalletRecords"
                component={WalletRecords}
                options={{
                    header: () => <CustomHeader title={strings?.["Wallet Records"]} basic />,
                }} />
        </NavigationStack.Navigator>

    );
};

export default Navigation;