import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView, TouchableOpacity, View } from 'react-native';
import ListCard from '../../shared/ListCard';
import { CoinBGSVG, DiscountBGSVG } from '../../../constants/imgs';
import { Text } from '../../shared/custom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { redColor } from '../../../constants/color';

const VoucherList = () => {
    const [selected, setSelected] = useState('all');
    const { walletLogs = [] } = useSelector(state => state?.user);
    const { strings } = useSelector(state => state.locale)
    const [state, setState] = useState([])
    useEffect(() => {
        let data = walletLogs?.map((item) => {
            return {
                title: item?.type === 'credit' ? strings?.['Points collected'] : strings?.['Points redeemed'],
                desc: moment(item?.created).format('DD/MM/YYYY'),
                img: item?.type === 'credit' ? CoinBGSVG : DiscountBGSVG,
                value: item?.amount,
                type: item?.type,
                valueColor: item?.type === 'credit' ? '#04CE00' : redColor
            }
        });
        switch (selected) {
            case 'expense':
                data = data?.filter(item => item?.type !== 'credit')
                break;
            case 'income':
                data = data?.filter(item => item?.type === 'credit')
                break;
            default:
                break;
        }
        data = data?.sort((a, b) => b.created - a.created)
        setState(data || [])
    }, [walletLogs, selected])
    return (
        <View >
            <View>
                <FlatList
                    style={{
                        // flex: 0.01,
                        paddingVertical: 16
                    }}
                    contentContainerStyle={{
                        paddingHorizontal: 16,
                    }}
                    ItemSeparatorComponent={() => <View style={{ width: 8 }} />}
                    data={[{
                        key: 'all',
                        title: strings?.['All'],
                    }, {
                        key: 'expense',
                        title: strings?.['Expense'],
                    }, {
                        key: 'income',
                        title: strings?.['Income'],
                    }]}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={({ item }) => {
                        return <TouchableOpacity
                            onPress={() => setSelected(item?.key)}
                            key={item?.key} style={{
                                paddingHorizontal: 16,
                                paddingVertical: 8,
                                borderRadius: 37,
                                backgroundColor: selected === item?.key ? `${redColor}20` : '#F3F3F3',
                            }}>
                            <Text style={{
                                color: selected === item?.key ? redColor : '#383838'
                            }}>
                                {item?.title}
                            </Text>
                        </TouchableOpacity>
                    }}
                />
            </View>

            <ScrollView contentContainerStyle={{ paddingBottom: 150 }} >
                {
                    state?.map((item, index) => {
                        return <View key={index}>
                            <ListCard data={item} paddingVertical={8} size={44} />
                        </View>
                    })
                }


            </ScrollView>
        </View>
    );
};

export default VoucherList;