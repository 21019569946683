import { createAction } from 'redux-action';
export const GET_OFFERS = 'GET_OFFERS';
export const SET_ACTIVE_OFFER = 'SET_ACTIVE_OFFER';
export const GetOffers = createAction(GET_OFFERS, (list) => {
    return list
});
const initial = {
    offers: [],
    activeOffer: false
};
const setActiveOffer = (offers) => {
    let newUserOffers = offers?.find(rec => rec.newUser);
    let globalOffers = offers?.find(rec => rec.global);
    return newUserOffers || globalOffers || false
};


let ex = (state = initial, action) => {
    let offers = [];
    let activeOffer = false;
    switch (action.type) {
        case GET_OFFERS:
            offers = action?.payload || [];
            activeOffer = setActiveOffer(offers);
            return { offers, activeOffer }
        case SET_ACTIVE_OFFER:
            return { ...state, activeOffer: action.payload }
        default:
            return state;
    }
};
export default ex