import React, { useEffect, useRef, useState } from "react";
import { Platform, View, I18nManager, Dimensions, Linking, TouchableOpacity } from "react-native";
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { NavigationContainer, DarkTheme, DefaultTheme, useNavigation, useNavigationContainerRef } from '@react-navigation/native'
// import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from './component/home'
import Search from "./component/search";
import { backgroundColor, mainColor, redColor } from "./constants/color";
import CustomHeader from "./component/shared/CustomHeader";
import { HomeSVG, Recent, Profile, SearchSVG, Basket } from "./constants/imgs";
import ProfilePage from './component/profile'
import Image from './component/shared/customImg'
import ProducPage from "./component/ProductPage";
import UserInfo from './component/profile/Info'
import Locations from "./component/profile/Locations";
import Favourits from "./component/profile/Favourits";
import Languages from "./component/profile/Languages";
import PreviousOrders from "./component/profile/PreviousOrders";
import AddLocation from "./component/Locations";
import LocationDetails from "./component/Locations/locationDetails";
import Cart from "./component/cart";
import StatusPage from "./component/statusPage";
import ResultsView from "./component/ResultsView";
import { firestore, auth, messaging, analytics } from "./constants/firebase/config";
import { useDispatch, useSelector } from "react-redux";
import { GetBanner, GetBrands, GetCategories, GetPrds, GetVouchers } from "./reducers/prds";
import { FlushOrder } from "./reducers/order";
import { GetOffers } from './reducers/offers'
import Login from "./component/login";
import Verify from "./component/login/verify";
import { FlushUser, GetUser } from './reducers/user'
import { GetActiveOrders } from './reducers/orders'
import Chat from "./component/profile/chat/chat";
import { localeSetLanguage } from "./reducers/locale";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SplashScreen from 'react-native-splash-screen';
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import ArabicEnglishScreen from "./component/ArabicEnglishScreen";
import { CheckStoreClosed, CompareVersions, OptimizeLocationToPolygons, validatePromo } from "./helpers/helpers";
import DeviceInfo from 'react-native-device-info';
import { SetAlertMessage, SetAppPlaces, SetPickupAreas, SetServingAreas } from "./reducers/settings";
import Country from "./component/profile/Country";
import { GetCurrentLocation } from "./helpers/index";
import { requestLocationPermission } from "./helpers/index";
import RateOrder from "./component/shared/RateOrder";
import Payments from "./component/profile/Payments";
import AddPaymentMethod from "./component/Payment/CardPayment";
import Privacy from "./component/profile/Privacy";
import DeleteAccount from "./component/profile/DeleteAccount";
import { REACT_APP_ORDERS_COLLECTION } from "./constants/variables/index";
// import Ghadeni from "./component/Ghadeni/navigation";
import LottieView from "./component/shared/lottieView/index";
import Loyalty from "./component/profile/loyalty/navigator";
const Tab = createBottomTabNavigator();

async function requestUserPermission() {
    let token = '';

    if (Platform.OS === 'ios') {
        const authorizationStatus = await messaging().requestPermission();
        console.log("authorizationStatusauthorizationStatus", authorizationStatus);

        if (authorizationStatus) {
            token = await messaging().getToken();
            console.log("tokentoken", token);

        }
    } else {
        try {
            token = await messaging().getToken();

        } catch (err) {

        }
    }
    return token
}
const MyTheme = {
    ...DefaultTheme,
    colors: {
        primary: redColor,
        background: mainColor,
        card: mainColor,
        text: '#fff',
        border: backgroundColor,
        notification: mainColor,
    },
};
const HomeStack = createStackNavigator();


function HomeStackScreen({ }) {
    const { strings } = useSelector((state) => state.locale);

    const [initialScreen, setInitialScreen] = useState(null);
    const dispatch = useDispatch();
    const [hideSplashScreen, setHideSplashScreen] = useState(false);
    useEffect(() => {
        (async () => {
            let language = await AsyncStorage.getItem('language');
            if (!language) {
                setInitialScreen('ArabicEnglishScreen');
            } else {
                setInitialScreen('HomeScreen');
            }
        })()

    }, [dispatch])



    useEffect(() => {
        if (initialScreen) {
            if (Platform.OS === 'android' || Platform.OS === 'ios') {
                setTimeout(function () {
                    SplashScreen.hide();
                }, 3000);
            }
            setHideSplashScreen(true)
        }
    }, [initialScreen]);

    if (hideSplashScreen) {

        return (
            <HomeStack.Navigator
                screenOptions={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS

                }}
                initialRouteName={initialScreen}
            >


                <HomeStack.Screen name="Login" component={Login} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Verify" component={Verify} options={{
                    header: () => null,
                }} />

                <HomeStack.Screen name="HomeScreen" component={TapNavigator} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="ArabicEnglishScreen" component={ArabicEnglishScreen} options={{
                    header: () => null,
                }} />
                <HomeStack.Group screenOptions={{
                    presentation: 'modal',
                    cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS
                }}>
                    <HomeStack.Screen name="Product"
                        component={ProducPage}
                        options={{
                            header: () => null,
                            // animation: 'slide_from_bottom',

                        }} />
                </HomeStack.Group>

                <HomeStack.Screen name="UserInfo" component={UserInfo} options={{
                    header: () => <CustomHeader title={strings.generalInfo} basic />,

                }} />
                <HomeStack.Screen name="UserLocation" component={Locations} options={{
                    header: () => <CustomHeader title={strings.locations} basic />,

                }} />
                <HomeStack.Screen name="UserFavourits" component={Favourits} options={{
                    header: () => <CustomHeader title={strings.favourites} basic />,

                }} />
                <HomeStack.Screen name="wallet" component={Loyalty} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Languages" component={Languages} options={{
                    header: () => <CustomHeader title={strings.languages} basic />,

                }} />
                <HomeStack.Screen name="Country" component={Country} options={{
                    header: () => <CustomHeader title={strings.languages} basic />,

                }} />
                <HomeStack.Screen name="Privacy" component={Privacy} options={{
                    header: () => <CustomHeader title={strings.privacy} basic />,

                }} />
                <HomeStack.Screen name="DeleteAccount" component={DeleteAccount} options={{
                    header: () => <CustomHeader title={strings.DeleteAccount} basic />,

                }} />

                <HomeStack.Screen name="PreviousOrders" component={PreviousOrders} options={{
                    header: () => <CustomHeader title={strings.orders} basic />,

                }} />
                <HomeStack.Screen name="Choose your location" component={AddLocation} options={{
                    header: () => <CustomHeader title={strings.addLocation} basic />,


                }} />
                <HomeStack.Screen name="Address Details" component={LocationDetails}
                    options={{
                        header: () => <CustomHeader title={strings.addressDetails} basic />,
                    }} />
                <HomeStack.Screen name="Cart" component={Cart} options={{
                    header: () => <CustomHeader title={strings.cart} basic />,
                }} />
                <HomeStack.Screen name="Status Page" component={StatusPage} options={{
                    header: () => <CustomHeader title={strings.statusPage} basic />,

                }} />
                <HomeStack.Screen name="Chat" component={Chat} options={{
                    header: () => <CustomHeader title={strings.chat} basic />,

                }} />

                <HomeStack.Screen name="Results" component={ResultsView} options={{
                    // header: () => <CustomHeader title={strings.results} />,
                    header: () => null,


                }} />
                <HomeStack.Screen name="Payments" component={Payments} options={{
                    header: () => <CustomHeader title={strings.paymentMethods} basic />,
                }} />
                <HomeStack.Screen name="AddCard" component={AddPaymentMethod} options={{
                    header: () => <CustomHeader title={strings.addPaymentMethod} basic />,
                }} />
                {/* <HomeStack.Screen name="Ghadeni" component={Ghadeni} options={{
                    header: () => null,
                    presentation: 'modal',
                    cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS
                }} /> */}



            </HomeStack.Navigator>
        );
    }
    return null;

}
function TapNavigator() {
    const { strings } = useSelector((state) => state.locale);
    const insets = useSafeAreaInsets();
    const { ghadeniStatus, ghadini } = useSelector(state => state.settings);
    const [showGhadeni, setShowGhadeni] = useState(false);
    const navigation = useNavigation();
    const dispatch = useDispatch();
    // for ghadeni
    // useEffect(() => {
    //     console.log("ghadeniStatus", ghadeniStatus);

    //     setShowGhadeni(ghadeniStatus?.type === 'close');
    //     if (ghadeniStatus?.seconds) {
    //         let interval = setInterval(() => {
    //             setShowGhadeni(ghadeniStatus?.type !== 'close');
    //             dispatch(CheckStoreClosed(true));
    //         }, ghadeniStatus?.seconds);
    //         return () => {
    //             clearInterval(interval)
    //         }
    //     }
    // }, [ghadeniStatus])



    // for ghadeni
    // useEffect(() => {
    //     dispatch(CheckStoreClosed(true));
    // }, [ghadini?.active])

    return (<Tab.Navigator

        screenOptions={({ route }) => ({
            tabBarStyle: {
                backgroundColor: 'transparent',
                minHeight: 60,
                position: Platform.OS === 'web' ? 'fixed' : 'absolute',
                width: '100%',
                border: 'none',
                paddingHorizontal: 16,
                justifyContent: 'space-around',
                flexDirection: 'row'
            },
            tabBarItemStyle: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: insets.bottom > 0 ? 0 : 8,
            },
            tabBarBackground: () => (
                <View style={{
                    backgroundColor: mainColor,
                    width: '100%',
                    height: '100%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden',

                }} />
            ),
            tabBarAllowFontScaling: true,
            tabBarLabelStyle: {
                margin: 0,
                // paddingBottom: Platform.OS === 'web' || Platform.OS === 'android' ? 8 : 4,
                // fontSize: 20
            },

            tabBarIconStyle: {
                height: '100%'

            },
            tabBarIcon: ({ focused, color, size }) => {
                let iconName;
                switch (route.name) {
                    case 'Home':
                        iconName = HomeSVG;
                        break;
                    case 'Search':
                        iconName = SearchSVG;
                        break;
                    case 'Orders':
                        iconName = Recent;
                        break;
                    case 'Profile':
                        iconName = Profile;
                        break;
                    default: break;
                }
                // You can return any component that you like here!
                return <Image width={23} height={23} svg uri={iconName} fill={focused ? redColor : '#121212'} />;
            },
            tabBarActiveTintColor: redColor,
            tabBarInactiveTintColor: '#121212',
        })}>
        <Tab.Screen
            name="Home"
            component={Home}
            options={{
                title: strings.home,
                header: () => null,
            }} />
        <Tab.Screen name="Orders" component={PreviousOrders} options={{
            title: strings.orders,
            header: () => <CustomHeader title={strings.orders} basic hideBack />,
        }} />

        {/* {
            showGhadeni ?
                <Tab.Screen
                    name="Ghadeni"
                    component={Ghadeni}
                    options={{
                        title: 'Ghadeni',
                        header: () => null,
                        tabBarStyle: { display: 'none' }, // Add this line to hide the tab bar
                        tabBarButton: () => <TouchableOpacity
                            style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                            onPress={() => {
                                navigation.navigate('Ghadeni')

                            }}>
                            <LottieView
                                src={require('./assets/lottie/lottie.json')}
                            />
                        </TouchableOpacity>
                    }}
                /> :
                null
        } */}

        <Tab.Screen
            name="Search"
            component={Search}
            options={{
                title: strings.search,
                header: () => null,
            }}
        />
        <Tab.Screen name="Profile" component={ProfilePage} options={{
            title: strings.profile,

            header: () => <CustomHeader basic title={strings.profile} hideBack />,
        }} />
    </Tab.Navigator>)
}
function Routes() {
    const dispatch = useDispatch()
    const [branch, setBranch] = useState(false);
    const [rateOrder, ToggleRateOrder] = useState(false);
    const orders = useSelector(state => state.orders);
    const { location, locationTimings } = useSelector(state => state.order);
    const { allLocations, pickupLocations, country, orderRateDismissed } = useSelector(state => state.settings);
    const { strings } = useSelector((state) => state.locale);
    const currentUser = useSelector(state => state.user);
    const navigationRef = useNavigationContainerRef();
    useEffect(() => {

        (async () => {
            if (!country) {
                try {
                    await requestLocationPermission()
                    let location = await GetCurrentLocation();
                    console.log("location", location);
                    let { coords: { latitude, longitude } } = location || {};
                    let locatedCountry = await fetch(`https://nominatim.openstreetmap.org/reverse.php?lat=${latitude}&lon=${longitude}&zoom=3&format=jsonv2&addressdetails=1`);
                    locatedCountry = await locatedCountry.json();
                    console.log("locatedCountry?.countryName;", locatedCountry.address?.country_code);
                    if (['ae', 'sa'].includes(locatedCountry.address?.country_code)) {
                        dispatch({ 'type': 'SET_COUNTRY', 'payload': locatedCountry.address?.country_code });
                    } else {
                        dispatch({ 'type': 'SET_COUNTRY', 'payload': 'ae' });
                    }
                } catch (e) {
                    dispatch({ 'type': 'SET_COUNTRY', 'payload': 'ae' });
                    console.log("eeee", e);
                }
            }
            dispatch({ "type": 'SET_LOADING', "payload": false })

        })()

    }, [country])



    useEffect(() => {
        let unsubs = [];
        let lastOrder = Object.values(orders || {})?.sort((a, b) => b?.created - a?.created)?.[0];
        if (lastOrder) {
            const { status, rated } = lastOrder;
            if (status === 'completed' && !rated && (orderRateDismissed !== lastOrder.id)) {
                ToggleRateOrder(lastOrder.id)
            }
        }
        let isNewUser = Object.values(orders || {})?.length === 0;
        let offerRef = firestore().collection('offers').where('active', '==', true).onSnapshot((offers) => {
            if (offers.empty) {
                return dispatch(GetOffers(false))
            }
            let offersData = offers.docs.reduce((val, rec) => {
                let activeOrders = Object.values(orders || {})?.filter(r => r?.receipt?.code === rec?.data()?.code);
                let { error } = validatePromo({ data: { ...rec.data(), id: rec.id }, activeOrders: activeOrders?.length, isNewUser, branch: branch });
                if (!error) {
                    val.push({ ...rec.data(), id: rec.id })
                }
                return val
            }, [])
            dispatch(GetOffers(offersData))
        })
        unsubs.push(offerRef)

        return () => {
            unsubs?.forEach(unsub => {
                unsub()
            })
        }
    }, [orders, branch])

    // const handleNotification = async (remoteMessage) => {
    //     console.log("remoteMessage", remoteMessage);
    //     let { data: { page } } = remoteMessage || {}
    //     switch (page) {
    //         case 'rateOrder':
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // useEffect(() => {
    //     messaging().onNotificationOpenedApp(remoteMessage => {
    //         // handleNotification(remoteMessage)
    //         console.log('Notification caused app to open from background state:', remoteMessage);
    //     })
    //     messaging().onMessage(async remoteMessage => {
    //         // handleNotification(remoteMessage)
    //         console.log('A new FCM message arrived!', JSON.stringify(remoteMessage));
    //     })
    // }, [])

    useEffect(() => {
        let unSub;
        (async () => {
            if (location?.locationId) {
                unSub = await firestore().collection('delivering_areas').doc(location?.locationId).collection('timings').onSnapshot(snap => {
                    let data = snap.docs.reduce((val, rec) => {
                        val[rec.id] = rec.data()
                        return val
                    }, {})
                    dispatch({ type: 'SET_LOCATION_TIMINGS', payload: data })

                })
            } else {
                dispatch({ type: 'SET_LOCATION_TIMINGS', payload: {} })
            }
        })()
        return () => {
            if (unSub) {
                unSub()
            }
        }
    }, [location])

    useEffect(() => {


        switch (location?.type) {
            case 'pickup':
                setBranch(pickupLocations?.[location?.key]?.branch || false);
                break;
            default:
                setBranch(allLocations?.[location?.locationId]?.branch || false);
                break;
        }

    }, [location, allLocations, locationTimings]);

    //for ghadeni
    // useEffect(() => {
    //     dispatch(CheckStoreClosed(true));

    // }, [locationTimings])

    useEffect(() => {
        dispatch(SetAlertMessage(false));
    }, []);

    const openStore = () => {
        if (Platform.OS === 'android') {
            const GOOGLE_PACKAGE_NAME = 'com.tayba';
            Linking.openURL(`market://details?id=${GOOGLE_PACKAGE_NAME}`);
        } else {
            const link = `itms-apps://itunes.apple.com/us/app/id${1584107582}?mt=8`
            Linking.canOpenURL(link).then(supported => {
                supported && Linking.openURL(link);
            }, (err) => console.log(err));
        }
        _updateApp()
    };

    const _updateApp = () => {
        dispatch(SetAlertMessage({
            title: "Update Required",
            cancelText: "Update",
            msg: strings?.["New Version"],
            alert: true,
            onCancel: () => {
                openStore()
            },

        }))
    };
    useEffect(() => {
        firestore().collection('forceUpdate').doc(Platform.OS).onSnapshot((record) => {
            if (Platform.OS === 'android') {
                if (DeviceInfo?.getBuildNumber() < record?.data()?.buildNumber) {
                    _updateApp()
                }
            } else if (Platform.OS === 'ios') {
                if (DeviceInfo?.getVersion() === record?.data()?.version) {
                    if (DeviceInfo?.getBuildNumber() < record?.data()?.buildNumber) {
                        _updateApp()
                    }
                }
                try {
                    if (CompareVersions(record?.data()?.version, DeviceInfo?.getVersion())) {
                        _updateApp()
                    }
                } catch (error) {
                    console.log("errorerror", error);
                }
            }
        })
    }, []);
    useEffect(() => {
        let unsubs = [];
        [{ label: 'prds', action: GetPrds },
        ].map(item => {
            let ref = firestore().collection(item.label).where('active', '==', true)
            if (branch) {
                console.log("branchbranch", branch);
                ref = ref.where('branches', 'array-contains', branch)
            } else if (country) {
                ref = ref.where('country', 'array-contains', country)
            }
            let unsub = ref.onSnapshot(res => {
                let prds = res.docs.map(rec => {
                    return { ...rec.data(), id: rec.id }
                })
                dispatch(item.action(prds || []))
            })
            unsubs.push(unsub)
        })
        return () => {
            if (unsubs) {
                unsubs.forEach(unsub => {
                    unsub()
                })
            }
        }
    }, [branch, country])

    useEffect(() => {
        const lsnr = () => {
            if (Platform.OS === 'web') {
                analytics().setCurrentScreen(navigationRef?.getCurrentRoute()?.name)
            } else {
                analytics().logScreenView({
                    screen_name: navigationRef?.getCurrentRoute()?.name,
                })
            }

        }
        navigationRef?.addListener('state', lsnr);
        return () => {
            navigationRef?.removeListener('state', lsnr);
        }
    }, []);

    useEffect(() => {
        let unsubs = [];
        let selectedCountry = country || 'ae';
        (async () => {
            [{ label: 'prds_category', action: GetCategories },
            { label: 'banners', action: GetBanner },
            { label: 'brands', action: GetBrands },
            { label: 'vouchers', action: GetVouchers }].map(item => {
                let ref = firestore().collection(item.label).where('country', 'array-contains', selectedCountry).where('active', '==', true)
                let unsub = ref.onSnapshot(res => {
                    let prds = res.docs.map(rec => {
                        return { ...rec.data(), id: rec.id }
                    })
                    dispatch(item.action(prds || []))
                })
                unsubs.push(unsub)
            })

            let pickup_areasRef = firestore().collection('pickup_areas').where('active', '==', true).onSnapshot(snap => {
                let pickupLocations = snap.docs.reduce((val, rec) => {
                    let { country: supportedCountries = [] } = rec.data()
                    if (supportedCountries.includes(selectedCountry)) {
                        val[rec.id] = rec.data()
                    }
                    return val
                }, {})
                dispatch(SetPickupAreas(pickupLocations))

            })
            unsubs.push(pickup_areasRef)

            let delivering_areasRef = firestore().collection('delivering_areas').where('active', '==', true).onSnapshot((snap => {
                let supportedAreas = []
                let allLocations = {}
                snap.docs.map(rec => {
                    let data = rec.data()
                    let { areas, active, country: supportedCountries = [] } = data
                    if (active && supportedCountries.includes(selectedCountry)) {
                        // if (true) {
                        Object.keys(areas || {}).map(areaKey => {
                            let areaObject = areas?.[areaKey]
                            let { list } = areaObject || {}
                            let optimizedList = list?.map(location => {
                                return ({ ...location, id: rec.id, key: rec.id })
                            })
                            supportedAreas = supportedAreas?.concat(optimizedList || [])
                            return false
                        })
                    }
                    if (supportedCountries.includes(selectedCountry)) {
                        allLocations[rec.id] = data
                    }
                    return false
                })
                dispatch(SetServingAreas({ supportedAreas, allLocations }))

            }))
            unsubs.push(delivering_areasRef)




            if (Platform.OS === 'web') {
                let language = await AsyncStorage.getItem('language')
                dispatch(localeSetLanguage(language || 'en'))
            }

        })()
        return () => {
            if (unsubs) {
                unsubs.forEach(unsub => {
                    unsub()
                })
            }
        }
    }, [country])

    useEffect(() => {
        let unsub = [];

        let onLinePaymentRef = firestore().collection('settings').onSnapshot(snap => {
            let settings = snap?.docs?.reduce((val, rec) => {
                val[rec.id] = rec.data()
                return val
            }, {})
            console.log("settingssettings", settings);

            dispatch({ type: 'SET_SETTINGS', payload: settings })
        })
        unsub.push(onLinePaymentRef)
        let servingLocationsRef = firestore().collection('servingLocations').onSnapshot(snap => {
            let places = snap?.docs?.map(rec => {
                return { ...rec.data(), id: rec.id }
            })
            dispatch(SetAppPlaces(places))
        })
        unsub.push(servingLocationsRef)
        auth().onAuthStateChanged(async user => {
            if (user?.uid) {
                let token = '';
                try {
                    analytics().setUserId(user?.uid);
                    token = await requestUserPermission()
                } catch (error) {
                    console.log("couldnt get token");
                }
                let update = true
                let listner = firestore().collection('users').doc(user.uid).onSnapshot(async userSnap => {
                    if (userSnap?.exists) {
                        dispatch(GetUser({ ...userSnap?.data(), uid: user.uid }))
                        if (currentUser?.locations !== userSnap?.data()?.locations) {
                            console.log("location changed");
                            dispatch(OptimizeLocationToPolygons({ ...userSnap?.data(), uid: user.uid }));
                        }
                        if (update && (token !== userSnap.data()?.token)) {
                            update = false
                            try {
                                firestore().collection('users').doc(user.uid).update({ token: token })
                            } catch (error) {
                                console.log("couldnt", error);

                            }
                        }
                    } else {

                        let post = {
                            uid: user.uid,
                        }
                        if (user?.phoneNumber) {
                            post.phoneNumber = user?.phoneNumber
                        }
                        if (user?.email) {
                            post.email = user?.email
                        }
                        if (token !== userSnap?.data()?.token) {
                            post.token = token
                        }
                        firestore().collection('users').doc(user.uid).set(post, { merge: true })
                    }
                })
                unsub.push(listner)
                console.log("REACT_APP_ORDERS_COLLECTION", REACT_APP_ORDERS_COLLECTION);
                let orderListner = firestore().collection(REACT_APP_ORDERS_COLLECTION).where('customer.uid', '==', user?.uid).onSnapshot(orderSnaps => {
                    console.log("user?.uid", user?.uid);
                    let orders = orderSnaps?.docs?.map(r => { return { ...r.data(), id: r.id } })
                    dispatch(GetActiveOrders(orders || []))
                })
                unsub.push(orderListner)
                let vouchersListner = firestore().collection('users').doc(user?.uid).collection('vouchers').onSnapshot(voucherSnap => {
                    let vouchers = voucherSnap?.docs?.map(r => { return { ...r.data(), id: r.id } })
                    console.log("user?.vouchers", vouchers);

                    dispatch({ type: 'SET_USER_VOUCHERS', payload: vouchers || [] })
                    // dispatch(GetActiveOrders(orders || []))
                })
                unsub.push(vouchersListner)

            } else {
                dispatch(FlushUser())
                dispatch(FlushOrder())
            }
        })
        return () => {
            if (unsub.length) {
                unsub.forEach(unsub => {
                    unsub()
                })
            }
        }
    }, [])

    return (
        <NavigationContainer theme={MyTheme} ref={navigationRef}>
            <HomeStackScreen />
            {

                rateOrder ?
                    <RateOrder show={rateOrder} fromHome={true} dismiss={() => {
                        ToggleRateOrder(false)
                        dispatch({ type: 'ORDER_RATE_DISMISSED', payload: rateOrder })
                    }} /> : null
            }
        </NavigationContainer>

    );
}


export default Routes;