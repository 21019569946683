import React from "react";
import { TouchableOpacity, View } from 'react-native';
import { redColor } from "../../../constants/color";
import Swiper from "react-native-web-swiper";
import SlideComp from "./SlideComp";

const Carosel = ({ data, helpers }) => {
    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                <Swiper
                    loop
                    timeout={4}
                    springConfig={{ speed: 11 }}
                    key={data?.length}
                    minDistanceForAction={0.1}
                    positionFixed
                    containerStyle={{
                        width: '100%',
                        aspectRatio: 1.78,
                    }}
                    controlsProps={{
                        dotsTouchable: true,
                        nextTitleStyle: { display: 'none' },
                        dotsPos: 'bottom',
                        PrevComponent: ({ onPress }) => (
                            <TouchableOpacity onPress={onPress}>
                            </TouchableOpacity>
                        ),
                        DotComponent: ({ index, isActive, onPress }) => <View key={index} onPress={onPress} style={{ bottom: -13, marginHorizontal: 2, backgroundColor: isActive ? redColor : '#cacaca', width: 11, height: 2 }}></View>
                    }}
                >
                    {
                        data?.map(item => <SlideComp key={item.id} data={item} helpers={helpers} />)
                    }
                </Swiper>
            </View>
        </View>
    );
};



export default Carosel;
