import { FlatList, View } from 'native-base';
import React from 'react';
import ListCard from '../shared/ListCard';
import { useNavigation } from '@react-navigation/native';
import { backgroundColor } from '../../constants/color';
import { Trash } from '../../constants/imgs';
import { useDispatch } from 'react-redux';

const Privacy = () => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const DATA = [
        {
            id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
            title: 'Account Deletion',
            onPress: () => {
                navigation.navigate('DeleteAccount')
            },
            img: Trash
        }
    ];

    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            <FlatList
                contentContainerStyle={{ width: '100%', paddingTop: 16, paddingHorizontal: 12 }}
                data={DATA}
                numColumns={1}
                showsVerticalScrollIndicator={false}
                renderItem={(i) =>
                    <View key={i.item} style={{ width: '100%' }} >
                        <ListCard index={i.item.id} data={i.item} onPress={i.item.onPress} />
                    </View>
                }
                keyExtractor={item => item.id}
            />
        </View>
    );
};

export default Privacy;