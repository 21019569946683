import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { TextInput, View } from 'react-native'
import { mainColor } from '../../constants/color';
import ActionBtn from '../shared/ActionBtn';
import { useNavigation } from '@react-navigation/core';
import { getLocations, checkLocationsServed } from '../../helpers/helpers.js';
import Alert from '../shared/Alert';
import { useSelector } from 'react-redux';
import { strings } from '../../translations/index.web'

class NewLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 25.1903,
                lng: 55.2747,
            },
            addressObject: {},
            locations: {},
            alert: false
        };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    }
    async componentDidMount() {
        this.handleScriptLoad()
        let locations = await getLocations({ country: this.props?.settings?.country ? this.props.settings.country : 'ae' })
        this.setState({ locations })

    }

    handleScriptLoad() {
        var options = {
            types: ['establishment'],
            componentRestrictions: { country: this.props?.settings?.country ? this.props.settings.country : 'ae' },
            // language: 'en'
        };

        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options);

        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',
            'formatted_address', 'geometry', 'name', 'vicinity']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect);
    }
    handlePlaceSelect() {
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        // Check if address is valid
        let { formatted_address, geometry, address_components } = addressObject
        let paramArray = formatted_address?.split(', ')

        let address = address_components?.reduce((value, currentValue) => {
            if (currentValue.types.includes("neighborhood")) {
                value.area = currentValue.short_name
            }
            if (!value.area && currentValue.types.includes("sublocality")) {
                value.area = currentValue.short_name
            }
            if (currentValue.types.includes("route")) {
                value.street = currentValue.short_name
            }
            return value
        }, {})

        if (geometry) {
            addressObject.geometry = {
                location: {
                    lat: geometry?.location?.lat(),
                    lng: geometry?.location?.lng(),
                },
                viewport: {
                    south_west: {
                        lng: addressObject?.geometry?.viewport?.getSouthWest().lng(),
                        lat: addressObject?.geometry?.viewport?.getSouthWest().lat()
                    },
                    north_East: {
                        lng: addressObject?.geometry?.viewport?.getNorthEast().lng(),
                        lat: addressObject?.geometry?.viewport?.getNorthEast().lat()
                    }
                }
            }

            let center = { lat: geometry.location.lat(), lng: geometry.location.lng() }
            this.setState({ center, addressObject, address })
        }
    }
    render() {
        let { navigation } = this.props
        let { addressObject, center, address, locations, alert } = this.state
        return (
            <View style={{ flex: 1, alignItems: 'center' }}>
                {
                    alert && <Alert
                        alert
                        title={strings.sorry}
                        cancelText='dismiss'
                        msg={strings.notServingArea}
                        onCancel={() => this.setState({ alert: false })} />
                }
                <View style={{ position: 'absolute', zIndex: 9, width: '90%', marginVertical: 32 }}>
                    <TextInput

                        style={{ textAlign: 'left', outline: 'none', color: '#000', backgroundColor: mainColor, width: '100%', paddingVertical: 16, paddingHorizontal: 12, borderRadius: 8 }}
                        nativeID='autocomplete'
                        type='text'
                        // value={this.state.addressObject?.formatted_address?.split(', ')[0]}

                        onChangeText={(text) => this.setState({ addressObject: { ...this.state.addressObject, formatted_address: text } })}
                        placeholder={strings.search}
                    />

                </View>
                <GoogleMapReact
                    options={
                        { fullscreenControl: false }
                    }
                    bootstrapURLKeys={{ key: 'AIzaSyDKh65DXmzsGhOlqcpDTi_U_B1autbsgqc' }}
                    defaultCenter={this.state.center}
                    center={this.state.center}
                    // onBoundsChange={(e) => console.log("location", e)}
                    defaultZoom={16}
                >

                </GoogleMapReact>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: 16,
                    bottom: 0,
                    position: 'absolute',
                    width: '100%'
                }}>
                    <ActionBtn title={strings.confirmLocation} onPress={() => {
                        if (center && checkLocationsServed(locations.supportedAreas, center)) {
                            navigation.navigate('Address Details', { selectedRegon: { details: addressObject, latitude: center.lat, longitude: center.lng, address }, locationId: checkLocationsServed(locations.supportedAreas, center).id })
                            return
                        } else {
                            this.setState({ alert: true })
                        }
                    }
                    } />
                </View>


            </View >
        );
    }

};

// Wrap and export
export default function (props) {
    const navigation = useNavigation();

    return <NewLocation {...props} navigation={navigation} />;
}
