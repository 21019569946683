import AsyncStorage from '@react-native-async-storage/async-storage';
import user from './reducers/user';
import helpers from './reducers/prds';
import order from './reducers/order';
import orders from './reducers/orders';
import offer from './reducers/offers';
import locale from './reducers/locale';
import settings from './reducers/settings';
import localforage from 'localforage';

import { persistCombineReducers } from 'redux-persist'
import { Platform } from 'react-native';

const persistConfig = {
    key: 'root',
    storage: Platform.OS === 'web' ? localforage : AsyncStorage,
}
const rootReducer = persistCombineReducers(persistConfig, {
    user,
    helpers,
    order,
    offer,
    locale,
    orders,
    settings
});

export default rootReducer;