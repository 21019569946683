import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { greenColor, mainColor, redColor } from '../../constants/color';
import CustomImage from '../shared/customImg'
import { Like, Add, minus } from '../../constants/imgs'
import { useNavigation } from '@react-navigation/native';
import { Text } from '../shared/custom'
import { connect, useDispatch, useSelector } from 'react-redux';
import { UpdateOrderItems } from '../../reducers/order'
import { checkDiscount, totalPriceForItem } from '../../helpers/helpers'
import { firestore } from '../../constants/firebase/config'
import Alert from './Alert';
import { Skeleton, Image } from 'native-base';
import { LogEvent } from '../../helpers/index';
const Label = (prop) => {
    const { locale, strings } = useSelector((state) => state.locale);

    return (
        <View style={{ position: 'absolute', zIndex: 999, paddingVertical: 4, paddingHorizontal: 6, borderTopEndRadius: 4, borderBottomEndRadius: 4, backgroundColor: prop.color, marginTop: prop.top }}>
            <Text style={{ fontSize: 10, fontFamily: 'bold', color: '#fff' }}>{prop.title}</Text>
        </View>
    )
}
const Favourit = ({ id, alert }) => {
    const { favourit = [], uid } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    return (
        <TouchableOpacity
            onPress={() => {
                if (uid) {
                    dispatch({ type: 'UPDATE_FAV_LIST', payload: { id } })
                    firestore().collection('users').doc(uid).update({
                        favourit: favourit?.includes(id) ? firestore.FieldValue.arrayRemove(id) : firestore.FieldValue.arrayUnion(id)
                    })
                } else {

                    alert()
                }
            }}
            style={{ position: 'absolute', zIndex: 99, right: 0, padding: 6 }}>
            <CustomImage width={20} height={20} svg={true} alt={Like} uri={Like} fill={favourit?.includes(id) ? redColor : 'none'} />
        </TouchableOpacity>
    )
}
const ProductCard = ({ data, offer, orderItems, UpdateOrderItems, user, basic, prds, brands }) => {
    const navigation = useNavigation()
    let orderd = !basic && orderItems[data?.key]
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(true)
    const [prdData, setPrdData] = useState(data)
    const { locale, strings } = useSelector((state) => state.locale);
    const { currency } = useSelector((state) => state.settings);
    const dispatch = useDispatch()
    const getBrandName = (key) => {
        let brand = brands?.find(r => r.id === key)
        if (brand) {
            if (locale === 'ae') {
                return brand.name_ar || brand.name
            }
            return brand.name

        }
        return ''
    }

    let discounted = checkDiscount(offer, data)
    useEffect(() => {
        if (basic) {
            let prd = prds?.find(r => r.id === data?.key)

            setPrdData({ ...prd, ...data })
        }
    }, [data?.key, prds])

    let image = ''
    let title = ''
    let details = (locale === 'en' ? data?.detail : (data?.detail_ar || data?.detail))

    if (basic) {
        image = prdData?.thumb?.length ? (prdData?.thumb?.[0]?.includes('https://cdn.taybagourmet.com') ? prdData?.thumb?.[0] : `https://cdn.taybagourmet.com/prds/${prdData.id}/400`) : 'https://firebasestorage.googleapis.com/v0/b/tayba-2b919.appspot.com/o/Vi.png?alt=media&token=2a582040-3746-4821-997a-996d7cd23a45'
    } else {
        image = data?.thumb?.length ? (data?.thumb?.[0]?.includes('https://cdn.taybagourmet.com') ? data?.thumb?.[0] : `https://cdn.taybagourmet.com/prds/${data.id}/400`) : 'https://firebasestorage.googleapis.com/v0/b/tayba-2b919.appspot.com/o/Vi.png?alt=media&token=2a582040-3746-4821-997a-996d7cd23a45'
    }
    if (basic) {
        title = (locale === 'en' ? (prdData?.name) : (prdData?.name_ar || prdData?.name)) + " " + (details?.toLowerCase() || '')
    } else {
        title = (locale === 'en' ? (data?.name) : (data?.name_ar || data?.name)) + " " + (details?.toLowerCase() || '')
    }

    return (
        <TouchableOpacity
            onPress={() => !alert && navigation.navigate('Product', { id: data?.key || data?.id, data: basic ? prdData : data })}
            style={{ borderRadius: 8, marginHorizontal: 4, marginVertical: 8, backgroundColor: mainColor, borderColor: orderd ? redColor : 'transparent', borderWidth: 1, height: 270 }}>

            {
                !basic &&
                <>
                    {data?.newArrival && <Label title={strings.newArrival} color={redColor} top={16} />}
                    {
                        discounted && <Label title={`${discounted.percent}% ${strings.off}`} color={greenColor} top={data?.newArrival ? 42 : 16} />
                    }
                    {
                        alert && <Alert title={strings.alert} msg={strings.loginMsg} onConfirm={() => { setAlert(false); navigation.navigate('Login') }} confirmText={strings.login} onCancel={() => setAlert(false)} />
                    }
                    <Favourit id={data?.id} alert={() => setAlert(true)} />

                </>
            }

            <Image
                // width={165}
                height={133}
                style={{
                    // width: 165,
                    height: 133,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                }}
                onLoadEnd={() => setLoading(false)}
                resizeMode="cover"
                alt={image}
                source={{ uri: image, cache: 'force-cache' }}
            />

            {loading && <Skeleton
                style={{ height: 133, position: 'absolute', width: '100%' }}
                key={data?.key}
            />
            }
            <View style={{ paddingHorizontal: 8, paddingBottom: 8, justifyContent: 'space-between', flexDirection: 'column', height: 137 }}>
                <View>
                    <Text style={{ textAlign: 'left', fontSize: 12, paddingTop: 8, textTransform: 'capitalize',fontFamily:'light' }}>{getBrandName((!basic ? data : prdData)?.brand)}</Text>
                    <Text ellipsizeMode='tail' numberOfLines={2} style={{ fontSize: 14, paddingTop: 4, fontFamily: 'medium', textAlign: 'left', lineHeight: 18 }}>
                        {
                            (orderd || data?.count) && <Text style={{ color: redColor }}>{`${data?.count || orderd?.count}X `}</Text>
                        }
                        <Text>{title}</Text>
                    </Text>
                </View>
                <View>
                    {
                        !basic &&
                        <>
                            {/* <Text numberOfLines={1} ellipsizeMode='tail' style={{ fontSize: 12, paddingTop: 2, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular', textAlign: 'left' }}>{(locale === 'en' ? data?.detail : (data?.detail_ar || data?.detail))}</Text> */}
                            <Text style={{ fontSize: 12, paddingTop: 14,  textAlign: 'left' }}>{data?.unit?.value ? (`${data?.unit?.value} ${data?.unit?.label}`) : ''}</Text>
                        </>
                    }
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 2, justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'baseline', paddingTop: basic ? 12 : 0, flex: 1 }}>
                            {
                                basic ?
                                    <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>
                                        <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{` ${Number(totalPriceForItem(data)).toFixed(2)} `}</Text>
                                        {strings[currency]}
                                    </Text>
                                    :
                                    <>
                                        {data?.price !== '0' ? <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>
                                            <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{discounted ? (data?.price - discounted?.price).toFixed(2) : data?.price} </Text>
                                            {strings[currency]}
                                        </Text> :

                                            <Text numberOfLines={1} ellipsizeMode={'tail'} style={{ fontSize: 12, fontFamily:'semiBold', }}>
                                                {strings.priceOnSelection}
                                            </Text>
                                        }
                                        {
                                            discounted && data?.price !== '0' && <Text numberOfLines={1} ellipsizeMode='tail' style={{
                                                flex: 1, fontSize: 12, paddingHorizontal: 4,textDecorationLine: 'line-through'
                                            }}>{data?.price} {strings[currency]}</Text>
                                        }
                                    </>

                            }
                        </View>
                        {
                            !basic && <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                {
                                    orderd && <TouchableOpacity onPress={() => {
                                        let items = JSON.parse(JSON.stringify(orderItems))
                                        if (orderd.count > 1) {
                                            orderd.count -= 1
                                            items[orderd.key] = orderd
                                            UpdateOrderItems(items);
                                            dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))

                                        } else {
                                            delete items[orderd.key]
                                            UpdateOrderItems(items);
                                            dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))

                                        }
                                    }} style={{ paddingHorizontal: 8 }}>
                                        <View>
                                            <CustomImage width={20} fill={redColor} height={20} svg uri={minus} />
                                        </View>
                                    </TouchableOpacity>
                                }

                                <TouchableOpacity onPress={() => {
                                    if (orderd && orderd.count < 10) {
                                        let items = JSON.parse(JSON.stringify(orderItems))
                                        orderd.count += 1
                                        items[orderd.key] = orderd
                                        UpdateOrderItems(items);
                                        dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))

                                    } else {
                                        navigation.navigate('Product', { id: data.id, data })
                                    }
                                }}>
                                    <View >
                                        <CustomImage width={20} fill={redColor} height={20} svg uri={Add} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        }

                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
const mapStateToProps = ({ order, offer: { activeOffer }, user, helpers }) => {
    return {
        user,
        offer: activeOffer,
        orderItems: order?.items || {},
        prds: helpers?.prds || [],
        brands: helpers?.brands
    }
}

export default connect(mapStateToProps, { UpdateOrderItems })(ProductCard);