// /**
//  * @format
//  */

// import { AppRegistry } from 'react-native';
// import App from './App';
// import { name as appName } from './app.json';
// import './index.css'


// AppRegistry.registerComponent(appName, () => App);
// AppRegistry.runApplication(appName, {
//     rootTag: document.getElementById('root'),
// });

/**
 * @format
 */

import { AppRegistry } from 'react-native';
import App from './App';
import { name as appName } from './app.json';
import './index.css';
import { createRoot } from 'react-dom/client';

// Get the root element from the DOM
const rootTag = document.getElementById('root');

// Create a root
const root = createRoot(rootTag);

// Initial render
root.render(<App />);

// Register the component with AppRegistry
AppRegistry.registerComponent(appName, () => App);