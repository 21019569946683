import { View } from 'native-base';
import React from 'react';
import { Text } from './custom';
import { useSelector } from 'react-redux';

const EmptyState = ({ title = '', msg = '' }) => {
    const { locale } = useSelector(state => state.locale);
    return (
        <View flex={1} justifyContent='center' alignItems={'center'}>
            <Text textAlign='center' fontSize={16} color='#0F172A' style={{ fontFamily: 'semiBold' }} >{title}</Text>
            <Text paddingTop={2} textAlign='center' fontFamily='Regular' style={{ color: '#C6C6C6' }}>{msg}</Text>
        </View>
    );
};

export default EmptyState;