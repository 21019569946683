import React, { useMemo, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
// import './index.css'
import { Text } from "../shared/custom.js";
import { Button, ScrollView, Toast, View } from "native-base";
import ActionBtn from "../shared/ActionBtn";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "../../translations/index";
import { functions } from "../../constants/firebase/config";


const useOptions = () => {
    const fontSize = '16px';
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: 'Poppins-Regular',
                    "::placeholder": {
                        color: "#cacaca",
                        fontFamily: 'Poppins-Regular,sans-serif',
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const SplitForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.user);

    const [state, setState] = useState({})
    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setLoading(true)


        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        });
        if (payload?.paymentMethod?.id) {
            // if (stripeId) {
            //     let attachPayment = await functions().httpsCallable('attachPayment')({
            //         paymentMethod: payload?.paymentMethod,
            //         customer_id: stripeId,
            //         uid
            //     });
            //     if (attachPayment?.data?.status === 'error') {
            //         setLoading(false)
            //         throw (attachPayment?.data)

            //     }
            // }
            let customerData = {
                uid: user?.uid,
            };
            if (user?.email) {
                customerData.email = user?.email
            }
            if (user?.phoneNumber) {
                customerData.phone = user?.phoneNumber
                customerData.name = user?.displayName
            }
            let addPaymentMethod = await functions().httpsCallable('addPaymentMethod')({
                paymentMethod: payload?.paymentMethod,
                customer_id: user?.stripe_id,
                customerData,
            });
            if (addPaymentMethod?.data?.error) {
                setLoading(false)
                throw (addPaymentMethod?.data?.message)

            }
            console.log("addPaymentMethodaddPaymentMethod", addPaymentMethod);

            setLoading(false)
            navigation.goBack()
        } else {
            console.log("payloadpayload", payload.error);
            if (payload?.error) {
                Toast.show({
                    placement: 'top',
                    title: 'Error',
                    status: 'error',
                    description: payload.error?.message
                })
                setLoading(false)
                throw (payload?.error?.message)
            }
        }
        return
    };

    return (
        <View padding={4} flex={1} justifyContent='space-between'>
            <ScrollView >
                <View>
                    <Text fontSize={14}>{strings?.["Card number"]}</Text>
                    <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>

                        <CardNumberElement
                            options={options}
                            // onReady={() => {
                            //     console.log("CardNumberElement [ready]");
                            // }}
                            onChange={event => {
                                setState({ ...state, [event.elementType]: event.complete })
                            }}
                        // onBlur={() => {
                        //     console.log("CardNumberElement [blur]");
                        // }}
                        // onFocus={() => {
                        //     console.log("CardNumberElement [focus]");
                        // }}
                        />
                    </View>
                </View>
                <View flexDirection={'row'} justifyContent='space-between' paddingTop={4}>
                    <View w={'48%'}>
                        <Text fontSize={14}>{strings?.["Expiration date"]}</Text>
                        <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>
                            <CardExpiryElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                }}
                            />
                        </View>

                    </View>
                    <View w={'48%'} >
                        <Text fontSize={14}>CVV</Text>
                        <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>
                            <CardCvcElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                }}
                            />
                        </View>

                    </View>
                </View>
            </ScrollView>
            <View display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                <ActionBtn loading={loading} disabled={!stripe || !(state?.cardNumber && state?.cardExpiry && state?.cardCvc)} title={strings?.['Add Card']} onPress={() => {
                    handleSubmit()
                }} />
            </View>

        </View>
    );
};

export default SplitForm;
