import moment from "moment";
import { getAreaOfPolygon, isPointInPolygon } from 'geolib';
import { firestore, functions } from '../constants/firebase/config'
import { SetAlertMessage } from "../reducers/settings";
import { UpdateOrderItems } from "../reducers/order";
import { strings } from '../translations/index';
import { Platform } from "react-native";
import { ApplepaySVG, CashSVG, OnlinePaymenSVG, PaymentCardsSVG, Visa } from "../constants/imgs";

export function Localize(data, key) {
    let locale = strings?.getLanguage() === 'ae' ? 'ar' : 'en'
    let text = data?.[`${key}_${locale}`] || data?.[key] || '';
    return text
}
export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
export function totalPriceForItem(orderedItem) {
    let price = orderedItem?.price || 0
    let selected = orderedItem?.selectedModifiers;
    if (!selected) {
        return price
    }
    Object.values(selected || {})?.map(value => {
        let optionPrice = Number(value.price)
        let count = Number(value.count) || 1
        price = (price * 1) + (optionPrice * count)
        return price
    })
    return price
}
export function GetOrderType(orderList) {
    if (Object.values(orderList || {})?.find(r => r.sameDay) && Object.values(orderList || {})?.find(r => !r.sameDay)) {
        return 'mix'
    }
    if (Object.values(orderList || {})?.find(r => !r.sameDay)) {
        return 'schaduled'
    }
    if (Object.values(orderList || {})?.find(r => r.sameDay)) {
        return 'sameDay'
    }
}
export function CompareVersions(version1, version2) {
    // split the versions into arrays of numbers
    const version1Parts = version1?.split('.')?.map(Number);
    const version2Parts = version2?.split('.')?.map(Number);

    // compare the parts from left to right
    for (let i = 0; i < Math.max(version1Parts.length, version2Parts.length); i++) {
        // if the current part is undefined, assume it is 0
        const part1 = version1Parts[i] || 0;
        const part2 = version2Parts[i] || 0;

        // if the parts are different, return 1 if the first is greater, -1 if the second is greater, or 0 if they are equal
        if (part1 !== part2) {
            return part1 > part2;
        }
    }

    // if all parts are equal, return 0
    return 0;
}
export function calcTotalCost(orderList, promo, deliveryFees = 0, freeDeliveryFees) {
    let cost = 0
    let deductedValue;
    let orderType = GetOrderType(orderList)

    Object.values(orderList || {})?.map(orderedItem => {
        let price = totalPriceForItem(orderedItem)
        return cost = cost + (price * orderedItem?.count)
    })

    let sub = (cost).toFixed(2)
    let subFreeDelivery = (cost).toFixed(2)
    let percent;
    if (promo) {
        let { appliedOn } = promo || {}
        percent = (promo?.percent / 100)
        deductedValue = 0
        if (promo?.voucher) {
            if (promo?.discount?.label === '%') {
                percent = promo?.discount?.value / 100;
                appliedOn = { type: 'All' };
            } else if (promo?.discount?.label === 'AED') {
                deductedValue = promo?.discount?.value
            } else if (promo?.type === 'freeDelivery') {
                deliveryFees = 0;
                freeDeliveryFees = 0;
            }
        }
        switch (appliedOn?.type) {
            case 'All':
                deductedValue = (cost * percent);
                break;
            case 'Brands':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { brand, count } = orderedItem;
                    if (appliedOn?.items?.includes(brand)) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            case 'Products':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { id, count } = orderedItem;
                    if (appliedOn?.items?.includes(id)) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            case 'Product Categories':
                Object.values(orderList || {})?.map(orderedItem => {
                    let { count } = orderedItem || {};
                    if (appliedOn.items?.some(ele => orderedItem?.['prds_category']?.[ele])) {
                        deductedValue = deductedValue + (totalPriceForItem(orderedItem) * percent * count)
                    }
                    return false
                })
                break;
            default: break;
        }
        deductedValue = (promo?.cap && deductedValue > promo.cap) ? promo?.cap : deductedValue
        cost = (cost - deductedValue);
        subFreeDelivery = (cost).toFixed(2)
    }
    let tax = (cost * 0.05).toFixed(2)
    let deliveryFeesAmount = (orderType === 'schaduled') ? ((deliveryFees && (subFreeDelivery < Number(freeDeliveryFees))) ? deliveryFees : 0) : deliveryFees
    // if (deliveryFees && ((subFreeDelivery) < Number(freeDeliveryFees))) {
    cost = cost + Number(deliveryFeesAmount)
    // }
    let post = {
        sub,
        tax,
        total: cost.toFixed(2),
        dummyTotal: cost,
        deliveryFees: deliveryFeesAmount,
        subFreeDelivery,
        freeDeliveryActive: (orderType === 'schaduled'),
        orderType
    }
    if (deductedValue) {
        post.deductedValue = deductedValue.toFixed(2)
        post.code = promo.code
        post.percent = percent
    }
    if (promo?.voucher) {
        post.voucher = promo?.voucher
    }

    return (post)
}
export function checkDiscount(discount, item) {
    if (discount) {
        let { appliedOn } = discount || {};
        let percent = ((discount?.percent) / 100)
        let { type, items } = appliedOn || {}
        let { key } = item || {};
        let price = totalPriceForItem(item)

        switch (type) {
            case 'All':
                return { percent: discount?.percent, price: (price * percent) }
            case 'Brands':
                if (items?.includes(item?.brand)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Products':
                if (items?.includes(key)) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            case 'Product Categories':
                if (items?.some(ele => item?.['prds_category']?.[ele])) {
                    return { percent: discount?.percent, price: (price * percent) }
                }
                break;
            default:
                return false;
        }
    }
    return false
}


export function getDate(ts) {
    let date = moment(ts).format('MMM DD yyyy').toString()
    let time = moment(ts).format('hh:mm A').toString()
    return ({ date, time })

}
export const OptimizeLocationToPolygons = (params) => async (dispatch) => {
    let { locations, uid } = params || {};
    let updated;
    if (locations) {
        locations = await Promise.all(locations?.map(async rec => {
            if (!rec?.optimized) {
                let { geometry: { location } } = rec;
                let { cluster } = dispatch(CheckLocationIsServed(rec))
                if (cluster) {
                    updated = true
                    rec.locationId = cluster
                    rec.optimized = true
                }
            }
            return rec
        }))
        if (updated) {
            try {
                await firestore().collection('users').doc(uid).update({
                    locations: locations,
                })
            } catch (error) {

            }

        }

    }
}



export function checkLocationsServed(servedAreas, center) {
    let existed = servedAreas?.filter(rec => {
        const bound = [
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.north_East.lng,
            },
            {
                latitude: rec.viewport.south_west.lat,
                longitude: rec.viewport.south_west.lng,
            },
            {
                latitude: rec.viewport.north_East.lat,
                longitude: rec.viewport.south_west.lng,
            },
        ];
        const customerIsInWashersServeArea = isPointInPolygon(
            center,
            bound,
        );
        return customerIsInWashersServeArea ? rec : false
    })
    return existed?.length ? existed[0] : false
}

export const CheckLocationIsServed = (current) => (_dispatch, getState) => {
    let { settings: { polygons = [], allLocations } } = getState();
    const { geometry: { location: currenrLocation } } = current || {}
    // let places_ids = (Object.keys(servingAreas || {}) || [])?.reduce((val, currentKey) => {
    //     let currentVal = servingAreas[currentKey];
    //     if (currentVal?.active) {
    //         let list = currentVal?.areas_list?.map(r => {
    //             return { cluster: currentKey, ...r }
    //         })
    //         val = val.concat(list?.filter(r => !!r) || [])
    //     }
    //     return val
    // }, [])

    // let polygons = places_ids?.map(r => {
    //     let placeData = places?.find(rec => rec?.id === r?.id);
    //     return { ...placeData, cluster: r.cluster }
    // })
    //check if no updates in areas
    let currentPlace = polygons?.find(r => r.cluster === current?.locationId)
    if (currentPlace) {
        let existed;
        switch (currentPlace.geojson?.type) {
            case "MultiPolygon":
                existed = Object.keys(currentPlace?.geojson?.coordinates || {}).reduce((val, current) => {
                    let existed = isPointInPolygon(currenrLocation, (currentPlace?.geojson?.coordinates?.[current] || []))
                    if (existed) {
                        val = true
                    }
                    return val
                }, false)
                if (existed) {
                    return { place_id: currentPlace?.place_id, cluster: currentPlace?.cluster }

                }
                break;
            default:
                let polygon = currentPlace.geojson?.coordinates || [];
                existed = isPointInPolygon(currenrLocation, polygon);
                if (existed) {
                    return { place_id: currentPlace?.place_id, cluster: currentPlace?.cluster }
                };
                break;
        }
    }

    let place = polygons.find(r => {
        let existed;
        switch (r.geojson?.type) {
            case "MultiPolygon":
                existed = Object.keys(r?.geojson?.coordinates || {}).reduce((val, current) => {
                    let existed = isPointInPolygon(currenrLocation, (r?.geojson?.coordinates?.[current] || []))
                    if (existed) {
                        val = true
                    }
                    return val
                }, false)
                return existed
            default:
                let polygon = r.geojson?.coordinates || [];
                existed = isPointInPolygon(currenrLocation, polygon)
                return existed
        }
    })
    let branch = allLocations?.[place?.cluster]?.branch
    console.log("placeplace", branch);

    // let newPlace = place.sort((a, b) => { getAreaOfPolygon(a?.geojson?.coordinates) - getAreaOfPolygon(b?.geojson?.coordinates) })
    // return { place_id: newPlace?.[0]?.place_id, cluster: newPlace?.[0]?.cluster }
    return { place_id: place?.place_id, cluster: place?.cluster, branch }

}
export async function getResturantLocations({ country }) {
    let res = await firestore().collection('pickup_areas').get()
    let PickupLocations = {}
    res.docs.map(rec => {
        let data = rec.data()
        let { active, country: supportedCountries = [] } = data
        if (active && supportedCountries.includes(country)) {
            PickupLocations[rec.id] = { ...data, id: rec.id }
        }
        return false
    })
    return { PickupLocations }
}

export async function getLocations({ country }) {
    let res = await firestore().collection('delivering_areas').where('active', '==', true).get()
    let supportedAreas = []
    let allLocations = {}
    res.docs.map(rec => {
        let data = rec.data()
        let { areas, active, country: supportedCountries = [] } = data
        if (active && supportedCountries.includes(country)) {
            // if (true) {
            Object.keys(areas || {}).map(areaKey => {
                let areaObject = areas[areaKey]
                let { list } = areaObject
                let optimizedList = list.map(location => {
                    return ({ ...location, id: rec.id, key: rec.id })
                })
                supportedAreas = supportedAreas.concat(optimizedList)
                return false
            })
        }
        allLocations[rec.id] = data
        return false
    })
    return { supportedAreas, allLocations }
}
export const applyPromo = (code) => async (dispatch, getState) => {
    if (code) {
        let loweCasedCode = code.toLowerCase()

        let snap = await firestore().collection('offers').where('code', '==', loweCasedCode).limit(1).get()
        if (snap.empty) {
            return { error: 'Please enter valid promocode' }
        }
        let { orders, order: { location: { branch = false } } = {} } = getState() || {};
        console.log("branchbranch", branch);

        let activeOrders = Object.values(orders || {})?.filter(r => r.receipt.code === loweCasedCode);
        let isNewUser = Object.values(orders || {})?.length === 0;
        let results = snap.docs.map(rec => {
            let data = rec.data()
            let res = validatePromo({ data: { ...data, id: rec.id }, activeOrders: activeOrders.length, isNewUser, branch: branch });
            return res
        })
        return results[0]
    }

}

export const validatePromo = ({ data, activeOrders, isNewUser, branch }) => {
    let { active, endDate, count, limited, redeemed = 0, newUser, branches } = data;
    if (active) {
        if (branches && !branches.includes(branch)) {
            return { error: 'this promocode is not valid for this branch' }
        }
        if (!endDate || endDate > Date.now()) {
            if (!count || (Number(count) > Number(redeemed))) {
                if (newUser) {
                    if (isNewUser) {
                        return data
                    } else {
                        return { error: 'this promocode is for new users only' }
                    }
                } else if (limited === '0' || limited === 'unlimited') {
                    return data
                } else if ((Number(limited) > 0)) {
                    if (activeOrders < limited) {
                        return data
                    }
                }
            }
        }
    }
    return { error: 'this promocode is expired' }
}

export function translateSelectedSlot(dateObj) {
    const today = moment().startOf('day');

    if (!dateObj) {
        return false
    }
    let { date, slot } = dateObj
    let dateString;

    const inputDate = moment(date?.dateTimestamp).startOf('day');

    if (inputDate.isSame(today, 'day')) {
        dateString = 'Today';
    } else if (inputDate.isSame(today.clone().add(1, 'day'), 'day')) {
        dateString = 'Tomorrow';
    } else {
        dateString = inputDate.format('DD/MM')
    }

    let timeSting = [moment(slot?.start)?.format('hh:mm A'), moment(slot?.end)?.format('hh:mm A')].join(' - ')
    return `${dateString} at ${timeSting}`


}

const checkGhadeniStatus = ({ ghadiniTimings }) => {
    let now = moment();
    const currentDay = now.format('dddd'); // Get the full name of the current day
    const currentTime = now.format('HH:mm'); // Get the current time in HH:mm format
    const dayTimings = ghadiniTimings[currentDay];
    if (dayTimings && dayTimings.isOpen) {
        const { startTime, endTime } = dayTimings.timings[0];
        // Convert startTime and endTime to HH:mm format
        const formattedStartTime = moment(startTime).format('HH:mm');
        const formattedEndTime = moment(endTime).format('HH:mm');

        const optimizedStart = moment(`${moment().format('yyyy-MM-DD')}T${moment(startTime).format('HH:mm:ss')}`);
        const optimizedEnd = moment(`${moment().format('yyyy-MM-DD')}T${moment(endTime).format('HH:mm:ss')}`);
        if (currentTime >= formattedStartTime && currentTime <= formattedEndTime) {
            const duration = optimizedEnd.diff(now); // Difference in milliseconds
            const durationInMinutes = Math.floor(moment.duration(duration).asMilliseconds());
            return { type: 'close', seconds: durationInMinutes };
        } else if (currentTime < formattedStartTime) {
            console.log("formattedStartTime", formattedStartTime, currentTime);

            const duration = optimizedStart.diff(now); // Difference in milliseconds
            return { type: 'open', seconds: duration };
        } else if (currentTime > formattedEndTime) {
            const duration = optimizedStart.add(1, 'day').diff(now); // Difference in milliseconds
            return { type: 'open', seconds: duration };
        }
    } else {
        return { type: 'inactive' }
    }
};
export const CheckStoreClosed = (getGhadini = false) => (dispatch, getState) => {
    let { order: { location, locationTimings }, settings: { allLocations, ghadini } } = getState();
    let now = moment()
    let weekDays = [{ Key: 'Sunday', Label: 'Sun' },
    { Key: 'Monday', Label: 'Mon' },
    { Key: 'Tuesday', Label: 'Tue' },
    { Key: 'Wednesday', Label: 'Wed' },
    { Key: 'Thursday', Label: 'Thu' },
    { Key: 'Friday', Label: 'Fri' },
    { Key: 'Saturday', Label: 'Sat' }]
    let timings = allLocations?.[location?.locationId]?.deliveryTimings || location?.params?.deliveryTimings || location?.timings;
    if (getGhadini) {
        if (ghadini?.active) {
            timings = locationTimings?.ghadiniTimings;
            console.log("timings", timings);
            if (timings) {
                let result = checkGhadeniStatus({ ghadiniTimings: timings });
                dispatch({ type: 'SET_GHADENI_STATUS', payload: result })
                console.log("resultresult", result);
            } else {
                dispatch({ type: 'SET_GHADENI_STATUS', payload: { type: 'inactive' } })
            }
        } else {
            dispatch({ type: 'SET_GHADENI_STATUS', payload: { type: 'inactive' } })

        }
        return
    }
    let breakLoop = false;
    let closedUntil = 0
    let currentWeekDay = now.day();
    let initialDay = 5
    while (!breakLoop && closedUntil === 0) {
        let { timings: daySlots, isOpen } = timings?.[weekDays[currentWeekDay].Key] || { daySlots: [], isOpen: false };

        closedUntil = daySlots?.reduce((val, currentVal) => {
            let startTime = moment(`${moment().add(initialDay - 5, 'day').format('yyyy-MM-DD')}T${moment(currentVal?.startTime).format('HH:mm:ss')}`);
            let endTime = moment(`${moment().add(initialDay - 5, 'day').format('yyyy-MM-DD')}T${moment(currentVal?.endTime).format('HH:mm:ss')}`);
            if (endTime.isBefore(startTime)) {
                endTime = endTime.add(1, 'day');
            }
            // let nowTime = moment(`1955-01-05T${now.format('HH:mm:ss')}`);
            if (isOpen) {
                if (now.isBetween(startTime, endTime)) {
                    breakLoop = true
                }
                if (now.isBefore(startTime) && moment(val).isBefore(startTime)) {
                    val = startTime.valueOf();
                }
            }

            return val;
        }, 0);
        // console.log("closedUntil", closedUntil, getGhadini);

        initialDay = initialDay + 1
        if (initialDay === 10) {
            breakLoop = true
        }
        currentWeekDay = (currentWeekDay + 1) % weekDays.length; // Increment and wrap around the array
    }

    if (closedUntil) {
        const duration = moment(closedUntil).diff(moment()); // Difference in milliseconds
        const durationInMinutes = Math.floor(moment.duration(duration).asSeconds());
        return durationInMinutes
    }
    return 0
}

export function EnableAdd(item) {

    let allowAdd = item?.modifiers?.map(rec => {
        let { key, min_count } = rec || {}
        if (!min_count || Number(min_count) === 0) {
            return true
        }
        let checked = Object.values(item?.selectedModifiers || {})?.filter(r => r.parent === key)
        let count = checked?.reduce((a, b) => a = a + (b.count || 1), 0)

        if (Number(count) >= Number(min_count)) {
            return true
        }
        return false
    })
    if (allowAdd?.includes(false)) {
        return false
    } else {
        return true
    }

}

export const VerifyProdsUpdated = (prds, order) => {
    let updates = [];
    Object.values(order?.items || {})?.forEach(rec => {
        let product = prds?.find(r => r.key === rec?.key);
        if (!product) {
            console.log("item Removed ", rec?.key);
            updates.push({
                type: 'remove',
                key: rec?.seq || rec?.key
            });
            return;
        }
        let enabled = EnableAdd({...product,...rec});
        if (!enabled) {
            console.log("item Removed ", rec?.key);
            updates.push({
                type: 'remove',
                key: rec?.seq || rec?.key
            });
            return;
        }

        let { key, price, modifiers } = product;
        if (price && price !== rec?.price) {
            console.log("item price updated ", rec?.key, "New price", price);
            updates.push({
                type: 'update',
                key: rec?.seq || rec?.key
            });
            return;
        }

        Object.values(rec?.selectedModifiers || {})?.forEach(selectedModifier => {
            let { key, price: modifierPrice, parent } = selectedModifier || {};
            let mod = modifiers?.find(r => r.key === parent);
            if (!mod) {
                console.log("modifier Removed ", key, rec?.key);
                updates.push({
                    type: 'remove',
                    key: rec?.key
                });
                return;
            }

            let modifierItem = mod?.items?.find(r => r.key === key);
            if (!modifierItem) {
                console.log("item Removed ", key, rec?.key);
                updates.push({
                    type: 'remove',
                    key: rec?.key
                });
                return;
            }

            if (modifierPrice && (modifierPrice !== modifierItem?.price)) {
                console.log("modifier price updated ", key, rec?.key, "New price", modifierItem?.price);
                updates.push({
                    type: 'update',
                    key: rec?.key
                });
            }
        });
    });
    return updates;
};


export const verifyLocation = () => (dispatch, getState) => {
    let { locale: { strings }, settings: { pickupLocations, allLocations }, order, user, helpers: { prds } } = getState();

    let ItemsUpdated = VerifyProdsUpdated(prds, order);
    if (ItemsUpdated?.length) {
        let optimizedItems = JSON.parse(JSON.stringify(order.items))
        ItemsUpdated.map(rec => {
            delete optimizedItems[rec.key]
            return false
        })
        dispatch(UpdateOrderItems(optimizedItems))
        dispatch(SetAlertMessage({
            type: 'alert',
            title: 'Sorry!',
            msg: strings?.['Due to updates during checkout, some items have been removed from your cart. Please review your cart before proceeding!'],
            alert: true,
            onCancel: () => {
                dispatch(SetAlertMessage(false))
            }
        }))

    }

    if (user?.locations?.length && Object.keys(order?.items || {})?.length) {
        if (order?.location) {
            if (order?.location?.type === 'pickup') {
                if (pickupLocations?.[order.location?.key]) {
                    return ({ type: 'location', location: { ...order?.location, ...pickupLocations?.[order.location?.key] } })
                } else {
                    return ({ type: 'alert', msg: strings?.['Sorry,This branch is not working at the moment!'], actionTitle: 'Change pickup spot', action: 'toggleLocations' })
                }
            } else {
                let { place_id, cluster } = dispatch(CheckLocationIsServed(order?.location));
                if (!place_id) {
                    return ({ type: 'alert', msg: strings?.['Sorry, Currently we are not serving this area!'], actionTitle: 'Change address', action: 'toggleLocations' })
                } else {
                    return ({
                        type: 'location', location: { ...order?.location, locationId: cluster, params: allLocations?.[cluster] || {} }
                    })
                }
            }
        } else {
            return ({ type: 'alert', msg: strings?.['Please select delivery address'], actionTitle: 'Select address', action: 'toggleLocations' })

        }
    } else {
        return ({ type: 'alert', msg: strings?.['completeProfileMsg'], actionTitle: "completeProfile", action: 'navigate' })
    }
}



export const PaymentMethods = [
    { id: 'wallet', delivery: Platform.OS === 'ios' ? 'Apple Pay' : Platform.OS === 'android' ? 'Google Pay' : 'Apple Pay/Google Pay', pickup: Platform.OS === 'ios' ? 'Apple Pay' : Platform.OS === 'android' ? 'Google Pay' : 'Apple Pay/Google Pay', img: ApplepaySVG },
    { id: 'online', delivery: 'Card Payment', pickup: 'Card Payment', img: OnlinePaymenSVG },
    { id: 'cash', delivery: 'Cash On Delivery', pickup: 'Cash On Pickup', img: CashSVG },
    { id: 'card', delivery: 'Card On Delivery (POS Machine)', pickup: 'Card On Pickup (POS Machine)', img: PaymentCardsSVG }
]


export const RandomiseMeals = (prds) => {
    if (prds.length < 3) {
        return []
    }

    let selectedMeals = new Set();
    let randomMeals = [];

    while (selectedMeals.size < 3) {
        let randomIndex = Math.floor(Math.random() * prds.length);

        if (!selectedMeals.has(randomIndex)) {
            selectedMeals.add(randomIndex);
            let randomMeal = { ...prds[randomIndex] }; // Clone to avoid mutating original

            let selectedModifiers = Object.keys(randomMeal?.modifiers || {}).reduce((val, current) => {
                let { items, key, type } = randomMeal?.modifiers[current];
                let selected = items[Math.floor(Math.random() * items.length)];
                val[key] = {
                    parent: key,
                    key: selected?.key,
                    count: 1,
                    type: type,
                    ...selected
                };
                return val;
            }, {});

            randomMeal.selectedModifiers = selectedModifiers || {};
            randomMeal.seq = Math.floor(100000000000 + Math.random() * 900000000000);
            randomMeals.push(randomMeal);
        }
    }
    return randomMeals;
}



export const handleAddNewCard = async ({ stripe, user }) => {
    const payload = await stripe.createPaymentMethod({
        type: 'card',
        paymentMethodType: 'Card'

    });
    console.log("payload", payload);

    if (payload.error) {
        return { error: payload?.error?.localizedMessage }
    }
    if (payload?.paymentMethod?.id) {
        let customerData = {
            uid: user?.uid,
        };
        if (user?.email) {
            customerData.email = user?.email
        }
        if (user?.phoneNumber) {
            customerData.phone = user?.phoneNumber
            customerData.name = user?.displayName
        }
        let addPaymentMethod = await functions().httpsCallable('addPaymentMethod')({
            paymentMethod: payload?.paymentMethod,
            customer_id: user?.stripe_id,
            customerData,
        });
        if (addPaymentMethod?.data?.error) {
            return { error: addPaymentMethod?.data?.message }
        }
        return { success: addPaymentMethod }
    }
    return
};

export const CalculateTotalPoins = (walletLogs) => {
    let total = walletLogs?.reduce((val, current) => {
        let { type, amount } = current;
        if (type === 'credit') {
            val = val + (amount * 1)
        } else {
            val = val - (amount * 1)
        }
        return val;
    }, 0)
    return total
}

export const RedeemVoucher = async (voucher_id) => {
    let redeemVoucher = await functions().httpsCallable('redeemVoucher')({
        code: voucher_id,
    });
    return redeemVoucher
}


export const CalculatePoints = (snap = []) => {
    let sorted = snap.sort((a, b) => a.created - b.created);
    const result = sorted?.reduce((val, current) => {
        let { type, amount, created, exp: expirey } = current;
        amount = amount * 1;
        let increment = type === 'credit';
        if (increment) {
            val.push({ exp: { start: created, end: expirey }, amount, OA: amount, id: current.id });
        } else {
            //check if recored created stamp is less than 3 months
            let eligible = val.filter(rec => moment(created).isBetween(moment(rec.exp.start), moment(rec.exp.end)));
            let balance = amount
            eligible.map(rec => {
                let index = val.findIndex(r => r.id === rec.id)
                if (balance >= val[index].amount) {
                    balance = balance - val[index].amount
                    val[index].amount = 0
                } else {
                    val[index].amount = val[index].amount - balance
                    balance = 0
                }
            })
        }


        return val
    }, []);
    result.totalCashback = result.reduce((total, rec) => {
        if (moment(rec.exp.end).isAfter(moment())) {
            total = total + rec.amount
        }
        return total
    }, 0);

    return result.totalCashback || 0

}