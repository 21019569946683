
import * as React from "react";
import { Dimensions, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Color } from "../../constants/GlobalStyles";
import { Text } from "./custom";
import CustomImage from "./customImg";
import { CoinSVG } from "../../constants/imgs";
import moment from "moment";
import { Localize } from "../../helpers/helpers";
import { useSelector } from "react-redux";
const { width } = Dimensions.get('screen')

const LoyaltyCard = ({ data, color = '#6E3B0E', onRedeem, redeemed }) => {
    let expired = redeemed && moment(data?.exp).isBefore(moment());
    const { strings } = useSelector(state => state.locale)
    return (
        <View style={styles.subtractLayout}>
            <View style={[styles.subContainers, { backgroundColor: data?.bgColor || color }]}>
                <View style={{ position: 'absolute', width: 34, height: 34, borderRadius: 34, backgroundColor: '#fff', left: -28, top: '65%' }} />
                <View style={{ position: 'absolute', width: 34, height: 34, borderRadius: 34, backgroundColor: '#fff', right: -28, top: '65%' }} />
                <View style={styles.flex}>
                    <Text style={styles.header} >{Localize(data, 'title')}</Text>
                    {
                        data?.exp ?
                            <Text style={[styles.whiteColor]} >{expired ? 'Expired' : data?.redeemed ? 'Redeemed' : redeemed ? `Valid until ${moment(data?.exp).format('DD MMM, yyyy')}` : `Valid for: ${data?.exp} days`}</Text>
                            :
                            null
                    }

                </View>
                <View style={styles.flex}>
                    <View>
                        <View>
                            <Text style={styles.offer} cap={false}>{data?.discount ? (data?.discount?.value + data?.discount?.label) : Localize(data, 'description')}</Text>
                        </View>
                        <View style={[styles.flex, { justifyContent: 'flex-start', width: 'auto' }]}>

                            {
                                !redeemed ?
                                    <TouchableOpacity style={styles.redeemBtn} onPress={onRedeem}>
                                        <Text style={{ fontSize: 12, color: data?.bgColor || color, fontFamily: 'medium' }}>{strings?.["Redeem now"]}</Text>
                                    </TouchableOpacity> : null

                            }




                            <View style={[styles.flex, { justifyContent: 'flex-start', paddingHorizontal: !redeemed ? 16 : 0, width: 'auto' }]}>
                                <CustomImage
                                    svg
                                    uri={CoinSVG}
                                    width={24}
                                    height={24}
                                />
                                <Text style={[styles.whiteColor]}>{data?.numberOfPoints}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: '35%', height: '100%', position: 'absolute', right: 0, bottom: -8 }}>
                        <Image
                            source={{
                                uri: data?.img?.[0]
                            }}

                            style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }}
                        />

                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({

    subtractLayout: {
        width: width,
        paddingHorizontal: 12,
        flex: 1,
    },
    whiteColor: {
        color: '#fff',
        fontSize: 12
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    header: {
        fontSize: 16,
        fontFamily: 'medium',
        color: '#fff'
    },
    subContainers: {
        backgroundColor: '#000',
        borderRadius: 12,
        width: '100%',
        padding: 12,
    },
    offer: {
        alignSelf: "stretch",
        fontSize: 32,
        fontWeight: "600",
        fontFamily: 'semiBold',
        color: Color.primitivesNeutralWhiteWhite,
        textAlign: "left",
        paddingVertical: 12
    },
    redeemBtn: {
        backgroundColor: '#fff',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 50


    }


});

export default LoyaltyCard;
