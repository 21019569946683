import { useNavigation } from '@react-navigation/native';
import { Pressable, ScrollView, Toast, View } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from '../../constants/imgs';
import { UpdateUser } from '../../helpers/helpers';
import { SetAlertMessage } from '../../reducers/settings';
import Image from '../shared/customImg'
import EmptyState from '../shared/EmptyState';
import { Text } from '../shared/custom';
import { strings } from '../../translations/index';
import ActionBtn from '../shared/ActionBtn';
import { backgroundColor } from '../../constants/color';
import { firestore } from '../../constants/firebase/config';

const PaymentMethods = () => {
    const nav = useNavigation()
    const user = useSelector(state => state.user);
    const { locale } = useSelector(state => state.locale);
    const dispatch = useDispatch()
    return (
        <View style={{ backgroundColor: backgroundColor ,flex:1}}>
            {
                !user?.paymentMethods?.length ? <View height={'100%'}>
                    <EmptyState title={strings?.['No Saved Cards']} msg={strings?.["Place an order to add your Card"]} />
                </View>
                    :
                    <View height={'100%'}>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 16, paddingBottom: 100 }}>

                            {
                                user?.paymentMethods?.map(r => <Pressable
                                    onPress={() => {
                                        dispatch(SetAlertMessage({
                                            title: "Warning",
                                            msg: strings['Are you sure ,This card will be deleted permanently'],
                                            confirmText: strings['Delete'],
                                            onConfirm: async () => {
                                                let cards = user.paymentMethods.filter(rec => rec.id !== r.id);
                                                console.log("cards", cards);

                                                try {
                                                    await firestore().collection('users').doc(user.uid).update({ paymentMethods: cards })
                                                } catch (error) {
                                                    console.log("error", error);

                                                }
                                                // const { error } = await dispatch(UpdateUser({ cards }))
                                                // if (error) {
                                                //     Toast.show({
                                                //         placement: 'top',
                                                //         title: 'Error',
                                                //         status: 'error',
                                                //         description: error.message
                                                //     })

                                                // }
                                                dispatch(SetAlertMessage(false))
                                            }
                                        }))

                                    }}
                                    flexDirection={'row'}
                                    borderRadius={8}
                                    marginBottom={4}
                                    backgroundColor='#fff'
                                    padding={4}
                                    alignItems='center'
                                    justifyContent={'space-between'}
                                    key={r.id}>
                                    <View w={'80%'}>
                                        <Text style={{ fontFamily: 'semiBold' }} >
                                            {r?.brand}
                                        </Text>
                                        <Text style={{ fontFamily: 'medium' }} paddingTop={1}>
                                            XXXX {r?.last4}
                                        </Text>
                                    </View>
                                    <Image
                                        svg
                                        fill='red'
                                        uri={Trash}
                                        width={20}
                                        height={20}
                                    />
                                </Pressable>)
                            }

                        </ScrollView>
                    </View>
            }
            {
                user?.uid ?
                    <View style={{ paddingHorizontal: 16, flexDirection: 'row', justifyContent: 'center', width: '100%' }} >
                        <ActionBtn
                            title={strings?.['Add Card']}
                            onPress={() => {
                                nav.navigate('AddCard')

                            }}
                        />
                    </View>
                    :
                    <View />
            }


        </View >
    );
};

export default PaymentMethods;