import { View, Text } from 'native-base';
import React from 'react';
import { Dot, FilledDot } from '../../constants/imgs';
import Image from './customImg'
import { redColor } from '../../constants/color';
import { useSelector } from 'react-redux';

const CardOptionComp = ({ selected, data, card = false, backgroundColor = '#fff', color }) => {
    const {locale} = useSelector(state => state.locale)
    return (
        <View
            // shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
            backgroundColor={backgroundColor}
            borderWidth={1}
            borderColor={selected && !card ? '#20A2DF' : '#fff'}
            borderRadius={8} paddingX={4}
            paddingY={card ? 2 : 4}
            flexDirection='row'
            justifyContent={'space-between'} alignItems='center' >
            <View maxW={'80%'} flexDirection='row' alignItems={'center'}>
                {
                    card && <Image
                        svg
                        fill={redColor}
                        uri={data?.image}
                        width={25}
                        height={25}
                    />
                }
                <View w={'100%'} >
                    <Text  style={{ fontSize: 14, color: '#3B3B3B' ,fontFamily:'semiBold'}}>
                        {data.title}
                    </Text>
                    {
                        data.desc ?
                            <Text style={{ fontSize: 12, color: '#3B3B3B' }}>
                                {data.desc}
                            </Text>
                            : <View />
                    }
                </View>

            </View>
            <View >
                {
                    <Image
                        svg
                        fill={redColor}
                        uri={selected ? FilledDot : Dot}
                        width={15}
                        height={15}
                    />
                }


            </View>

        </View>
    )
}

export default CardOptionComp;