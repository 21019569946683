import React, { useEffect, useState } from 'react';
import { Text } from '../../shared/custom';
import { FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import CustomImage from '../../shared/customImg';
import { ArrowRight, BackRoundSVG, CoinBGSVG, CoinSVG, DiscountBGSVG, DiscountSVG, Recent } from '../../../constants/imgs';
import { useDispatch, useSelector } from 'react-redux';
import LoyaltyCard from '../../shared/LoyaltyCard';
import ListCard from '../../shared/ListCard';
import { useNavigation } from '@react-navigation/native';
import { firestore } from '../../../constants/firebase/config';
import moment from 'moment';
import { RedeemVoucher } from '../../../helpers/helpers';
import { redColor } from '../../../constants/color';
import { SetAlertMessage } from '../../../reducers/settings';
const Index = () => {
    const { locale, strings } = useSelector(state => state.locale);
    const { vouchers } = useSelector(state => state.helpers);
    const [state, setState] = useState([])
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    useEffect(() => {
        const unsubscribe = firestore().collection('users').doc(user?.uid).collection('wallet').onSnapshot((snap) => {
            let data = snap?.docs?.map((item) => item.data());
            dispatch({ type: 'SET_WALLET_LOGS', payload: data || [] });
            let state = data?.sort((a, b) => b.created - a.created)?.slice(0, 6)?.map((item) => {
                return {
                    title: item?.type === 'credit' ? strings?.['Points collected'] : strings?.['Points redeemed'],
                    desc: moment(item?.created)?.format('MMM DD, YYYY'),
                    img: item?.type === 'credit' ? CoinBGSVG : DiscountBGSVG,
                    value: item?.amount,
                    type: item?.type,
                    valueColor: item?.type === 'credit' ? '#04CE00' : redColor
                }
            });
            setState(state || [])
        })
        return () => {
            unsubscribe()
        }

    }, [])
    return (
        <ScrollView>
            <View style={[styles.container, styles.flex, { justifyContent: 'center' }]}>
                <View>
                    <Text style={[styles.centerText, { color: '#383838', fontFamily: 'light' }]}>{strings?.["Points"]}</Text>
                    <View style={styles.flex}>
                        <CustomImage
                            svg
                            uri={CoinSVG}
                            width={24}
                            height={24}

                        />
                        <Text style={[styles.centerText, { fontSize: 32, color: '#0e0e0e', fontFamily: 'semiBold' }]}>{user?.totalPoints}</Text>
                    </View>
                    {/* <Text style={[styles.centerText]} cap={false}>=  AED 16.4</Text> */}
                </View>
            </View>
            <View style={styles.separator} />
            <View style={{ paddingBottom: 16 }}>
                <View style={[styles.flex, { justifyContent: 'space-evenly', padding: 16 }]}>
                    {
                        [{
                            title: strings?.['My Vouchers'],
                            icon: DiscountSVG,
                            action: 'VoucherList',
                            props: {
                                myVouchers: true,
                                title: 'My Vouchers'
                            }
                        }, {
                            title: strings?.['Activity'],
                            icon: Recent,
                            action: 'WalletRecords',
                            props: {}
                        }].map((item, index) => {
                            return <TouchableOpacity key={index} style={styles.mainActions} onPress={() => {
                                navigation.navigate(item.action, item.props)
                            }}>
                                <CustomImage
                                    svg
                                    uri={item.icon}
                                    width={24}
                                    height={24}
                                    fill={redColor}
                                />
                                <Text style={{ padding: 8, fontSize: 12 }}>
                                    {item.title}
                                </Text>
                            </TouchableOpacity>
                        })
                    }
                </View>
                <View style={[styles.flex, { paddingHorizontal: 16, paddingBottom: 16 }]}>
                    <Text style={styles.header}>
                        {strings?.["Vouchers"]}
                    </Text>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate('VoucherList')
                        }}
                        style={[styles.seeMoreBtn, {
                            transform: [{ rotate: locale === 'ae' ? '180deg' : '0deg' }]
                        }]}>
                        <CustomImage
                            svg
                            uri={BackRoundSVG}
                            rotate={180}
                            width={20}
                            height={20}

                        />
                    </TouchableOpacity>
                </View>
                <View style={{ width: '100%' }}>
                    <FlatList
                        data={vouchers}
                        contentContainerStyle={{
                            paddingBottom: 100
                        }}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={(_item, index) => index.toString()}
                        ItemSeparatorComponent={() => <View style={{ height: 16 }} />}
                        renderItem={({ item }) => {
                            return (
                                <View key={item} style={{ overflow: 'hidden' }}>
                                    <LoyaltyCard data={item} onRedeem={async () => {
                                        if (user?.totalPoints < item?.numberOfPoints) {
                                            dispatch(SetAlertMessage({
                                                title: strings?.sorry,
                                                alert: true,
                                                msg: strings?.['You do not have enough points to redeem this voucher'],
                                                onConfirm: () => {
                                                    dispatch(SetAlertMessage(false))
                                                }
                                            }))
                                            return;
                                        }
                                        dispatch(SetAlertMessage({
                                            title: strings?.['Redeem voucher'],
                                            msg: strings?.[`Are you sure you want to redeem this voucher?`],
                                            confirmText: strings?.['Redeem'],
                                            onConfirm: async () => {
                                                let res = await RedeemVoucher(item.id)
                                                console.log("redeem", res);
                                                dispatch(SetAlertMessage(false))
                                                if (res.data?.error) {
                                                    dispatch(SetAlertMessage({
                                                        title: 'Error',
                                                        alert: true,
                                                        msg: res.data?.error?.message,
                                                        onConfirm: () => {
                                                            dispatch(SetAlertMessage(false))
                                                        }
                                                    }))
                                                }

                                            }
                                        }))
                                    }} />
                                </View>
                            )
                        }}
                    />
                </View>

            </View>
            {/* <View style={styles.separator} />
            <View style={{ paddingBottom: 16 }}>
                <View style={[styles.flex, { paddingHorizontal: 16, paddingTop: 16 }]}>
                    <Text style={styles.header}>
                        {strings?.['Recent activity']}
                    </Text>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate('WalletRecords')
                        }}
                        style={[styles.seeMoreBtn, {
                            transform: [{ rotate: locale === 'ae' ? '180deg' : '0deg' }]
                        }]}>
                        <CustomImage
                            svg
                            uri={BackRoundSVG}
                            rotate={180}
                            width={20}
                            height={20}

                        />
                    </TouchableOpacity>
                </View>
                <View>
                    {
                        state?.map((item, index) => {
                            return <View key={index}>
                                <ListCard data={item} paddingVertical={8} size={44} />
                            </View>
                        })
                    }

                </View>
            </View> */}


        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16
    },
    mainActions: {
        backgroundColor: '#F8F8F8',
        width: '50%',
        display: 'flex',
        flexDirection: 'coloumn',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 8,
        borderRadius: 8,
        padding: 16
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    centerText: {
        textAlign: 'center',
        color: '#383838'
    },
    separator: {
        height: 6,
        backgroundColor: '#f2f2f2',
        width: '100%',
    },
    header: {
        fontSize: 16,
        fontFamily: 'semiBold',
        textAlign: 'left'
    },
    seeMoreBtn: {
        padding: 4,
        backgroundColor: '#F3F3F3',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 44,
        width: 30,
        height: 30,
    }

})
export default Index;