import React, { useEffect, useState } from 'react';
import { View,  ScrollView, FlatList, TouchableOpacity, Platform, Dimensions ,SafeAreaView} from 'react-native';
import CustomImage from '../shared/customImg'
import Logo from '../../assets/logoTayba.png'
import { mainColor, backgroundColor, secColor, redColor, colorTextDark, colorTextGrey, colorLightGrey } from '../../constants/color'
import { Basket, location, SearchSVG, LocationArrow } from '../../constants/imgs'
import Carosel from './carosel/index';
import CardSM from '../shared/cardSM';
import ProductCard from '../shared/productCard';
import { useNavigation } from '@react-navigation/core';
import { connect, useSelector } from 'react-redux';
import OrderOption from './OrderOption';
import { Text } from '../shared/custom'
import Alert from '../shared/Alert';
import ScalableImage from '../shared/ScalableImage';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import BasketBtn from '../shared/BasketBtn';
// import { SafeAreaView } from 'react-native-safe-area-context';
const Home = (props) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const navigation = useNavigation()
    const [selectLocation, showSelectLocation] = useState(false)
    const [alert, setAlert] = useState(false)
    const [list, setList] = useState([])
    // const [count, setCount] = useState(false)
    useEffect(() => {

        let list = props?.helpers?.categories?.sort((a, b) => a.index - b.index)?.map(category => {
            if (!category.homeScreen) {
                return false
            }
            let { subcategories } = category
            let allItems = []
            let optimizedsubcategories = subcategories?.map(rec => {
                let { items } = rec
                let optimizedList = items?.map(itm => {
                    let itemObject = props.helpers.prds.find(r => r.id === itm.key)
                    return itemObject
                })
                allItems = allItems?.concat(optimizedList)?.filter(r => !!r)
                return { ...rec, items: optimizedList }
            })
            allItems = [...new Set(allItems)]
            return { ...category, subcategories: optimizedsubcategories, allItems }


        })
        setList(list)
    }, [props?.helpers?.prds, props?.helpers?.categories])

    return (
        <>
            {
                alert && (props?.user?.uid && Object.keys(props?.order?.items || {})?.length === 0 ?
                    <Alert
                        title={strings.alert}
                        alert
                        cancelText={'dismiss'}
                        msg={strings.addItemsMsg}
                        onCancel={() => setAlert(false)}
                    />
                    : props?.user?.uid ?
                        <Alert
                            title={strings.alert}
                            confirmText={strings[alert?.actionTitle] || strings.completeProfile}
                            msg={alert.msg}
                            onCancel={() => setAlert(false)}
                            onConfirm={() => {
                                switch (alert.action) {
                                    case 'toggleLocations':
                                        showSelectLocation(true)
                                        break
                                    case 'navigate':
                                        navigation.navigate('Choose your location')
                                        break
                                    default:
                                        break

                                }
                                setAlert(false)
                            }} />
                        : <Alert title={strings.alert}
                            msg={strings.loginMsg}
                            onConfirm={() => { setAlert(false); navigation.navigate('Login') }}
                            confirmText={strings.login}
                            onCancel={() => setAlert(false)} />)
            }
            <SafeAreaView style={{ flex: 1, backgroundColor: mainColor }}>
                {
                    selectLocation && <OrderOption user={props?.user} dismiss={() => showSelectLocation(false)} />
                }


                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: mainColor,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 19,
                    paddingBottom: 10,
                    paddingHorizontal: 16
                }}>
                    <CustomImage uri={Logo} png width={35} height={35} />
                    <TouchableOpacity
                        onPress={() => navigation.navigate('Search')}

                        style={{
                            width: '75%',
                            height: 40,
                            backgroundColor: colorLightGrey,
                            borderRadius: 8,
                            'outline': 'none',
                            color: '#fff',
                            paddingHorizontal: 8,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                        <CustomImage uri={SearchSVG} svg={true} fill={colorTextDark} width={20} height={20} />
                        <Text style={{ fontSize: 12, marginLeft: 8 }}>{strings.search}</Text>
                    </TouchableOpacity>
                    <BasketBtn setAlert={(e) => setAlert(e)} />
                </View>
                <TouchableOpacity
                    onPress={() => {
                        if (props?.user?.uid) {
                            showSelectLocation(true)
                        } else {
                            setAlert(true)
                        }
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 14,
                        paddingHorizontal: 16,
                        backgroundColor: mainColor,
                    }}>
                    <CustomImage uri={location} svg={true} fill={redColor} width={25} height={25} />
                    {props?.order?.location?.area && <Text style={{ fontWeight: '300' }} >{props?.order?.location?.type === 'pickup' ? strings.pickupFrom : strings.deliveringTo}:</Text>}
                    {props?.order?.location?.area && <Text style={{ fontWeight: '600', marginHorizontal: 6 }}>{`${props?.order?.location?.area}`}</Text>}
                    {!props?.order?.location?.area && <Text style={{ fontWeight: '600', marginHorizontal: 6 }}>{strings.selectLocation}</Text>}
                    <CustomImage uri={LocationArrow} svg={true} fill={redColor} width={20} height={20} />
                </TouchableOpacity>
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 150, backgroundColor: colorLightGrey }}>
                    <View style={{ paddingTop: 18, aspectRatio: 1.78, backgroundColor: colorLightGrey }}>
                        <Carosel data={props?.helpers?.banner?.filter(rec => rec.showInBanners)} helpers={props?.helpers} />
                    </View>
                    <View
                    // style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: 'auto' }}
                    >

                        <View style={{ paddingTop: 18, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 16 }}>
                            <Text style={{
                                fontSize: 18,
                                paddingVertical:4,
                                fontFamily: 'semiBold',
                                textAlign: 'left',
                                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
                            }}>
                                {strings.shopByCategory}
                            </Text>

                            <TouchableOpacity
                                onPress={() => navigation.navigate('Results', { filterType: 'prdsCategory' })}>
                                <Text style={{
                                    fontSize: 12,
                                    color: redColor,
                                    textAlign: 'right'
                                }}>
                                    {strings.showAll}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        {/* <FlatList
                            contentContainerStyle={{ paddingTop: 16, paddingHorizontal: 12 }}
                            style={{ width: Platform.OS === 'web' ? '100%' : 'auto' }}
                            data={props.helpers?.categories}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            renderItem={(i, index) =>
                                i.item.categoriesList && <View key={i.item.id + index} style={{
                                    paddingHorizontal: 4, width: Dimensions.get('window').width * (Dimensions.get('window').width > 900 ? 0.3 : 1) / 3 - 8, // Width / 3 - (marginLeft and marginRight for the components)
                                }}>
                                    <TouchableOpacity
                                        onPress={() => navigation.navigate('Results', { category: i.item.id, filterType: 'category' })}>
                                        <CardSM data={i.item} title />
                                    </TouchableOpacity>
                                </View>

                            }
                            keyExtractor={item => item?.id}
                        /> */}
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                            flexWrap: 'wrap',
                            width: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            paddingHorizontal: 16,
                            marginTop: 8,
                        }}>
                            {
                                props.helpers?.categories?.sort((a, b) => a.index - b.index)?.map((item, index) => {
                                    return (
                                        item.categoriesList && <View key={item.id + index} style={{
                                            flexBasis: '25%',
                                            paddingHorizontal: 4,

                                            alignSelf: 'flex-start',
                                            width: Dimensions.get('window').width * (Dimensions.get('window').width > 900 ? 0.25 : 1) / 3 - 8,
                                            // Width / 3 - (marginLeft and marginRight for the components)
                                        }}>
                                            <TouchableOpacity
                                                onPress={() => navigation.navigate('Results', { category: item.id, filterType: 'category' })}>
                                                <CardSM data={item} title />
                                            </TouchableOpacity>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                    {

                        (props?.helpers?.brands?.length ? [...list] : list).map(rec => {
                            let banner = rec.banner ? props.helpers?.banner?.find(ban => ban.key === rec.banner) : false

                            let image = `https://cdn.taybagourmet.com/banners/${banner?.key}/${locale !== 'en' ? 'logo_ar' : 'logo'}`;
                            if (rec?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]?.includes('https://cdn.taybagourmet.com')) {
                                image = rec?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]
                            };

                            return rec && <View key={rec.id || rec} style={{ paddingTop: 30 }}>
                                {banner && <View style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: widthPercentageToDP('100%')
                                }}>
                                    <TouchableOpacity style={{ minHeight: 220, width: widthPercentageToDP('100%') }} onPress={() => {
                                        switch (banner?.type) {
                                            case 'url':
                                                Linking.openURL(banner?.url).catch(err => console.log(err))
                                                break;
                                            case 'brand':
                                                navigation.navigate('Results', { brand: props?.helpers?.brands?.find(rec => rec.id === banner?.selected), filterType: 'brand' })
                                                break;
                                            case 'product':
                                                navigation.navigate('Product', { id: banner?.selected, data: props?.helpers?.prds?.find(rec => rec.id === banner?.selected) })
                                                break;
                                            case 'prdCat':
                                                navigation.navigate('Results', { category: banner?.selected, filterType: 'category' })
                                                break;
                                            default:
                                                break;
                                        }
                                    }} key={banner}  >
                                        {Platform.OS !== 'web' ? (locale === 'en' ? (banner?.img?.length ?
                                            <ScalableImage
                                                width={Dimensions.get('window').width}
                                                source={{ uri: image, cache: 'force-cache' }}
                                            /> : <></>) :
                                            (banner?.img_ar?.length ?
                                                <ScalableImage
                                                    width={Dimensions.get('window').width}
                                                    source={{ uri: image, cache: 'force-cache' }}
                                                /> : <></>)) :
                                            (
                                                (locale === 'en' ? (banner?.img?.length ?
                                                    <ScalableImage
                                                        width={Dimensions.get('window').width > 900 ? Dimensions.get('window').width * 0.3 : Dimensions.get('window').width}
                                                        // width={Dimensions.get('window').width}
                                                        uri={banner?.img[0]}
                                                        source={{ uri: image, cache: 'force-cache' }}
                                                    /> : <></>) :
                                                    (banner?.img_ar?.length ?
                                                        <ScalableImage
                                                            width={Dimensions.get('window').width > 900 ? Dimensions.get('window').width * 0.3 : Dimensions.get('window').width}
                                                            source={{ uri: image, cache: 'force-cache' }}
                                                        /> : <></>))
                                            )}
                                        <View style={{ paddingTop: 20 }} />
                                    </TouchableOpacity>
                                </View>}
                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 16 }}>
                                    <Text style={{
                                        fontSize: 18,
                                        paddingVertical:4,
                                        // fontWeight: 'bold',
                                        fontFamily: 'semiBold'
                                    }}>
                                        {(locale === 'en' ? rec.name : rec.name_ar) || strings.shopByBrand}
                                    </Text>
                                    {
                                        rec && rec !== "Shop by brand" && <TouchableOpacity
                                            onPress={() => navigation.navigate('Results', { category: rec.id, filterType: 'category' })}
                                        >
                                            <Text style={{
                                                fontSize: 12,
                                                color: redColor
                                            }}>
                                                {strings.showAll}
                                            </Text>
                                        </TouchableOpacity>
                                    }

                                </View>
                                <View style={{ flexDirection: 'column', width: 'auto', alignItems: 'flex-start' }}>
                                    {
                                        rec && rec !== "Shop by brand" ?
                                            <FlatList
                                                initialNumToRender={3}
                                                contentContainerStyle={{ paddingHorizontal: 12, alignItems: 'center' }}
                                                style={{ width: Platform.OS === 'web' ? '100%' : 'auto' }}
                                                data={(rec.allItems)?.slice(0, 7)}
                                                horizontal={true}
                                                maxToRenderPerBatch={2}
                                                getItemLayout={(data, index) => (
                                                    { length: 180, offset: 180 * index, index }
                                                )}
                                                removeClippedSubviews={true}
                                                showsHorizontalScrollIndicator={false}
                                                renderItem={(i) =>
                                                    <View key={i.item} style={{ width: 180 }} >
                                                        <ProductCard key={i?.item?.id + rec?.name} index={i?.item?.id} data={i?.item} />
                                                    </View>
                                                }
                                                keyExtractor={item => item?.id}
                                            />
                                            :
                                            <FlatList
                                                // initialNumToRender={3}
                                                contentContainerStyle={{  paddingTop:8, paddingHorizontal: 12, paddingBottom: 40 }}
                                                style={{ width: Platform.OS === 'web' ? '100%' : 'auto' }}
                                                data={props?.helpers?.brands}
                                                horizontal={true}
                                                getItemLayout={(data, index) => (
                                                    { length: 180, offset: 180 * index, index }
                                                )}
                                                showsHorizontalScrollIndicator={false}
                                                renderItem={(i) =>
                                                    <TouchableOpacity
                                                        key={i.index}
                                                        onPress={() => navigation.navigate('Results', { brand: i.item, filterType: 'brand' })}
                                                        style={{ paddingRight: 8, width: Dimensions.get('window').width > 900 ? widthPercentageToDP('10') : widthPercentageToDP('30') }}
                                                    >
                                                        {
                                                            i.item?.img?.length ?
                                                                <CardSM data={i.item} />
                                                                :
                                                                <View style={{ backgroundColor: mainColor, width: '100%', height: '100%', borderRadius: 12, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Text style={{ fontSize: 12 }}>{i.item.name}</Text>
                                                                </View>
                                                        }

                                                    </TouchableOpacity>

                                                }
                                                keyExtractor={item => item?.id}
                                            />
                                    }
                                </View>
                            </View>
                        })
                    }
                </ScrollView>
            </SafeAreaView >
        </>
    );
};

const mapStateToProps = ({ user, helpers, order, locale }) => {
    return { user, helpers, order, locale }
}
export default connect(mapStateToProps, {})(Home);