import React, { useState } from "react";
import { useNavigation } from '@react-navigation/core';
import { useSelector } from "react-redux";
import { Skeleton } from "native-base";
import { Image, Platform, TouchableOpacity, View, Linking, useWindowDimensions } from 'react-native';

const SlideComp = ({ data, helpers }) => {
    const navigation = useNavigation()
    const { locale } = useSelector((state) => state.locale);
    const [loading, setLoading] = useState(true)
    let image = `https://cdn.taybagourmet.com/banners/${data.id}/${locale !== 'en' ? 'logo_ar' : 'logo'}`;
    if (data?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]?.includes('https://cdn.taybagourmet.com')) {
        image = data?.[locale !== 'en' ? 'img_ar' : 'img']?.[0]
    };
    const { width } = useWindowDimensions();

    return (
        <View style={{ width: Platform.OS === 'web' ? 'auto' : width }} >
            <TouchableOpacity onPress={() => {
                switch (data?.type) {
                    case 'url':
                        Linking.openURL(data?.url).catch(err => console.log(err))
                        break;
                    case 'brand':
                        navigation.navigate('Results', { brand: helpers?.brands?.find(rec => rec.id === data?.selected), filterType: 'brand' })
                        break;
                    case 'product':
                        navigation.navigate('Product', { id: data?.selected, data: helpers?.prds?.find(rec => rec.id === data?.selected) })
                        break;
                    case 'prdCat':
                        navigation.navigate('Results', { category: data?.selected, filterType: 'category' })
                        break;
                    default:
                        break;
                }
            }} key={data.id} style={{ paddingHorizontal: 16 }} >
                <Image
                    style={{
                        width: '100%',
                        aspectRatio: 1.78,
                        borderRadius: 8,
                        resizeMode: "cover"

                    }}
                    onLoadEnd={() => setLoading(false)}

                    source={{ uri: image, cache: 'force-cache' }}
                />

                {/* <View style={{ paddingTop: 20 }} /> */}

            </TouchableOpacity>
            {

                loading && <View style={{ position: 'absolute', width: Platform.OS === 'web' ? '100%' : width, aspectRatio: 1.78, paddingHorizontal: 16 }}>
                    <Skeleton
                        style={{ borderRadius: 8, height: '100%', aspectRatio: 1.78 }}
                    />
                </View>


            }
        </View>
    )

}

export default SlideComp;