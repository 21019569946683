import React from 'react';
import { View, SafeAreaView, Platform, TextInput, TouchableOpacity } from 'react-native';
import Image from './customImg'
import { BackSVG, Basket, ArrowRight } from '../../constants/imgs';
import { backgroundColor, mainColor, secColor } from '../../constants/color';
import { useNavigation } from '@react-navigation/core';
import { Text } from '../shared/custom'
import { useSelector } from 'react-redux';
const CustomHeader = ({ basic, hideBack, title = 'Unknown' }) => {
    const navigation = useNavigation()
    const { locale, strings } = useSelector((state) => state.locale);

    return (
        <>
            <SafeAreaView style={{  backgroundColor: mainColor }} />
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: mainColor }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // paddingHorizontal: 16,
                    alignItems: 'center',
                    width: '90%',
                    paddingVertical: Platform.OS === 'web' || Platform.OS === 'android' ? 16 : 16,
                    paddingBottom: 16,

                }}>
                    {!hideBack ? <TouchableOpacity
                        onPress={() => navigation.goBack()}
                    >
                        <Image uri={locale === 'en' ? BackSVG : ArrowRight} width={25} height={25} svg />

                    </TouchableOpacity>
                        :
                        <View style={{ width: 20 }} />
                    }
                    <Text style={{ fontFamily: 'semiBold', fontSize: 18, width: '80%', textAlign: 'center' }}> {title}</Text>
                    {
                        basic ?
                            <View style={{ width: 20 }} />
                            :
                            <Image uri={Basket} width={20} height={20} svg />
                    }


                </View>
            </View>
            {
                !basic && <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 8, backgroundColor: mainColor }}>
                    <TextInput style={{ outline: 'none', paddingHorizontal: 8, height: 40, width: '90%', borderRadius: 8, backgroundColor: secColor }} />
                </View>
            }

        </>
    );
};

export default CustomHeader;