import React from 'react';
import { View, TouchableOpacity } from 'react-native'
import { mainColor, redColor } from '../../constants/color';
import { Dot, FilledDot } from '../../constants/imgs';
import Image from './customImg'
import { Text } from './custom'

const ListCard = ({ data, onPress, selector, selected, paddingVertical = 24, size = 18 }) => {
    return (
        <TouchableOpacity style={{ marginVertical: 8 }} onPress={onPress}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
                backgroundColor: mainColor,
                paddingVertical: paddingVertical,
                borderRadius: 8,
                borderColor: '#2B2730'
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }}>
                    {
                        data?.img ?
                            <Image
                                svg
                                width={size}
                                height={size}
                                uri={data.img}
                                fill={redColor}
                            /> : null
                    }
                    <View style={{ paddingHorizontal: 8, }}>
                        <Text style={{ fontFamily: 'semiBold', textAlign: 'left' }}>{data?.title}</Text>
                        {
                            data?.desc ?
                                <Text style={{ fontSize: 12, color: '#383838' , textAlign: 'left'}}>
                                    {data.desc}
                                </Text> : null
                        }
                    </View>
                    {
                        selector ?
                            <View>
                                <Image
                                    svg
                                    width={18}
                                    height={18}
                                    uri={selected ? FilledDot : Dot}
                                    fill={redColor}
                                />
                            </View> : null

                    }
                </View>
                {
                    data?.value ?
                        <Text style={{ paddingHorizontal: 8, fontFamily: 'semiBold', color: data?.valueColor || '#cacaca' }}>{data?.value}</Text>
                        : null
                }
            </View>
        </TouchableOpacity>

    );
};

export default ListCard;