import React, { useState } from 'react';
import { View, FlatList, TouchableOpacity, Platform } from 'react-native';
import { backgroundColor, mainColor, redColor } from '../../constants/color';
import ProductCard from '../shared/productCard';
import { Text } from '../shared/custom'
import { connect, useSelector } from 'react-redux';
import { getDate } from '../../helpers/helpers';
import RateOrder from '../shared/RateOrder';
const OrderRecord = ({ review, navigation, data }) => {
    let orderDate = getDate(data?.created)
    const { locale, strings } = useSelector((state) => state.locale);
    let colors = { new: '#DE8329', accepted: '#32C5FF', active: '#6DD400', completed: '#6DD400', canceled: '#DE2934', rejected: '#DE2934' }

    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor, marginBottom: 16, padding: 16, width: 'auto', alignItems: 'flex-start', }}>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View >
                    <Text style={{ fontFamily: 'semiBold', fontSize: 12, textAlign: 'left' }}>{strings.order + " " + data?.orderNumber} </Text>
                    <Text style={{ fontSize: 10, paddingTop: 3, textAlign: 'left' }}>{strings.placedOn + " " + orderDate.date} {orderDate.time} </Text>
                </View>
                <View style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                    <Text style={{ fontFamily: 'semiBold', fontSize: 12, textAlign: 'left' }}>{strings.status} </Text>
                    <Text style={{ color: colors[data?.status], fontFamily: 'semiBold', fontSize: 12, paddingTop: 3 }}>{strings[data?.status]} </Text>
                </View>

            </View>
            <FlatList
                style={{ paddingTop: 16, width: Platform.OS === 'web' ? '100%' : 'auto' }}
                data={data?.items}
                getItemLayout={(data, index) => (
                    { length: 180, offset: 180 * index, index }
                )}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                renderItem={(i) => {
                    return <View key={i.item.key} style={{ width: 180 }} >
                        <ProductCard basic data={i.item} index={i.item.id} />
                    </View>

                }

                }
                keyExtractor={item => item.key}
            />
            <View style={{ width: '100%', paddingTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                {
                    data.rated || ['new', 'pending', 'accepted', 'active'].includes(data?.status) ?
                        <View />
                        :
                        <TouchableOpacity style={{ padding: 8, backgroundColor: '#E0262D20', borderRadius: 6 }} onPress={() => review(data.id)}>
                            <Text style={{ color: redColor, fontSize: 10 }}>{strings.reviewOrder}</Text>
                        </TouchableOpacity>
                }

                <TouchableOpacity onPress={() => navigation.navigate('Status Page', { data })}>
                    <Text style={{ color: redColor, fontSize: 12 }}>{strings.viewDetails}</Text>
                </TouchableOpacity>
            </View>

        </View>
    )

}
const PreviousOrders = ({ navigation, orders }) => {
    const [show, setShow] = useState(false)
    const { strings } = useSelector((state) => state.locale);

    return (
        <View style={{ flex: 1, backgroundColor: mainColor, maxHeight: Platform.OS === 'web' ? '100vh' : 'auto', width: 'auto', alignItems: 'flex-start', }}>
            <FlatList
                ListEmptyComponent={() => {
                    return <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                        }}>
                        <Text style={{ fontSize: 18 }}>{strings.noOrders}</Text>
                    </View>
                }}
                style={{ width: '100%', flex: 1 }}
                contentContainerStyle={{ paddingBottom: 70, flexGrow: 1 }}
                data={Object.values(orders || {})?.sort((a, b) => { return (b.created - a.created) })}
                showsVerticalScrollIndicator={false}
                renderItem={(i) => {
                    return <View key={i.item.id}>
                        <OrderRecord data={i.item} navigation={navigation} review={(id) => setShow(id)} />
                    </View>
                }}
                keyExtractor={item => item.id}
            />
            {
                show && <RateOrder show={show} dismiss={() => setShow(false)} />
            }

        </View>
    );
};
const mapStateToProps = ({ orders }) => {
    return { orders: orders || [] }
}
export default connect(mapStateToProps, {})(PreviousOrders);