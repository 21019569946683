export default {
  orderFromTayba: 'اطلب من طيبة',
  shopByCategory: 'تسوق حسب الفئة',
  search: 'بحث',
  showAll: 'إظهار الكل ←',
  shopByBrand: 'تسوق وفق الماركة',
  deliveringTo: 'التوصيل إلى: ',
  Delivery: 'توصيل',
  Pickup: 'استلام',
  newArrival: 'اضيف حديثا',
  Tayba: 'طيبة',
  AED: 'درهم',
  SAR: 'ريال',
  deliveringTo: 'التوصيل إلى',
  readyToEat: 'العناصر الجاهزة للأكل',
  fastDelivery: 'توصيل سريع',
  estDelivery: 'مقدر. توصيل:',
  otherItems: 'عناصر أخرى',
  scheduleDelivery: 'جدولة التسليم',
  paymentMethod: 'طريقة الدفع او السداد',
  chooseDateTime: 'اختر التاريخ والوقت',
  'Cash On Delivery': 'الدفع عند الاستلام',
  'Card On Delivery (POS Machine)': 'بطاقة عند الاستلام (آلة نقاط البيع)',
  'Cash On Pickup': 'الدفع عند التسليم',
  'Card On Pickup (POS Machine)': 'بطاقة عند التسليم (آلة نقاط البيع)',
  enterPromocode: 'إدخال الرمز الترويجي',
  applyPromo: "تطبيق",
  subTotal: 'المجموع الفرعي',
  tax: 'الضريبة (0.5٪)',
  discount: 'خصم',
  total: 'المجموع',
  completeOrder: 'اكمل الطلب',
  change: 'تغيير',
  searchPlace: 'ابحث عن مكان',
  confirmLocation: 'تأكيد الموقع',
  yourName: 'اسمك',
  yourNumber: 'رقم الهاتف',
  yourEmail: 'بريدك الالكتروني',
  area: 'المنطقة',
  street: 'الشارع',
  buildingName: 'اسم المبنى',
  floor: 'الدور',
  flat: 'الشقة',
  Home: 'منزل',
  Work: 'عمل',
  Other: 'آخر',
  saveAddress: 'حفظ العنوان',
  generalInfo: 'معلومات عامة',
  locations: 'المواقع',
  favourites: 'المفضلة',
  languages: 'اللغات',
  orders: 'الطلبات',
  addLocation: 'إضافة الموقع',
  results: 'نتائج',
  profile: 'الملف الشخصي',
  favouritesList: 'قائمة المفضلة',
  previousOrders: 'الطلبات السابقة',
  myAddresses: 'عناويني',
  getInTouch: 'تواصل معنا',
  logOut: 'تسجيل خروج',
  logoutMsg: 'تريد الخروج من طيبة',
  logoutTitle: 'تسجيل خروج؟',
  cancel: 'إلغاء',
  general: 'عامة',
  settings: 'إعدادات',
  otherItems: 'أشياء أخرى',
  "Scheduled Delivery": 'التوصيل المجدول',
  yourOrder: 'طلبك',
  statusPage: 'حالة الطلب',
  chat: 'محادثة',
  cart: 'السلة',
  warning: 'تحذير',
  cancelOrder: 'الغاء الطلب',
  areYouSure: 'هل أنت متأكد؟',
  quantity: 'الكمية',
  readyToEat: 'جاهز للاكل',
  allPricesIncludeVAT: 'تشمل جميع الأسعار ضريبة القيمة المضافة',
  choose: 'اختر',
  chooseAtLeaset: 'اختر على الأقل',
  chooseUpto: 'اختر لغاية',
  ingredients: 'مكونات من سوقنا',
  addToCart: 'أضف إلى السلة',
  required: 'مطلوب',
  alert: 'انذار',
  completeProfile: 'اكمل الملف الشخصي',
  completeProfileMsg: 'يرجى إكمال ملف التعريف الخاص بك قبل الطلب',
  login: 'تسجيل الدخول',
  loginMsg: 'يرجى تسجيل الدخول لاستخدام هذه الميزة',
  removeItem: 'حذف',
  removeItemMsg: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
  facebookSignin: 'الدخول باستخدام الفيسبوك',
  googleSignin: 'الدخول باستخدام جوجل',
  appleSignin: 'الدخول باستخدام ابل',
  enterMobileNumber: 'أدخل رقم هاتفك للمتابعة',
  phoneNumber: 'رقم الهاتف',
  continue: 'المتابعة',
  enterValidNumber: 'الرجاء إدخال رقم هاتف صحيح',
  orVia: 'او عن طريق',
  off: 'خصم',
  home: 'الصفحة الرئيسية',
  selectLocation: 'اختر موقعك',
  verifyPhoneNumber: 'تحقق من الرقم',
  verifyNumberMsg: 'لم يتم التحقق من رقم الهاتف المحمول. يرجى التحقق من خلال إرسال OTP إلى رقمك',
  sendOTP: 'إرسال OTP',
  noOrders: 'لا توجد طلبات',
  noFavourites: 'لا مفضلات',
  noLocations: 'لا توجد مواقع مضافة',
  noResults: 'لا توجد نتائج',
  dismiss: 'رجوع',
  minOrderIs: 'الحد الأدنى للطلب هو',
  toThisArea: 'لهذه المنطقة',
  changeEmail: 'تغيير البريد الالكتروني',
  resetPassword: 'إعادة تعيين كلمة المرور',
  name: 'الاسم',
  gender: 'الجنس',
  DOB: 'تاريخ الميلاد',
  saveInfo: 'احفظ المعلومات',
  order: 'الطلب#',
  placedOn: 'تم الطلب في',
  status: 'الحالة',
  reviewOrder: 'قيم هذا الطلب',
  viewDetails: 'عرض التفاصيل ←',
  reviewYourOrder: 'قيم طلباتك',
  'How was your last order': 'كيف كان طلبك الأخير؟',
  'Overall order experience': 'تجربة النظام العام',
  'Delivery on time': 'التسليم في الوقت المحدد',
  'Rate products': 'قيم المنتجات',
  writeFeedback: 'اكتب ملاحظاتك',
  submitReview: 'إرسال المراجعة',
  Male: 'ذكر',
  Female: 'انثى',
  addItemsMsg: 'يرجى إضافة منتجات إلى عربة التسوق الخاصة بك أولا!',
  enterVerification: 'الرجاء إدخال رمز التحقق المرسل',
  to: 'إلى',
  DontReceive: 'لم تحصل على رمز!',
  resendIn: 'إعادة إرسال خلال',
  resend: 'إعادة إرسال',
  upto: 'لغاية',
  new: 'قيد الانتظار',
  accepted: 'مقبول',
  active: 'نشط',
  completed: 'اكتمل',
  canceled: 'ملغى',
  rejected: 'رفض',
  changeNumber: 'تغيير رقم الهاتف المحمول',
  confirm: 'تأكيد',
  pickupFrom: 'الاستلام من',

  sorry: 'ناسف!',
  notServingArea: 'نحن لا نخدم المنطقة المختارة في هذا الوقت',
  addNewAddress: 'أضف عنوان جديد +',
  addressDetails: 'تفاصيل العنوان',
  deleteLocation: 'هل أنت متأكد أنك تريد حذف هذا الموقع؟',
  closedMsg: 'خيار الاستلام من طيبة جورميه غير متوفر خارج ساعات العمل',
  addedToCart: 'تمت الإضافة إلى عربة التسوق',
  continueShopping: 'استمر في التسوق',
  viewCart: 'عرض عربة التسوق',
  priceOnSelection: 'السعر حسب الاختيار',
  "Select Date": 'اختيار الموعد',
  "Sorry,This branch is not working at the moment!": 'عذرا ، هذا الفرع لا يعمل في الوقت الحالي!',
  "Sorry, Currently we are not serving this area!": 'عذرا ، حاليا نحن لا نخدم هذه المنطقة!',
  "Change pickup spot": 'تغيير نقطة الالتقاط',
  "Change address": 'غير عنوان التوصيل',
  "Select address": 'حدد العنوان',
  "Please select delivery address": 'الرجاء تحديد عنوان التسليم',
  existedNumber: 'رقم الهاتف هذا قيد الاستخدام بالفعل',
  needFreeShipping: 'هل ما زلت بحاجة إلى شحن مجاني؟',
  add: 'أضف',
  forFreeShipping: 'أضف {amount} {currency} أكثر للحصول على شحن مجاني',
  free: 'مجاني',
  "Items": 'الطلبات',
  "Order Confirmation": 'تأكيد الطلب',
  "Are you sure you want to place this order? We’re excited to get it ready for you!": 'هل أنت متأكد أنك تريد تقديم هذا الطلب؟ نحن متحمسون لتحضيره لك!',
  "Place Order": 'وضع الطلب',
  "New Version": "إصدار جديد من تطبيق Tayba متاح الآن. يرجى التحديث إلى أحدث إصدار الآن.",
  "Update": 'تحديث',
  "No Slots available for selected date": 'لا توجد فترات زمنية متاحة في التاريخ المحدد',
  "Sorry we cannot accept FAST DELIVERY orders at the moment": 'عذرًا ، لا يمكننا قبول طلبات التسليم السريع في الوقت الحالي',
  "You can place your order after": 'يمكنك تقديم طلبك بعد',
  "Due to updates during checkout, some items have been removed from your cart. Please review your cart before proceeding!": "نظرًا للتحديثات أثناء الطلب ، تمت إزالة بعض العناصر من عربة التسوق الخاصة بك. يرجى مراجعة عربة التسوق الخاصة بك قبل المتابعة!",
  country: 'الدولة',
  "No Saved Cards": 'لا توجد بطاقات محفوظة',
  "Place an order to add your Card": 'قم بطلب لإضافة بطاقتك',
  "Add Card": 'إضافة بطاقة',
  paymentMethods: 'طرق الدفع',
  addPaymentMethod: 'إضافة طريقة دفع',
  "+ Add new card": 'إضافة بطاقة جديدة',
  "Card number": 'رقم البطاقة',
  "Expiration date": 'تاريخ الانتهاء',
  "Card Payment": 'الدفع بالبطاقة',
  "Apple Pay": "Apple Pay",
  "Google Pay": "Google Pay",
  "Are you sure ,This card will be deleted permanently": 'هل أنت متأكد ، سيتم حذف هذه البطاقة بشكل دائم',
  "Delete": 'حذف',
  "privacy": "الخصوصية",
  "DeleteAccount": 'حذف الحساب',

  "Date": 'التاريخ',
  "Time": 'الوقت',
  "specialNotes": 'ملاحظات خاصة',
  chooseMeal: 'اختر وجبة',
  yourMeals: 'وجباتك',
  noMeal: 'أوه! مازلت لم تضيف وجبة؟',
  noMealDesc: 'يبدو أنك لم تقم بإضافة وجبة إلى سلة غددني، مرر سريعًا للاختيار من بين الوجبات الموجودة في الأعلى أو اضغط على تبديل لتغيير الوجبات',
  "Shuffle meal": 'تبديل الوجبات',
  "Order Now": 'اطلب الآن',
  "Let us choose your today’s lunch": 'دعنا نختار غداءك اليوم',
  "Introducing our new Meal Suggestion feature! Let us craft the perfect meal for you": 'نقدم لك ميزة اقتراح الوجبات الجديدة! دعنا نصنع الوجبة المثالية لك',
  "Order later": 'اطلب لاحقا',
  Edit: 'تعديل',
  "Edit item": 'تعديل العنصر',
  "Price includes Taxes": 'السعر يشمل الضرائب',
  "You can select only 1 item": 'يمكنك اختيار عنصر واحد فقط',
  item: 'عنصر',
  Update: 'تحديث',

  apply: 'تطبيق',
  remove: 'إزالة',
  "Order summary": 'ملخص الطلب',
  "Add coupon": 'إضافة كوبون',
  "Add special notes to your order": 'أضف ملاحظات خاصة إلى طلبك',
  "Add New Payment Method": 'إضافة طريقة دفع جديدة',
  "Select Card": 'اختر بطاقة',
  "Select Payment": 'اختر طريقة الدفع',
  slideToPay: "اسحب {type} | {amount}",
  order: 'للطلب',
  pay: 'للدفع',
  "Your addresses": 'عناوينك',
  "Add new address" : 'إضافة عنوان جديد',
  ghadeni: 'غديني',
  checkout:'الدفع',
  Wallet: 'المحفظة',
  Vouchers:'القسائم',
  "Wallet Records":'سجل المحفظة',
  "Points collected":'النقاط المجمعة',
  "Points redeemed":'النقاط المستردة',
  "Points":'النقاط',
  "My Vouchers":'قسائمي',
  "Activity":'السجل',
  "You do not have enough points to redeem this voucher":'ليس لديك نقاط كافية لاسترداد هذه القسيمة',
  "Redeem voucher":'استرداد القسيمة',
  "Are you sure you want to redeem this voucher?":'هل أنت متأكد أنك تريد استرداد هذه القسيمة؟',
  "Redeem":'استرداد',
  "Recent activity":'السجل الأخير',
  "Redeem now":'استرداد الآن',
  "All":'الكل',
  "Expense":'المصروف',
  "Income":'الدخل',
  "Use your vouchers":'استخدم قسائمك',







}
