import firebase from 'firebase';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import '@firebase/functions';
import '@firebase/analytics';
// import '@firebase/messaging';
// const messaging = firebase.messaging();

import { Platform } from "react-native";

const firebaseConfig = {
    apiKey: "AIzaSyDKh65DXmzsGhOlqcpDTi_U_B1autbsgqc",
    authDomain: "tayba-2b919.firebaseapp.com",
    projectId: "tayba-2b919",
    storageBucket: "tayba-2b919.appspot.com",
    messagingSenderId: "383645174475",
    appId: "1:383645174475:web:751c37e72623dfd4cf294e"
};
let firestore;
let auth;
let storage;
let messaging;
let functions;
let analytics;

if (!firebase.apps.length) {
    // if (Platform.OS === 'web') {
    firebase.initializeApp(firebaseConfig);
    firestore = firebase.firestore
    auth = firebase.auth
    storage = firebase.storage
    messaging = firebase.messaging
    functions = firebase.functions
    analytics = firebase.analytics
    // }
}
// functions().useEmulator('localhost', 5000);

export { firestore, auth, storage, messaging, functions, analytics };

