import React from 'react';
import { FlatList, View } from 'react-native';
import { backgroundColor } from '../../constants/color';
import ListCard from '../shared/ListCard';
import { AEFlag, Globe, SAFlag } from '../../constants/imgs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { SetOrderLocation } from '../../reducers/order';

const Country = ({ }) => {
    const { country } = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const DATA = [
        {
            lang: 'ae',
            id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
            title: 'Emirates',
            currency: 'AED',
            img: AEFlag
        },
        {
            lang: 'sa',
            id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
            title: 'Saudi Arabia',
            currency: 'SAR',
            img: SAFlag
        },

    ];


    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            <FlatList
                contentContainerStyle={{ width: '100%', paddingTop: 16, paddingHorizontal: 12 }}
                data={DATA}
                numColumns={1}
                showsVerticalScrollIndicator={false}
                renderItem={(i) =>
                    <View key={i.item} style={{ width: '100%' }} >
                        <ListCard index={i.item.id} data={i.item} selector selected={i.item.lang === country} onPress={() => {
                            dispatch({ type: 'SET_COUNTRY', payload: i.item.lang })
                            dispatch({ 'type': 'SET_CURRENCY', 'payload': i.item.currency })
                            dispatch(SetOrderLocation(false))
                            navigation.goBack()
                        }} />
                    </View>
                }
                keyExtractor={item => item.id}
            />
        </View>
    );
};

export default Country;