import { createAction } from 'redux-action';
import { GET_PRDS } from './prds';
import { EnableAdd } from '../helpers/helpers';
export const UPDATE_ORDER_ITEMS = 'UPDATE_ORDER_ITEMS';
export const UPDATE_ORDER_ITEMS_COUNT = 'UPDATE_ORDER_ITEMS_COUNT';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';

export const UPDATE_ORDER_LOCATION = 'UPDATE_ORDER_LOCATION';
export const UPDATE_ORDER_DATE = 'UPDATE_ORDER_DATE';
export const FLUSH_ORDER = 'FLUSH_ORDER';
export const UPDATE_ORDER_DISCOUNT = 'UPDATE_ORDER_DISCOUNT';
export const SET_ORDER_NOTE = 'SET_ORDER_NOTE'
export const SET_LOCATION_TIMINGS = 'SET_LOCATION_TIMINGS'
export const UpdateOrderItems = createAction(UPDATE_ORDER_ITEMS, (list) => {
    return list
});

export const SetOrderLocation = createAction(UPDATE_ORDER_LOCATION, (list) => {
    return list
});
export const FlushOrder = createAction(FLUSH_ORDER, (list) => {
    return list
});
export const SetOrderDeliveryDate = createAction(UPDATE_ORDER_DATE, (list) => {
    return list
});
export const setOrderDiscount = createAction(UPDATE_ORDER_DISCOUNT, (list) => {
    return list
});
const initial = { items: {} };

let ex = (state = initial, action) => {
    switch (action.type) {
        case UPDATE_ORDER_ITEMS:
            return { ...state, items: action.payload }
        case UPDATE_ORDER_ITEMS_COUNT:
            return { ...state, items: { ...state.items, [action.payload.seq]: action.payload } }
        case REMOVE_ORDER_ITEM:
            let items = { ...state.items };
            delete items[action.payload];
            return { ...state, items }
        case UPDATE_ORDER_LOCATION:
            return { ...state, location: action.payload }
        case SET_LOCATION_TIMINGS:
            return { ...state, locationTimings: action.payload }
        case UPDATE_ORDER_DATE:
            return { ...state, date: action.payload }
        case FLUSH_ORDER:
            return { items: {}, location: state.location, locationTimings: state.locationTimings }
        case UPDATE_ORDER_DISCOUNT:
            return { ...state, discount: action.payload }
        case SET_ORDER_NOTE:
            return { ...state, note: action.payload }
        case GET_PRDS:
            let listUpdated = {};
            if (state?.items) {
                listUpdated = Object.keys(state.items || {}).reduce((val, key) => {
                    let current = state.items?.[key];
                    let lastSnap = action.payload?.find(rec => rec?.key === current?.key)
                    if (lastSnap) {
                        let enabled = EnableAdd({
                            ...lastSnap, count: current?.count, selectedModifiers: current?.selectedModifiers
                        })
                        if (enabled) {
                            val[key] = { ...lastSnap, count: current?.count, selectedModifiers: current?.selectedModifiers }
                        }    
                    } else {
                        val[key] = current
                    }
                    return val
                }, {})
            }

            return { ...state, items: listUpdated || state?.items }
        default:
            return state;
    }
};
export default ex