import React, { useState } from 'react';
import { View, TouchableOpacity, Modal, TextInput, Dimensions, ActivityIndicator } from 'react-native';
import { colorLightGrey, colorTextDark, colorTextGrey, colorTextLightGrey, mainColor, redColor } from '../../constants/color';
import { CloseBtn, Star } from '../../constants/imgs';
import CustomImage from '../shared/customImg'
import ActionBtn from '../shared/ActionBtn';
import { Text } from '../shared/custom'
import { useSelector } from 'react-redux';
import { firestore } from '../../constants/firebase/config'
import Rate, { AndroidMarket } from 'react-native-rate'
import { REACT_APP_ORDERS_COLLECTION } from '../../constants/variables/index';

const Stars = ({ count, update }) => {
    return (
        <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
            {
                [0, 1, 2, 3, 4].map(rec =>
                    <View key={rec}>
                        <TouchableOpacity onPressIn={() => update(rec)} key={rec} style={{ paddingRight: 8, zIndex: 999 }}>
                            <View pointerEvents='none'>
                                <CustomImage width={30} height={30} svg fill={count > rec ? redColor : colorTextLightGrey} uri={Star} />
                            </View>
                        </TouchableOpacity>
                    </View>

                )
            }

        </View>
    )
}

const RateOrder = ({ show, dismiss, fromHome }) => {
    const [rate, setRate] = useState({ overall: 3, delivery: 3, product: 3 });
    const [comment, setComment] = useState('');
    const { locale, strings } = useSelector((state) => state.locale);
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            animationType='slide'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={!!show}

        >
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: Dimensions.get('window').width > 900 ? '30%' : '100%', height: '100%', elevation: 4, shadowColor: '#000' }}>
                {loading && <View style={{
                    // backgroundColor: redColor,
                    padding: 16,
                    borderRadius: 10,
                    position: 'absolute',
                    zIndex: 10000000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                }}>
                    <ActivityIndicator size="large" color={redColor} />
                </View>
                }
                <View style={{ backgroundColor: mainColor, width: '80%', height: '60%', minHeight: 470, borderRadius: 16, paddingHorizontal: 16 }}>
                    <Text style={{ textAlign: 'left', fontSize: 20, fontFamily: 'semiBold', paddingTop: 24, width: '85%' }}>
                        {fromHome ? strings?.['How was your last order'] : strings.reviewYourOrder}
                    </Text>
                    <TouchableOpacity onPressIn={() => dismiss()} style={{ position: 'absolute', padding: 16, right: 0, zIndex: 99 }}>
                        <CustomImage width={30} height={30} fill={redColor} svg uri={CloseBtn} />
                    </TouchableOpacity>
                    {
                        [{ id: 'overall', text: 'Overall order experience' }, { id: 'delivery', text: 'Delivery on time' }, { id: 'product', text: 'Rate products' }].map(rec => <View key={rec.id} style={{ paddingTop: 16 }}>
                            <Text style={{ textAlign: 'left', }}>{strings[rec.text]}</Text>
                            <Stars count={rate[rec.id]} update={(val) => {
                                rate[rec.id] = val + 1;
                                setRate({ ...rate })
                            }} />
                        </View>)
                    }
                    <View style={{ paddingTop: 16, paddingBottom: 16, }}>
                        <TextInput multiline
                            placeholder={strings.writeFeedback}
                            placeholderTextColor={colorTextDark}
                            style={{ textAlign: locale === 'en' ? 'left' : 'right', outline: 'none', backgroundColor: colorLightGrey, color: '#000', borderRadius: 8, padding: 8, height: 80 }}
                            numberOfLines={4}
                            onChangeText={text => setComment(text)} />
                    </View>

                    <View style={{ width: '100%', flex: 1, paddingVertical: 16, marginBottom: 16 }}>
                        <ActionBtn title={strings.submitReview} fromCart onPress={() => {
                            setLoading(true)
                            let batch = firestore().batch()
                            batch.update(firestore().collection(REACT_APP_ORDERS_COLLECTION).doc(show), { rated: Date.now() });
                            batch.set(firestore().collection('reviews').doc(show), {
                                created: Date.now(),
                                order: show,
                                rate: rate,
                                comment: comment
                            });
                            batch.commit().then(() => {
                                setLoading(false)
                                dismiss();
                                let commulated = (rate.overall + rate.delivery + rate.product) / 3;
                                console.log("commulated",commulated);
                                if (commulated > 3) {
                                    const options = {
                                        AppleAppID: "1584107582",
                                        GooglePackageName: "com.tayba",
                                        preferredAndroidMarket: AndroidMarket.Google,
                                        preferInApp: true,
                                        openAppStoreIfInAppFails: true,
                                    }
                                    Rate.rate(options, (success, errorMessage) => {
                                        if (success) {
                                            // this technically only tells us if the user successfully went to the Review Page. Whether they actually did anything, we do not know.
                                            console.log('success');

                                        }
                                        if (errorMessage) {
                                            // errorMessage comes from the native code. Useful for debugging, but probably not for users to view
                                            console.error(`Example page Rate.rate() error: ${errorMessage}`)
                                        }
                                    })
                                }
                            })
                        }} />
                    </View>
                </View>
            </View>

        </Modal>
    )
};

export default RateOrder;