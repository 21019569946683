import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { mainColor, redColor } from '../../constants/color';
import { Add, minus, Trash } from '../../constants/imgs';
import Image from './customImg'
import { Text } from './custom'
import { connect, useDispatch, useSelector } from 'react-redux';
import { UpdateOrderItems } from '../../reducers/order'
import { checkDiscount, totalPriceForItem } from '../../helpers/helpers';
import Alert from '../shared/Alert';
import { LogEvent } from '../../helpers/index';

const CartCard = ({ data, disabled, UpdateOrderItems, order, offer, prds }) => {
    let image = data?.thumb?.length ? data?.thumb[0] : ''
    let name_ar = ''
    const { locale, strings } = useSelector((state) => state.locale);
    const { currency } = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(false)

    let price = totalPriceForItem(data)
    let activeOffer = disabled ? { percent: data?.receipt?.percent, code: data?.receipt?.code } : offer
    let discounted = checkDiscount(activeOffer, data)
    if (disabled) {
        let prd = prds.find(r => r.id === data.key)
        image = prd?.thumb?.length ? prd?.thumb[0] : ''

    }
    let prd = prds.find(r => r.id === data.key)
    name_ar = prd?.name_ar ? prd?.name_ar : ''

    return (
        <View style={{ paddingHorizontal: 16, backgroundColor: mainColor, borderRadius: 8 }}>
            {
                alert && <Alert
                    title={strings.alert}
                    confirmText={strings.removeItem}
                    msg={strings.removeItemMsg}
                    onCancel={() => setAlert(false)}
                    onConfirm={() => {
                        let { items } = JSON.parse(JSON.stringify(order))
                        delete items[data.id]
                        UpdateOrderItems(items)
                        dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))

                        setAlert(false)
                    }
                    } />
            }
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <View style={{ paddingBottom: 8, width: '75%' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 8, alignItems: 'center' }}>
                        <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{locale === 'en' ? data?.name : (name_ar || data?.name)}</Text>
                        <Text style={{ fontSize: 12, paddingHorizontal: 4 }}>{data?.unit?.value ? (`${data?.unit?.value} ${data?.unit?.label}`) : ''}</Text>
                    </View>
                    {
                        data?.selectedModifiers && Object.values(data?.selectedModifiers || {})?.map(r => <Text
                            key={r?.key + r?.parent}
                            style={{
                                fontSize: 10,
                                paddingTop: 2,
                            }}>{`${r?.count || 1}X ${r?.name}`}</Text>
                        )
                    }
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 4 }}>
                        <Text style={{ fontSize: 10, fontFamily: 'semiBold' }}>
                            <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{Number(price).toFixed(2)} </Text>
                            {/* <Text style={{ fontSize: 14, fontFamily: locale === 'en'  ? 'Poppins-SemiBold' : 'Tajawal-Bold' }}>{discounted ? (data?.price - discounted?.price).toFixed(2) : price} </Text> */}
                            {strings[currency]}
                        </Text>
                        {/* {
                            discounted && <Text
                                style={{
                                    fontSize: 9,
                                    paddingHorizontal: 4,
                                    textDecorationLine: 'line-through'
                                }}>
                                {price} {strings[currency]}
                            </Text>

                        } */}

                    </View>
                </View>
                <View >
                    {
                        image ? <Image
                            width={50}
                            height={50}
                            resizeMode="cover"
                            style={{
                                borderRadius: 8,
                                marginVertical: 8,
                            }}
                            uri={image}
                        />
                            :
                            <View />
                    }
                </View>


            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', paddingBottom: 8 }}>
                <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{`${strings.quantity} ${data?.count}`}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {
                        !disabled && <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <TouchableOpacity style={{ marginHorizontal: 16 }} onPress={() => {
                                setAlert(true)
                            }}>
                                <Image fill={redColor} width={20} height={20} svg uri={Trash} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{ paddingHorizontal: 8 }} onPress={() => {
                                let { items } = JSON.parse(JSON.stringify(order))
                                if (items[data.id].count > 1) {
                                    items[data.id].count -= 1
                                    UpdateOrderItems(items);
                                    dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))
                                } else {
                                    setAlert(true)
                                }
                            }}>
                                <Image fill={redColor} width={20} height={20} svg uri={minus} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                let { items } = JSON.parse(JSON.stringify(order))
                                items[data.id].count += 1
                                UpdateOrderItems(items);
                                dispatch(LogEvent({ event: 'add_to_cart', data: { items: items } }))

                            }}>
                                <Image fill={redColor} width={20} height={20} svg uri={Add} />
                            </TouchableOpacity>
                        </View>
                    }

                </View>
            </View>
        </View>
    )
};
const mapStateToProps = ({ order, offer: { activeOffer }, helpers }) => {
    return { order, offer: activeOffer, prds: helpers?.prds || [] }
}
export default connect(mapStateToProps, { UpdateOrderItems })(CartCard);