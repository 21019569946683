/* fonts */
export const FontFamily = {
  interMedium: "Inter-Medium",
  interRegular: "Inter-Regular",
  interSemiBold: "Inter-SemiBold",
  robotoBold: "Roboto-Bold",
  urbanistRegular: "Urbanist-Regular",
  urbanistMedium: "Urbanist-Medium",
};
/* font sizes */
export const FontSize = {
  size_base: 16,
  size_sm: 14,
  size_xs: 12,
  size_xl: 20,
};
/* Colors */
export const Color = {
  primitivesNeutralWhiteWhite: "#fff",
  primitivesNeutralGreyGrey01: "#f3f3f3",
  primitivesSecondaryRedRed08: "#be2b15",
  primitivesNeutralGreyGrey11: "#2a2a2a",
  primitivesSecondaryRedRed02: "#f8e7e5",
  primitivesNeutralGreyGrey08: "#707070",
  primitivesNeutralGreyGrey06: "#a3a3a3",
  primitivesNeutralBlackBlack: "#000",
  primitivesNeutralGreyGrey07: "#8c8c8c",
  primitivesNeutralGreyGrey02: "#e8e8e8",
};
/* Paddings */
export const Padding = {
  p_base: 16,
  p_9xs: 4,
  p_xs: 12,
  p_xs_2: 11,
  p_5xl: 24,
  p_5xs: 8,
};
/* border radiuses */
export const Border = {
  br_5xl: 24,
  br_xs: 12,
  br_29xl: 48,
  br_37xl: 56,
  br_5xs: 8,
  br_9xs: 4,
  br_11xs: 2,
  br_22xl: 41,
};
export const Gap = {
  gap_sm: 4,
  gap_md: 12,
  gap_lg: 19,
  };
  
export const Bold = (locale) => (locale === 'ae' ? 'Tajawal-Bold' : 'Poppins-Bold');
export const SemiBold = (locale) => (locale === 'ae' ? 'Tajawal-Medium' : 'Poppins-SemiBold');
export const Medium = (locale) => (locale === 'ae' ? 'Tajawal-Medium' : 'Poppins-Medium');
export const Regular = (locale) => (locale === 'ae' ? 'Tajawal-Regular' : 'Poppins-Regular');
export const Light = (locale) => (locale === 'ae' ? 'Tajawal-Light' : 'Poppins-Light');