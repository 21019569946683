import React, { useEffect, useState } from 'react';
import { View, Platform, ActivityIndicator, TouchableOpacity, TouchableWithoutFeedback, Keyboard, ScrollView } from 'react-native';
import { backgroundColor, colorTextDark, mainColor, redColor } from '../../constants/color';
import { TextInput } from 'react-native-paper';
import ActionBtn from '../shared/ActionBtn';
import { Text } from '../shared/custom'
import { firestore, auth, functions } from '../../constants/firebase/config'
import { useSelector } from 'react-redux';
import PhoneInput from '../shared/PhoneInput/index';
import Alert from '../shared/Alert'
import { editSVG } from '../../constants/imgs';
import Image from '../shared/customImg'
import ChangeNumberModal from '../shared/changeNumberModal'
import { Logger } from '../../helpers/index';

async function verifyPhoneNumber(phoneNumber) {
    let confirmation;
    if (Platform.OS === 'web') {
        confirmation = await auth().signInWithPhoneNumber('+' + phoneNumber, window.recaptchaVerifier).catch(error => {
            Logger(error)
            return false
        });
    } else {
        confirmation = await auth().verifyPhoneNumber(phoneNumber).catch(error => {
            Logger(error)
            return false
        })
    }
    return confirmation
}
const Info = ({ route, navigation }) => {
    let { user } = route?.params || {}

    const [userObject, setUser] = useState(user)
    const { locale, strings } = useSelector((state) => state.locale);
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [changeNumber, setChangeNumber] = useState(false);

    useEffect(() => {
        if (Platform.OS === 'web' && window.document.getElementById('recaptcha-container')) {
            window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                }
            });
            try {
                window.recaptchaVerifier.render()
            } catch (error) {
                Logger(error)
                setAlert(false)
                setLoading(false)
            }
        }
    }, [])

    const PhoneInputComponent = (Platform.OS === "web" ? <PhoneInput
        disabled={!changeNumber && auth()?.currentUser?.phoneNumber}

        containerStyle={{
            width: '100%',
            background: mainColor,
            borderRadius: 6,
            marginTop: 16,
            direction: 'ltr'
        }}
        inputStyle={{
            width: '100%',
            height: 50,
            background: mainColor,
            borderColor: '#000',
            color: '#000'
        }}
        dropdownStyle={{
            background: mainColor,
            borderColor: '#000',
            color: '#000',
            zIndex: 1000000,
        }}

        buttonStyle={{
            background: mainColor,
            borderColor: '#000',
        }}
        regions={['middle-east', 'north-africa']}
        country={'ae'}
        value={userObject.phoneNumber}
        onChange={text => {
            if (Number(text)) {
                if (!changeNumber) {
                    setUser({ ...JSON.parse(JSON.stringify(userObject)), phoneNumber: text?.replace(/^0+/, '') })
                } else {
                    setChangeNumber(text?.replace(/^0+/, ''))
                }
            }
        }}
    /> : <PhoneInput
        disabled={!changeNumber && auth()?.currentUser?.phoneNumber}
        defaultCode="AE"
        onChangeFormattedText={(text) => {
            if (Number(text)) {
                if (!changeNumber) {
                    setUser({ ...JSON.parse(JSON.stringify(userObject)), phoneNumber: text?.replace(/^0+/, '') })
                } else {
                    setChangeNumber(text?.replace(/^0+/, ''))
                }
            }
        }}

        textInputProps={{
            placeholderTextColor: '#cacaca60',
            placeholder: strings.phoneNumber,
            style: {
                height: Platform.OS === 'ios' ? "auto" : 50,
                color: '#000',
                fontSize: 16,
                textAlign: locale === 'en' ? 'left' : 'right',
            },
            selectionColor: '#000',
            width: '100%',
            color: '#000',
        }}
        containerStyle={{
            borderWidth: 1,
            borderRadius: 6,
            overflow: 'hidden',
            width: '100%',
            marginTop: 16,
        }}
        textContainerStyle={{
            borderLeftWidth: 1,
            borderStyle: 'solid',
            color: '#000',
            height: Platform.OS === 'ios' ? "auto" : 50,

        }}
        withDarkTheme={false}
        codeTextStyle={{ color: '#000' }}
        value={!changeNumber ? userObject.phoneNumber : changeNumber}
    />
    )
    return (
        <TouchableWithoutFeedback style={{ flex: 1, backgroundColor: backgroundColor }} accessible={false} onPress={() => Keyboard.dismiss}>
            <>

                <ScrollView showsHorizontalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }}
                    keyboardShouldPersistTaps='handled'
                >
                    <View nativeID='recaptcha-container' />
                    {
                        changeNumber && <ChangeNumberModal
                            title={strings.changeNumber}
                            confirmText={strings.confirm}
                            PhoneInputComponent={PhoneInputComponent}
                            onCancel={() => {
                                setChangeNumber(false)
                            }}
                            onConfirm={async () => {
                                setChangeNumber(false)
                                setLoading(true)
                                if (changeNumber) {
                                    let userExists = await functions().httpsCallable('checkExistingUser')({
                                        phoneNumber: changeNumber
                                    })
                                    if (userExists?.data?.existed) {
                                        setAlert({ title: strings.alert, msg: strings.existedNumber, text: 'dismiss' })
                                    } else {
                                        try {
                                            let confirmation = await verifyPhoneNumber(changeNumber)
                                            if (confirmation) {
                                                setUser({ ...JSON.parse(JSON.stringify(userObject)), phoneNumber: changeNumber })
                                                navigation.navigate('Verify', { confirmation, linkMobile: true, updateMobile: true, phoneNumber: changeNumber })
                                            } else {
                                                setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                            }
                                            setLoading(false)
                                        } catch (err) {
                                            Logger(err)
                                            setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                            setLoading(false)
                                        }
                                    }

                                } else {
                                    let err = new Error('no number entered')
                                    Logger(err)
                                    setAlert({ title: strings.alert, text: 'dismiss', msg: strings.enterValidNumber })
                                    setLoading(false)
                                }
                            }}
                        />
                    }
                    {
                        alert && <Alert
                            alert={alert?.text === 'dismiss'}
                            title={alert?.title}
                            confirmText={strings[alert?.text]}
                            msg={alert?.msg}
                            // cancelText={alert?.text}
                            onCancel={() => {
                                setAlert(false)
                                setLoading(false)
                            }
                            }
                            onConfirm={async () => {
                                if (alert.title === strings.alert) {
                                    setAlert(false)
                                    setLoading(false)
                                } else {
                                    setAlert(false)
                                    setLoading(true)
                                    if (userObject.phoneNumber) {
                                        let userExists = await functions().httpsCallable('checkExistingUser')({
                                            phoneNumber: userObject.phoneNumber
                                        })

                                        if (userExists?.data?.existed) {
                                            setAlert({ title: strings.alert, msg: strings.existedNumber, text: 'dismiss' })
                                        } else {
                                            try {
                                                let confirmation = await verifyPhoneNumber(userObject.phoneNumber)

                                                if (confirmation) {
                                                    navigation.navigate('Verify', { confirmation, linkMobile: true, updateMobile: auth()?.currentUser?.phoneNumber && auth()?.currentUser?.phoneNumber !== userObject?.phoneNumber, phoneNumber: userObject?.phoneNumber })
                                                } else {
                                                    setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                                }
                                                setLoading(false)

                                            } catch (err) {
                                                console.log("errerrerr", err);
                                                setAlert({ title: strings.alert, msg: strings.enterValidNumber, text: 'dismiss' })
                                                setLoading(false)
                                            }
                                        }

                                    } else {

                                        setAlert({ title: strings.alert, text: 'dismiss', msg: strings.enterValidNumber })
                                        setLoading(false)
                                    }
                                }
                            }
                            }
                        />
                    }
                    {loading && <View style={{
                        // backgroundColor: redColor,
                        padding: 16,
                        borderRadius: 10,
                        position: 'absolute',
                        zIndex: 10000000,
                        alignSelf: 'center',
                        top: '45%'
                    }}>
                        <ActivityIndicator size="large" color={redColor} />
                    </View>
                    }

                    <View style={{ display: 'flex', flexDirection: 'row', paddingVertical: 28, paddingHorizontal: 16 }}>
                        <Image
                            style={{ width: 65, height: 65 }}
                        />
                        <View style={{ paddingHorizontal: 8 }}>
                            <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold' }}>{user?.name}</Text>
                            <Text style={{ textAlign: 'left', paddingTop: 2 }}>{user.email}</Text>
                        </View>
                    </View>
                    <View style={{ paddingHorizontal: 16, flex: 1 }}>
                        <TextInput
                            selectionColor="#FF0000"
                            theme={{
                                // dark: true,
                                roundness: 8,
                                colors: {
                                    outline: 'none',
                                    text: colorTextDark, accent: colorTextDark, primary: colorTextDark, placeholder: '#5E5A65', background: mainColor
                                }
                            }}
                            keyboardType={'default'}
                            value={userObject?.name}
                            onChangeText={(text) => setUser({ ...JSON.parse(JSON.stringify(userObject)), name: text })}
                            style={{
                                color: colorTextDark, marginTop: 16, height: 50, borderRadius: 8, backgroundColor: mainColor,
                                background: mainColor,
                                borderColor: colorTextDark,
                                borderWidth: 1
                            }}
                            label={strings.name}
                            underlineColor='transparent'
                            underlineColorAndroid='transparent'
                        />

                        {!auth()?.currentUser?.phoneNumber ? PhoneInputComponent :
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                outline: 'none',
                                marginTop: 16,
                                height: 50,
                                borderRadius: 8,
                                overflow: 'hidden',
                                backgroundColor: mainColor,
                                background: mainColor,
                                paddingEnd: 16,
                                borderColor: colorTextDark,
                                borderWidth: 1,
                            }}>
                                <TextInput
                                    focusable={false}
                                    editable={false}
                                    theme={{
                                        dark: true,
                                        roundness: 8,
                                        colors: { outline: 'none', text: colorTextDark, accent: colorTextDark, primary: redColor, placeholder: '#5E5A65', background: mainColor }
                                    }}
                                    value={userObject?.phoneNumber}
                                    style={{
                                        color: '#fff', height: 50, backgroundColor: mainColor,
                                        background: mainColor,
                                        width: '70%'
                                    }}
                                    label={strings.phoneNumber}
                                    underlineColor='transparent'
                                    underlineColorAndroid='transparent'
                                />
                                <TouchableOpacity onPress={() => setChangeNumber(true)}>
                                    <Image uri={editSVG} svg={true} width={20} height={20} />
                                </TouchableOpacity>
                            </View>
                        }
                        <TextInput
                            selectionColor="#FF0000"
                            theme={{
                                // dark: true,
                                roundness: 8,
                                colors: { outline: 'none', text: colorTextDark, accent: colorTextDark, primary: colorTextDark, placeholder: '#5E5A65', background: mainColor }
                            }}
                            keyboardType={'default'}
                            value={userObject?.email}
                            onChangeText={(text) => setUser({ ...JSON.parse(JSON.stringify(userObject)), email: text })}
                            style={{
                                color: '#fff', marginTop: 16, height: 50, borderRadius: 8, backgroundColor: mainColor,
                                background: mainColor,
                                borderColor: colorTextDark,
                                borderWidth: 1
                            }}
                            label={strings.yourEmail}
                            underlineColor='transparent'
                            underlineColorAndroid='transparent'
                        />
                    </View>


                </ScrollView>
                <View style={{
                    flexDirection: 'row', justifyContent: 'center', padding: 16, display: 'flex'
                }}>
                    <ActionBtn title={strings.saveInfo}
                        disabled={!userObject.name || !userObject.phoneNumber}
                        onPress={async () => {
                            setLoading(true)
                            if (!auth()?.currentUser?.phoneNumber || (auth()?.currentUser?.phoneNumber !== userObject?.phoneNumber)) {
                                setAlert({ title: strings.verifyPhoneNumber, text: 'sendOTP', msg: strings.verifyNumberMsg })
                                setLoading(false)

                            } else {
                                await firestore().collection('users').doc(auth().currentUser.uid).update(userObject)
                                setLoading(false)
                                navigation.goBack()

                            }
                        }} />
                </View>
            </>
        </TouchableWithoutFeedback>
    );
};

export default Info;