import { createAction } from 'redux-action';
export const GET_ACTIVE_ORDERS = 'GET_ACTIVE_ORDERS';


export const GetActiveOrders = createAction(GET_ACTIVE_ORDERS, (list) => {
    return list
});


const initial = {};

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_ACTIVE_ORDERS:            
            return action.payload ? { ...action.payload } : false
        default:
            return state;
    }
};
export default ex