import React from 'react';
import { Dimensions, Platform, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import { redColor } from '../../constants/color';
import { Text } from '../shared/custom'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Pressable, Spinner, View } from 'native-base';

const ActionBtn = ({ title, price, onPress, disabled , count, fromCart, desktop, loading  }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { currency } = useSelector((state) => state.settings);
    const insets = useSafeAreaInsets();

    const Btn = () => {
        return (
            <Pressable
                isLoading={loading}
                isLoadingText=' '
                onPress={() => { disabled ? {} : onPress() }}
                width={'full'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                style={{
                    height: 50,
                    paddingHorizontal: 16,
                    // paddingVertical: 16,
                    backgroundColor: price && count ? '#28BE34' : redColor,
                    bottom: Platform.OS === 'web' || insets.bottom === 0 ? (fromCart ? 0 : 16) : undefined,
                    // width: '100%',
                    borderRadius: 10,
                    // display: 'flex',
                    // flexDirection: 'row',
                    // justifyContent: 'space-between'

                }}>
                {
                    loading ?
                        <View  width={'full'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                            <Spinner size={'lg'} color={'#fff'} />
                        </View>
                        :
                        <View
                            width={'full'}
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                            <Text style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: 16,
                                fontFamily: 'semiBold',
                                width: price ? 'auto' : '100%'
                            }}>{title}</Text>

                            {
                                price && count ?
                                    <Text style={{
                                        color: '#fff',
                                        textAlign: 'center', fontSize: 16, fontFamily: 'semiBold'
                                    }}>{((price * count).toFixed(2)) || '0'} {strings[currency]}</Text> : <></>
                            }
                        </View>
                }
            </Pressable>
        )

    }
    if (fromCart) {
        return (
            <View style={
                Platform.OS === 'android' || Platform.OS === 'web' ?
                    {
                        bottom: 10,
                        position: 'relative',
                        height: 50,
                        paddingHorizontal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    } :
                    {
                        bottom: 10,
                        position: fromCart ? 'relative' : 'absolute',
                        paddingHorizontal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    }}>
                <Btn />

            </View>
        )
    }
    return (
        <SafeAreaView style={
            Platform.OS === 'android' || Platform.OS === 'web' ?
                {
                    bottom: 20,
                    position: Platform.OS === 'android' ? 'absolute' : 'relative',
                    height: 50,
                    // paddingHorizontal: 16,
                    width: '100%',
                    // width: Dimensions.get('window').width > 900 && desktop ? (price ? "100%" : '30%') : '100%',
                    opacity: disabled ? 0.5 : 1
                } :
                {
                    bottom: 0,
                    position: 'absolute',
                    paddingHorizontal: 16,
                    width: '100%',
                    opacity: disabled ? 0.5 : 1
                }}>
            <Btn />
        </SafeAreaView>

    );
};

export default ActionBtn;