import React, { useEffect, useState } from 'react';
import { Image, TouchableOpacity, View, SafeAreaView, ScrollView, FlatList, Platform, Dimensions, Modal } from 'react-native';
import { backgroundColor, colorTextDark, greenColor, mainColor, redColor } from '../../constants/color';
import { CloseBtn, FilledDot, Trash, CheckIcon, Dot } from '../../constants/imgs';
import ActionBtn from '../shared/ActionBtn';
import CustomImage from '../shared/customImg'
import ProductCard from '../shared/productCard';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Text } from '../shared/custom'
import { connect, useDispatch, useSelector } from 'react-redux';
import { SetOrderLocation, UpdateOrderItems } from '../../reducers/order';
import { checkDiscount, totalPriceForItem, EnableAdd, verifyLocation, Localize } from '../../helpers/helpers';
import Alert from '../shared/Alert';
import { LogEvent } from '../../helpers/index';

const Modifier = ({ title, detail, required, type, data, update, selected, style }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { currency } = useSelector((state) => state.settings);
    return (
        <View style={style}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 16 }}>
                <View style={{}}>
                    <Text style={{ fontSize: 16, fontFamily: 'semiBold', textAlign: 'left' }}>
                        {title}
                    </Text>
                    {
                        detail ? <Text style={{ fontSize: 12, paddingTop: 2, textAlign: 'left' }}>
                            {detail}
                        </Text>
                            :
                            <View />
                    }

                </View>
                {
                    required ? <Text style={{ color: redColor, fontSize: 12, fontFamily: 'semiBold' }}>
                        {strings.required}
                    </Text>
                        :
                        <View />
                }
            </View>
            <View>
                {
                    type === 'count' ?
                        <FlatList
                            horizontal
                            getItemLayout={(data, index) => (
                                { length: 50, offset: 50 * index, index }
                            )}
                            contentContainerStyle={{ paddingHorizontal: 16 }}
                            showsHorizontalScrollIndicator={false}
                            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            renderItem={(r) => <TouchableOpacity
                                onPress={() => update(r.item)}
                                key={r} style={{
                                    borderRadius: 8,
                                    paddingHorizontal: 16,
                                    paddingVertical: 10,
                                    borderColor: data === r.item ? redColor : colorTextDark,
                                    borderWidth: 1,
                                    marginRight: 6,
                                    marginTop: 14,
                                    backgroundColor: data === r.item ? redColor : mainColor,

                                }}>
                                <Text style={{ color: data === r.item ? '#fff' : colorTextDark }}>{r.item}</Text>
                            </TouchableOpacity>}
                        />
                        : type === 'single' ?
                            data?.items?.map((rec, index) => {
                                let allSelectedCount = selected && Object.values(selected || {})?.filter(r => r.parent === data.key).length
                                return (<TouchableOpacity
                                    onPress={() => {
                                        if (allSelectedCount < (data?.max_count || data?.items?.length)) {
                                            if (selected[rec.key + data.key]) {
                                                delete selected[rec.key + data.key]
                                            } else {
                                                selected[rec.key + data.key] = { ...rec, parent: data.key }
                                            }
                                        } else if (Number(allSelectedCount) === Number((data?.max_count || data?.items?.length)) && (data?.max_count || data?.items?.length) < 2) {
                                            let filtered = Object.values(selected || {}).find(r => r.parent === data.key)
                                            delete selected[filtered.key + filtered.parent]
                                            if ((filtered.key + filtered.parent) !== (rec.key + data.key)) {
                                                selected[rec.key + data.key] = { ...rec, parent: data.key }
                                            }
                                        } else {
                                            delete selected[rec.key + data.key]
                                        }
                                        update(selected)
                                    }}
                                    key={rec.key}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingVertical: 16,
                                        justifyContent: 'space-between',
                                        paddingHorizontal: 16
                                    }}>
                                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                        {
                                            selected[rec.key + data.key] ? <CustomImage svg fill={redColor} width={20} height={20} uri={FilledDot} /> :
                                                <CustomImage svg fill={redColor} width={20} height={20} uri={Dot} />

                                        }
                                        <Text style={{ paddingHorizontal: 8, textAlign: 'left' }}>{Localize(rec, 'name')}</Text>
                                    </View>
                                    <Text style={{ fontFamily: 'semiBold', fontSize: 14 }}>{rec?.price} {strings[currency]}</Text>
                                </TouchableOpacity>)
                            })
                            : type === 'multiple' ?
                                data?.items?.map((rec, index) => {
                                    let allSelectedCount = selected && Object.values(selected || {})?.filter(r => r.parent === data.key)?.reduce((a, b) => a = a + b.count, 0)
                                    let count = selected && selected[rec?.key + data?.key] && selected[rec?.key + data?.key].count
                                    return (<TouchableOpacity
                                        onPress={() => {
                                            if (allSelectedCount < data.max_count) {
                                                if (selected[rec.key + data.key]) {
                                                    selected[rec.key + data.key].count += 1
                                                } else {
                                                    selected[rec.key + data.key] = { ...rec, parent: data.key, count: 1 }
                                                }
                                                update(selected)
                                            }
                                        }}
                                        key={rec.key}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingVertical: 16,
                                            justifyContent: 'space-between',
                                            paddingHorizontal: 16
                                        }}>
                                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                            <Text style={{ textAlign: 'left' }}>
                                                {
                                                    count && <Text style={{ paddingHorizontal: 8, color: redColor, fontSize: 18, fontFamily: 'semiBold' }}> {`${count}X`}</Text>
                                                }
                                                {Localize(rec, 'name')}</Text>
                                        </View>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Text style={{ fontFamily: 'semiBold', fontSize: 14 }}>{rec?.price} {strings[currency]}</Text>
                                            {
                                                count && <TouchableOpacity
                                                    style={{ paddingLeft: 8 }}
                                                    onPress={() => {
                                                        delete selected[rec.key + data.key]
                                                        update(selected)
                                                    }}>
                                                    <CustomImage
                                                        svg
                                                        width={20}
                                                        height={20}
                                                        uri={Trash}
                                                        fill={redColor}
                                                    />
                                                </TouchableOpacity>
                                            }

                                        </View>
                                    </TouchableOpacity>)
                                })
                                : <FlatList
                                    contentContainerStyle={{ paddingTop: 16, paddingHorizontal: 16 }}
                                    horizontal={true}
                                    data={data}
                                    getItemLayout={(data, index) => (
                                        { length: 50, offset: 50 * index, index }
                                    )}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={(i) =>
                                        <View key={i.item.id} style={{ width: 180 }} >
                                            <ProductCard key={i.item} index={i.item.id} data={i.item} />
                                        </View>
                                    }
                                    keyExtractor={item => item.id}
                                />
                }
            </View>

        </View>
    )
}
const ProducPage = ({ route, navigation, UpdateOrderItems, order, helpers, offer, user }) => {
    let { params } = route
    let { data } = params
    const [count, setCount] = useState(1)
    const [loading, setLoading] = useState(true)

    const [modifiers, setModifiers] = useState({})
    const [relatedProducts, setRelatedProducts] = useState([])
    const { locale, strings } = useSelector((state) => state.locale);
    const { currency } = useSelector((state) => state.settings);

    const [alert, setAlert] = useState(false)
    const [continueShopping, setContinueShopping] = useState(false)
    const dispatch = useDispatch()
    let discounted = checkDiscount(offer, data)
    useEffect(() => {
        dispatch(LogEvent({ event: 'view_item', data: { item: data } }))
        let added = data?.key && order?.items && order.items[data?.key]
        if (added) {
            setCount(added.count || 1)
            setModifiers(added.selectedModifiers || {})
        }
        let relatedProducts = data?.relatedProducts?.map(prdKey => helpers?.prds?.find(r => r.key === prdKey))
        if (relatedProducts?.length) {
            return setRelatedProducts(relatedProducts)
        }
        setRelatedProducts([])
    }, [data?.key])
    let image = data?.imgs?.length ? data?.imgs[0] : ''
    let thumb = data?.thumb?.length ? `https://cdn.taybagourmet.com/prds/${data.id}/400` : '';
    if (data?.thumb?.[0]?.includes('https://cdn.taybagourmet.com')) {
        thumb = data?.thumb?.[0]
    };

    let details = Localize(data, 'detail');
    let title = Localize(data, 'name') + " " + (details?.toLowerCase() || '')

    let categories = Object.keys(data?.prds_category || {}).map(rec => {
        return helpers?.categories?.find(cat => cat.key === rec)
    })

    return (
        <>
            <TouchableOpacity onPress={() => navigation.goBack()} style={{ position: 'absolute', right: 0, paddingTop: 46, paddingRight: 16, zIndex: 99 }}>
                <CustomImage width={30} height={30} svg uri={CloseBtn} />
            </TouchableOpacity>
            {/* {
                alert && <Alert title={strings.alert}
                    msg={strings.loginMsg}
                    onConfirm={() => { setAlert(false); navigation.navigate('Login') }}
                    confirmText={strings.login}
                    onCancel={() => setAlert(false)} />
            } */}
            {
                alert && (user?.uid ?
                    <Alert
                        title={strings.alert}
                        confirmText={strings[alert?.actionTitle] || strings.completeProfile}
                        msg={alert?.msg}
                        onCancel={() => setAlert(false)}
                        onConfirm={() => {
                            setAlert(false)
                            navigation.goBack()
                            switch (alert.action) {
                                case 'toggleLocations':
                                    navigation.navigate('UserLocation', { user: user, select: true })
                                    break
                                case 'navigate':
                                    navigation.navigate('Choose your location')
                                    break
                                default:
                                    navigation.navigate('Choose your location')
                                    break

                            }
                        }} /> :
                    <Alert title={strings.alert}
                        msg={strings.loginMsg}
                        onConfirm={() => {
                            setAlert(false);
                            navigation.goBack();
                            navigation.navigate('Login');
                        }}
                        confirmText={strings.login}
                        onCancel={() => setAlert(false)} />)
            }
            <ScrollView showsHorizontalScrollIndicator={false} contentContainerStyle={{ backgroundColor: backgroundColor }}>
                <Image
                    style={{
                        width: '100%',
                        height: hp('30'),
                    }}
                    height={hp('30')}
                    onLoadEnd={() => setLoading(false)}
                    resizeMode="cover"
                    source={{ uri: image, cache: 'force-cache' }}
                />
                {
                    loading && <Image
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: hp('30'),
                        }}
                        height={hp('30')}
                        resizeMode="cover"
                        source={{ uri: thumb, cache: 'force-cache' }}
                    />


                }

                <View style={{ paddingHorizontal: 16, paddingTop: 16, backgroundColor: mainColor }}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <View style={{ width: '80%', position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                            {
                                categories?.map(item => {
                                    return (
                                        item ? <View key={item.id}>
                                            <Text style={{ color: '#7C7C7C', fontSize: 12, paddingHorizontal: 6, fontFamily: 'semiBold' }}><View style={{ backgroundColor: redColor, width: 8, height: 8, borderRadius: 50 }} />{Localize(item, 'name')}</Text>
                                        </View> : <></>
                                    )
                                })
                            }
                        </View>
                        <View>
                            {discounted && <View style={{ backgroundColor: greenColor + '20', paddingHorizontal: 6, paddingVertical: 4, borderRadius: 8 }}>
                                <Text style={{ color: greenColor, fontSize: 12 }}>{`${discounted.percent}% ${strings.off}`}</Text>
                            </View>}
                        </View>


                    </View>
                    {data?.price !== '0' ? <View style={{ paddingTop: 14, paddingBottom: 24 }}>
                        <Text style={{ lineHeight: 26, fontSize: 20, fontFamily: 'semiBold', textAlign: 'left' }}>{title}</Text>
                        {discounted && <Text style={{ fontFamily: 'light', paddingTop: 4, textDecorationLine: 'line-through' }}>{strings[currency]} {data?.price}</Text>
                        }
                        <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 8, alignItems: 'baseline', justifyContent: 'flex-start' }}>
                            <Text style={{ color: redColor, fontSize: 16, fontFamily: 'semiBold', textTransform: 'uppercase', lineHeight: 28 }}>{strings[currency]}</Text>
                            <Text style={{ color: redColor, fontSize: 24, fontFamily: 'semiBold', paddingHorizontal: 6, lineHeight: 32 }}>{discounted ? (data?.price - discounted?.price).toFixed(2) : data?.price}</Text>
                            <Text style={{ fontSize: 10, lineHeight: 28 }} >{strings.allPricesIncludeVAT}</Text>
                        </View>
                    </View> :
                        <View style={{ paddingTop: 14, paddingBottom: 24 }}>
                            <Text style={{ fontSize: 20, fontFamily: 'semiBold', textAlign: 'left' }}>{title}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 8, alignItems: 'baseline', justifyContent: 'flex-start' }}>
                                <Text style={{ fontSize: 14, fontFamily: 'semiBold', color: redColor }}>{strings.priceOnSelection}</Text>
                            </View>
                        </View>
                    }
                </View>
                <View style={{ backgroundColor: mainColor, marginTop: 5 }}>

                    <Modifier
                        type='count'
                        required
                        title={strings.quantity}
                        detail={data?.unit?.value ? `${data?.unit?.value} ${data?.unit?.label}` : ''}
                        data={count}
                        style={{ paddingTop: 24, paddingBottom: 24 }}
                        update={(data) => setCount(data)} />
                    {
                        data?.modifiers?.map(m => {
                            return (<Modifier
                                style={{ paddingVertical: 12 }}
                                required={m.min_count && Number(m.min_count) !== 0}
                                detail={
                                    m.min_count && m.max_count && Number(m.max_count) === Number(m.min_count) ?
                                        `${strings.choose} ${m.max_count}`
                                        : m.min_count && (!m.max_count || Number(m.min_count) > Number(m.max_count)) ?
                                            `${strings.chooseAtLeaset} ${m.min_count}`
                                            : m.max_count && (!m.min_count || Number(m.max_count) > Number(m.min_count)) ?
                                                `${strings.chooseUpto} ${m.max_count}`
                                                : ''
                                }
                                type={m.type || 'single'}
                                title={locale === 'en' ? m.name : m.name_ar}
                                data={m}
                                selected={JSON.parse(JSON.stringify(modifiers))}
                                update={(data) => setModifiers(data)} />)
                        }

                        )
                    }
                </View>

                <View style={{
                    backgroundColor: backgroundColor,
                    marginTop: 5,
                    paddingBottom: 100,
                    minHeight: '50%',
                    alignItems: 'flex-start',

                }}>
                    {
                        relatedProducts?.length ? <View style={{
                            flex: 1,
                            flexDirection: 'column',
                            width: '100%'
                        }}>
                            <Text style={{ fontSize: 18, fontFamily: 'semiBold', paddingTop: 22, paddingHorizontal: 16 }}>
                                {strings.ingredients}
                            </Text>
                            <FlatList
                                contentContainerStyle={{ paddingHorizontal: 10 }}
                                horizontal={true}
                                getItemLayout={(data, index) => (
                                    { length: 50, offset: 50 * index, index }
                                )}
                                data={relatedProducts}
                                showsHorizontalScrollIndicator={false}
                                renderItem={(i) =>
                                    <View key={i.item} style={{ width: 180 }} >
                                        <ProductCard key={i.item} index={i?.item?.id} data={i?.item} />
                                    </View>
                                }
                                keyExtractor={item => item?.id}
                            />
                        </View>
                            :
                            <View style={{
                                height: 250,
                            }} />
                    }

                </View>
            </ScrollView>
            <View style={{ paddingHorizontal: 16, justifyContent: 'center', flexDirection: 'row' }}>
                <ActionBtn desktop={true} title={strings.addToCart}
                    disabled={!EnableAdd({
                        ...data,
                        count,
                        selectedModifiers: modifiers
                    })}
                    count={count}
                    price={totalPriceForItem({
                        ...data,
                        count,
                        selectedModifiers: modifiers
                    }) || 0}
                    onPress={() => {
                        if (user?.locations?.length) {
                            let post = {
                                ...data,
                                count,
                                selectedModifiers: modifiers
                            }
                            let optimizedItems = JSON.parse(JSON.stringify(order.items))
                            optimizedItems[data.key] = post
                            UpdateOrderItems(optimizedItems);
                            dispatch(LogEvent({ event: 'add_to_cart', data: { items: optimizedItems } }))
                            // navigation.goBack()
                            setContinueShopping(true)
                        } else {
                            let res = dispatch(verifyLocation(user, order));
                            console.log("resresresresresres", res);
                            switch (res.type) {
                                case 'alert':
                                    setAlert(res)
                                    break;
                                default:
                                    break;
                            }
                        }

                    }} />
            </View>


            <Modal
                animationType='slide'
                presentationStyle='overFullScreen'
                transparent={true}
                visible={continueShopping}
            >
                <SafeAreaView style={{ backgroundColor: '#fff' }} />
                <View style={{
                    width: Dimensions.get('window').width > 900 ? '30%' : '100%',
                    backgroundColor: '#00000070',
                    height: '100%',
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: mainColor,
                        paddingHorizontal: 16,
                        paddingTop: Platform.OS === 'web' ? 22 : 0,
                        paddingBottom: 12

                    }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            maxWidth: '70%'
                        }}>
                            <CustomImage
                                svg
                                width={20}
                                height={20}
                                uri={CheckIcon}
                                fill={redColor}
                            />
                            <View style={{ marginStart: 5 }}>
                                <Text style={{
                                    textAlign: 'left',
                                    fontFamily: 'semiBold',
                                    fontSize: 12,
                                    marginBottom: 2
                                }}>{Localize(data, 'name')} x{count}</Text>
                                <Text style={{
                                    textAlign: 'left',
                                    fontSize: 10
                                }}>{data?.unit?.value ? (`${data?.unit?.value} ${data?.unit?.label}`) : ''}</Text>
                            </View>
                        </View>
                        <View style={{}}>
                            <Text style={{
                                textAlign: 'right',
                                fontSize: 10,
                                marginBottom: 2
                            }}>{strings.addedToCart}</Text>
                            <Text style={{
                                textAlign: 'right',
                                fontFamily: 'semiBold',
                                fontSize: 12
                            }}>{strings[currency]} {((totalPriceForItem({
                                ...data,
                                count,
                                selectedModifiers: modifiers
                            }) * count).toFixed(2)) || `0 ${currency}`}</Text>
                        </View>
                    </View>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: mainColor,
                        paddingHorizontal: 16,
                        paddingBottom: 10
                    }}>
                        <TouchableOpacity
                            onPress={() => {
                                setContinueShopping(false)
                                navigation.goBack()
                            }}
                            style={{
                                flex: 1,
                                paddingVertical: 8,
                                backgroundColor: '#fff',
                                borderWidth: 1,
                                borderColor: redColor,
                                borderRadius: 6,
                                marginEnd: 8
                            }}>
                            <Text style={{
                                textAlign: 'center',
                                color: redColor,
                                fontFamily: 'semiBold',
                                fontSize: 12
                            }}>{strings.continueShopping}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                let res = dispatch(verifyLocation(user, order));
                                console.log("resresresresresres", res);
                                switch (res.type) {
                                    case 'location':
                                        setContinueShopping(false)
                                        dispatch(SetOrderLocation(res.location))
                                        navigation.replace('Cart')
                                        break;
                                    case 'pass':
                                        setContinueShopping(false)
                                        navigation.replace('Cart')
                                        break;
                                    case 'alert':
                                        setContinueShopping(false)
                                        setAlert(res)
                                        break;
                                    default:
                                        break;


                                }
                                // navigation.replace('Cart')
                            }}
                            style={{
                                flex: 1.5,
                                paddingVertical: 8,
                                backgroundColor: redColor,
                                borderWidth: 1,
                                borderColor: redColor,
                                borderRadius: 6,
                            }}>
                            <Text style={{
                                textAlign: 'center',
                                color: '#fff',
                                fontFamily: 'semiBold',
                                fontSize: 12
                            }}>{strings.viewCart}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>

        </>

    );
};
const mapStateToProps = ({ order, helpers, offer: { activeOffer }, user }) => {
    return { order, helpers, offer: activeOffer, user }
}

export default connect(mapStateToProps, { UpdateOrderItems })(ProducPage);