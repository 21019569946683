import React from 'react';
import { View, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { colorTextDark, mainColor } from '../../constants/color'
import { location } from '../../constants/imgs'
import { Text } from '../shared/custom'
import CustomImage from './customImg'
const CardSM = ({ data, title }) => {
    const { locale } = useSelector((state) => state.locale);
    let image = data?.img?.[0] ? `https://cdn.taybagourmet.com/${title ? 'prds_category' : 'brands'}/${data.id}/logo` : 'https://firebasestorage.googleapis.com/v0/b/tayba-2b919.appspot.com/o/Vi.png?alt=media&token=2a582040-3746-4821-997a-996d7cd23a45';
    if (data?.img?.[0]?.includes('https://cdn.taybagourmet.com')) {
        image = data?.img?.[0]
    };    
    return (
        <View
            style={{
                // backgroundColor: mainColor,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                borderRadius: 16,
                paddingTop: title ? 8 : 0
            }}>
            {
                 <View style={{
                    shadowColor: '#000000',
                    shadowOffset: {
                        width: 0,
                        height: 3
                    },
                    elevation: 4,
                    shadowRadius: 4,
                    borderRadius: 16,
                    shadowOpacity: 0.2,
                    height: title ? 'auto' : 70,
                    width: '100%',

                }}>
                    <Image
                        resizeMode={'cover'}
                        // width={'100%'}
                        style={{
                            aspectRatio: title && 1,
                            height: title ? 'auto' : 70,
                            width: '100%',
                            borderRadius: title ? 8 : 16,
                        }}
                        // height={title ? 'auto' : 70}
                        source={{ uri: image, cache: 'force-cache' }} /></View>
                    // :
                    // <View />

            }
            {
                title && <Text style={{ color: colorTextDark, textAlign: 'center', fontSize: 11, paddingVertical: 8, fontFamily: 'semiBold' }}>{(locale === 'en' ? data?.name : data?.name_ar) || ""}</Text>
            }
        </View>
    );
};

export default CardSM;