import React, { useState, useEffect } from 'react';
import { View, FlatList, Image, SafeAreaView, TextInput, TouchableOpacity } from 'react-native'
import { backgroundColor, secColor, mainColor, redColor, colorLightGrey, colorTextDark } from '../../constants/color';
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ArrowRight, BackSVG, Basket, SearchSVG } from '../../constants/imgs';
import CustomImage from '../shared/customImg'
import { Text } from '../shared/custom'
import { connect, useSelector } from 'react-redux';
import Alert from '../shared/Alert';
import BasketBtn from '../shared/BasketBtn';

const Record = ({ data, navigation }) => {
    const { locale } = useSelector((state) => state.locale);
    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('Product', { id: data.id, data })}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
                marginBottom: 1,
                paddingVertical: 14,
                backgroundColor: mainColor
            }}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <Image
                    width={33}
                    height={33}
                    style={{
                        width: 33,
                        height: 33,
                        borderRadius: 8
                    }}
                    resizeMode="cover"
                    source={{ uri: data?.thumb?.length && data.thumb[0], cache: 'force-cache' }}
                />
                <Text style={{ fontSize: 12, fontFamily: 'semiBold', paddingHorizontal: 12 }}>{locale === 'en' ? data?.name : data?.name_ar}</Text>
            </View>

            <CustomImage
                width={15}
                height={15}
                uri={locale === 'en' ? ArrowRight : BackSVG}
                svg
            />

        </TouchableOpacity>
    )

}
const Search = ({ prd, navigation, order, user }) => {
    const [search, setSearch] = useState(false)
    const [list, setList] = useState([])
    const { locale, strings } = useSelector((state) => state.locale);
    const [alert, setAlert] = useState(false)
    const insets = useSafeAreaInsets();

    useEffect(() => {
        let list;
        if (search) {
            list = prd.filter(r => r.name.toLowerCase().includes(search) || r.name_ar.toLowerCase().includes(search))
        } else {
            list = prd.slice(0, 5)
        }
        setList(list)
    }, [search])

    return (
        <View style={{ flex: 1, backgroundColor: mainColor, paddingTop: insets.top > 4 ? insets.top - 4 : insets.top }}>
            {/* <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 8, backgroundColor: mainColor }}>
                <TextInput
                    onChangeText={(text) => setSearch(text.toLowerCase())}
                    style={{
                        outline: 'none',
                        paddingHorizontal: 8,
                        height: 40,
                        width: '90%',
                        borderRadius: 8,
                        color: '#fff',
                        backgroundColor: secColor
                    }} />
            </View> */}
            {
                alert && (user?.uid && Object.keys(order?.items || {})?.length === 0 ? <Alert
                    title={strings.alert}
                    alert
                    cancelText={'dismiss'}
                    msg={strings.addItemsMsg}
                    onCancel={() => setAlert(false)} onConfirm={() => {
                        setAlert(false)
                    }} /> : user?.uid ? <Alert
                        title={strings.alert}
                        confirmText={strings.completeProfile}
                        msg={strings.completeProfileMsg}
                        onCancel={() => setAlert(false)} onConfirm={() => {
                            setAlert(false)
                            navigation.navigate('Choose your location')
                        }} /> : <Alert title={strings.alert}
                            msg={strings.loginMsg}
                            onConfirm={() => { setAlert(false); navigation.navigate('Login') }}
                            confirmText={strings.login}
                            onCancel={() => setAlert(false)} />)
            }
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: mainColor,
                justifyContent: 'space-between',
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: mainColor,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingVertical: 16,
                    paddingHorizontal: 16
                }}>
                    <View style={{ width: '8%', minWidth: 25 }} />

                    <Text style={{
                        width: '80%',
                        textAlign: 'center',
                        fontSize: 18,
                        fontFamily: 'semiBold',
                        paddingHorizontal: 16,
                    }}>{strings.search}
                    </Text>

                    <BasketBtn setAlert={() => setAlert(true)} />
                </View>
                <View style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', outline: 'none',
                    paddingHorizontal: 8,
                    marginHorizontal: 16,
                    height: 40,
                    // width: '100%',
                    borderRadius: 8,
                    color: '#fff',
                    backgroundColor: colorLightGrey,
                    marginBottom: 10
                }}>
                    <CustomImage uri={SearchSVG} svg={true} fill={colorTextDark} width={20} height={20} />
                    <TextInput
                        placeholder={strings.search}
                        placeholderTextColor={colorTextDark}
                        onChangeText={(text) => setSearch(text.toLowerCase())}
                        selectionColor="#FF0000"
                        style={{
                            outline: 'none',
                            paddingHorizontal: 8,
                            height: 40,
                            paddingVertical: 8,

                            width: '90%',
                            borderRadius: 8,
                            color: colorTextDark,
                        }} />
                </View>
            </View>
            {list.length ? <FlatList
                data={list}
                style={{ flex: 1, backgroundColor: backgroundColor }}
                renderItem={(i) => <Record key={i.item} index={i.item.id} data={i.item} navigation={navigation} />}
                keyExtractor={item => item.id}
            /> : <View
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '85%',
                    backgroundColor: backgroundColor
                }}>
                <Text style={{ fontSize: 18, color: '#ffffff' }}>{strings.noResults}</Text>
            </View>}
        </View>

    );
};
const mapStateToProps = ({ helpers, order, user }) => {
    return { prd: helpers?.prds || [], order, user }
}

export default connect(mapStateToProps, {})(Search);