import React from 'react';
import { Text as TextCopmnet } from 'react-native'
import { colorTextDark } from '../../constants/color';
import { Bold, Light, Medium, Regular, SemiBold } from '../../constants/GlobalStyles';
import { useSelector } from 'react-redux';
export const Text = ({ children, numberOfLines, style, cap = true }) => {
    let fontFamily = 'regular';
    if (Array.isArray(style)) {
        style = style.reduce((acc, item) => {
            if (item.fontFamily) {
                fontFamily = item.fontFamily;
            }
            return { ...acc, ...item };
        }, {});
    } else {
        if (style?.fontFamily) {
            fontFamily = style.fontFamily;
        }
    }
    const { locale } = useSelector((state) => state.locale);
    const capitalize = (s) => {
        if (typeof s !== 'string') return children
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }
    return (
        <TextCopmnet
            numberOfLines={numberOfLines}
            style={[{
                color: colorTextDark,
                lineHeight: locale === 'ae' ? ((style?.fontSize || 14) + 2) : undefined,
            }, style, {
                fontFamily: (fontFamily === 'bold' ?
                    Bold(locale)
                    : fontFamily === 'semiBold' ?
                        SemiBold(locale)
                        : fontFamily === 'medium' ?
                            Medium(locale)
                            : fontFamily === 'light' ?
                                Light(locale)
                                : Regular(locale)),
            }]}
        // style={{...style , color: colorTextDark}}

        >
            {
                cap ? capitalize(children) : children
            }

            {/* {typeof props.children === 'string' ? props.children.charAt(0)?.toUpperCase() + props.children.slice(1).toLowerCase() : props.children} */}
        </TextCopmnet>
    );
};
