import React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ImageBackground
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { localeSetLanguage } from "../reducers/locale";
import { useNavigation } from '@react-navigation/core';
import TaybaLogo from '../assets/logoTayba.png'
import { colorTextDark } from '../constants/color';
const ArabicEnglishScreen = ({ }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const user = useSelector(state => state.user)

  return (
    <ImageBackground
      source={require('../assets/taybaBackground.png')}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#151F28'
      }}>
      <View style={{
        position: 'relative',
        alignItems: 'center',
      }}>
        <Image style={{ height: 250, width: 350, resizeMode: 'contain' }} source={TaybaLogo} />
        {/* <Text style={{ fontSize: 22, fontFamily: 'Tajawal-Bold', color: '#fff' }}>كانك في مصر</Text> */}

        <View style={{ marginTop: 24 }}>
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderColor: colorTextDark,
              borderStyle: 'solid',
              borderRadius: 5,
              alignItems: 'center',
              width: 200,
              paddingVertical: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={() => {
              dispatch(localeSetLanguage('en'));
              setTimeout(() => {
                navigation.navigate(user?.uid ? 'HomeScreen' : 'Login')
              }, 500);
            }}>
            <Text style={{ fontSize: 18, lineHeight: 20, fontFamily: 'Poppins-SemiBold', color: colorTextDark }}>English</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderColor: colorTextDark,
              borderStyle: 'solid',
              borderRadius: 5,
              alignItems: 'center',
              width: 200,
              paddingVertical: 10,
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onPress={() => {
              dispatch(localeSetLanguage('ae'));
              // setTimeout(() => {
              //   navigation.navigate(user?.uid ? 'HomeScreen' : 'Login')
              // }, 500);
            }}>
            <Text style={{ fontSize: 18, lineHeight: 20, fontFamily: 'Tajawal-Bold', color: colorTextDark }}>عربي</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};



export default ArabicEnglishScreen;
