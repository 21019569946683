import { SvgXml } from 'react-native-svg'
import { Platform } from "react-native";
import React from 'react';
import { redColor } from '../../../constants/color';
import { Image } from 'native-base';

const Index = ({ uri, width, png, height, svg, style, resizeMode, fill = redColor, step = 1, rotate = 0 }) => {
    if (Platform.OS === 'web' || !svg) {

        return (

            <Image
                alt='image'
                accessibilityLabel={'image'}
                source={svg ? { uri: uri({ fill, step }) } : png ? uri : { uri: uri }}
                resizeMode={resizeMode || "contain"}
                style={{ ...style, width: width, height: height }}
            />)
    }
    return (
        <SvgXml
            alt='image'
            accessibilityLabel={'image'}
            width={width}
            height={height}
            style={{
                ...style,
                transform: [{ rotate: `${rotate}deg` }]
            }}
            xml={uri({ fill, step })} />
    );
};

export default Index;