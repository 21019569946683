import { useStripe } from '@stripe/react-stripe-js';
import { analytics, auth } from '../constants/firebase/config.web'
import { totalPriceForItem } from './helpers';

export async function onGoogleButtonPress() {
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
        'display': 'popup'
    });
    return auth().signInWithPopup(provider)
}
export async function onFacebookButtonPress() {
    const provider = new auth.FacebookAuthProvider();
    provider.setCustomParameters({
        'display': 'popup'
    });
    return auth().signInWithPopup(provider)

}
export async function onAppleButtonPress() {
}
export function logout() {
    auth().signOut()

}


export function Logger(error) {

}

export const requestLocationPermission = async () => {
    return true
}
export const requestTrackingPermission = async () => {
    return true
}
export const GetCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                resolve(position)
            },
            error => {
                reject(error)
            },
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
    })
}

export const Stripe = () => {
    return useStripe();
};

export const ConfirmOnlinePayment = ({ selectedCard, paymentIntent, stripe }) => async (dispatch, getState) => {
    const { currency = 'AED', country = 'AE' } = getState().settings;
    console.log("currency", currency, country, paymentIntent);
    const results = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        { payment_method: selectedCard },
        { handleActions: true }
    );
    return results
}

export const isPlatformPaySupported = async ({ receipt, stripe }) => {
    const paymentRequest = stripe.paymentRequest({
        country: "AE",
        currency: "aed",
        total: {
            label: "Tayba",
            amount: Number((receipt?.total * 100).toFixed(2)),
        },
    })
    try {
        const canMakePayment = await paymentRequest.canMakePayment();
        console.log("canMakePayment", canMakePayment);
        return canMakePayment
    } catch (error) {
        console.log("error", error);
        return false
    }
}

export const LogEvent = ({ event, data }) => (dispatch, getState) => {
    const { settings: { currency = 'AED', country = 'AE', allLocations = {} }, order: { location } } = getState() || {};
    let branch = allLocations?.[location?.locationId]?.branch || false;
    switch (event) {
        case 'add_to_cart':
            console.log('eventeventeventeventevent', event);
            //params = items
            try {
                let items = Object.values(data?.items || {}).map((item) => {                    
                    return {
                        item_id: item.key,
                        item_name: item.name,
                        quantity: Number(item.count),
                        price: Number(totalPriceForItem(item))
                    }
                });
                let post = {
                    items,
                    country,
                    branch,
                    currency,
                    value: Object.values(data?.items || {})?.reduce((acc, rec) => acc + (totalPriceForItem(rec) * 1), 0)
                }
                analytics().logEvent('add_to_cart', post);
                // analytics().logAddToCart(post);
            } catch (error) {
                console.log("errorerrorerrorerrorerrorerror analytics", error);
            }
            break;
        case 'begin_checkout':
            break;
        case 'purchase':
            //params = receipt, order
            try {
                let post = {
                    value: Number(data?.receipt?.total),
                    currency: currency,
                    items: Object.values(data?.order?.items || {}).map(rec => {
                        return {
                            item_id: rec?.key,
                            item_name: rec?.name,
                            quantity: Number(rec?.count),
                            price: Number(totalPriceForItem(rec))
                        }
                    }),
                    branch,
                    country,
                    coupon: data?.receipt?.code || undefined,
                    shipping: Number(data?.receipt?.deliveryFees || 0),
                }
                analytics().logEvent('purchase', post);
                // analytics().logPurchase(post);
            } catch (error) {
                console.log("errorerrorerrorerrorerrorerror analytics", error);
            }
            break;
        case 'view_item':
            let post = {
                id: data?.item?.id,
                name: data?.item?.name,
                value: Number(totalPriceForItem(data?.item) * 1),
                currency: currency,
                branch,
                country
            }
            try {
                analytics().logEvent('view_item', post);
            } catch (error) {
                console.log("errorerrorerrorerrorerrorerror analytics", error);
            }
            break;
        default:
            break;
    }

}