import { Checkbox } from 'native-base';
import React, { useState } from 'react';
import { View, Text, ScrollView, Button, } from 'native-base';
import { StyleSheet, SafeAreaView } from 'react-native';
import { redColor } from '../../constants/color';
import { useDispatch } from 'react-redux';
import { SetAlertMessage } from '../../reducers/settings';
import { auth } from '../../constants/firebase/config';
import { useNavigation } from '@react-navigation/native';

const DeleteAccountScreen = () => {
    const [state, setState] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const handleDeleteAccount = () => {
        dispatch(SetAlertMessage({
            title: 'Account Deletion',
            msg: 'Are you sure you want to delete your account?',
            confirmText: 'Delete',
            onConfirm: () => {

                auth().currentUser.delete().then(() => {
                    dispatch(SetAlertMessage(false));
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'HomeScreen' }]
                    })
                    console.log("Account deleted successfully");
                }).catch((error) => {
                    console.log("Error deleting account", error);
                })
            },
        }))
    };

    return (
        <SafeAreaView style={{ flex: 1 }} >
            <View style={styles.container}>


                <ScrollView style={styles.scrollView}>
                    <Text style={styles.termsHeader}>Please read the terms and conditions carefully:</Text>
                    <Text style={styles.termsText}>
                        By deleting your account, you hereby affirm your understanding and acceptance of the following:
                    </Text>
                    <Text style={styles.termsText}>1. You will no longer receive any transactional or promotional communication from Tayba Gourmet and its associated services.</Text>
                    <Text style={styles.termsText}>2. You will not be able to register or sign in with the same email address used for the account to be deleted. You will lose access to all information related to your account, including but not limited to orders, returns, rewards, and account balance.</Text>
                    <Text style={styles.termsText}>3. You must first cancel any active subscriptions and auto-deliveries to avoid future charges and delivery issues.</Text>
                    <Text style={styles.termsText}>4. We will retain necessary data to complete any pending orders, returns, or claims up to their completion.</Text>
                    <Text style={styles.termsText}>5. You will lose access to any pending Tayba Gourmet credits or rewards. For recovery or use of such credits, contact customer support.</Text>
                    <Text style={styles.termsText}>By proceeding, you confirm that you have read and understood the terms and conditions of deleting your account.</Text>
                    <View style={{
                        marginVertical: 10
                    }}>
                        <Checkbox isChecked={state} onChange={() => setState(!state)} >
                            <Text>I have read and understood the terms and conditions.</Text>
                        </Checkbox>
                    </View>


                </ScrollView>
                <Button disabled={!state} onPress={handleDeleteAccount} style={{ backgroundColor: state ? redColor : '#cacaca', height: 50 }} >
                    <Text color={state ? '#fff' : redColor}>DELETE YOUR ACCOUNT</Text>
                </Button>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    scrollView: {
        marginBottom: 20,
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    subHeader: {
        fontSize: 18,
        marginBottom: 10,
    },
    termsHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    termsText: {
        fontSize: 16,
        marginBottom: 10,
    },
});

export default DeleteAccountScreen;
