import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native'
import { mainColor, redColor } from '../../constants/color';
import { Location, Trash, editSVG2 } from '../../constants/imgs';
import Image from '../shared/customImg'
import { Text } from '../shared/custom'
import { useNavigation } from '@react-navigation/core';
import { useSelector } from 'react-redux';
import Alert from '../shared/Alert';
import { firestore } from '../../constants/firebase/config'

const LocationCard = ({ disabled, data, user, edit, select, profile, userLocations }) => {
    const navigation = useNavigation()
    const { locale, strings } = useSelector((state) => state.locale);
    const [alert, setAlert] = useState(false)

    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 16,
            backgroundColor: mainColor,
            paddingVertical: 16,
            borderRadius: 8,
            borderColor: '#2B2730'
        }}>
            {
                alert && <Alert
                    title={strings.alert}
                    // alert
                    cancelText={'cancel'}
                    confirmText={strings.confirm}
                    msg={strings.deleteLocation}
                    onCancel={() => setAlert(false)}
                    onConfirm={() => {
                        let newlocations = userLocations.filter(rec => rec.id !== data.id)
                        firestore().collection('users').doc(user?.uid).update({
                            locations: newlocations
                        })
                        setAlert(false)
                    }}
                />
            }
            <View style={{ width: '60%' }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                        svg
                        width={12}
                        height={12}
                        uri={Location}
                        fill={redColor}
                    />
                    <Text style={{ paddingHorizontal: 4, fontFamily: 'semiBold' }}>{data?.type === "pickup" ? data.address : data?.label}</Text>
                </View>
                {data?.type !== "pickup" && <Text style={{ textAlign: 'left', fontSize: 12, paddingTop: 8, letterSpacing: 0}}>
                    {data?.formatted_address}
                </Text>}
            </View>
            {/* {
                !disabled && data?.type !== "pickup" && <TouchableOpacity onPress={() => edit ? navigation.navigate('Address Details', { user: user, data: data }) : navigation.navigate('UserLocation', { user: user, select })}>
                    <Text style={{ color: redColor, fontFamily: locale === 'en' ? 'Poppins-Regular' : 'Tajawal-Regular' }}>{strings.change}</Text>

                </TouchableOpacity>
            } */}


            {
                !disabled && data?.type !== "pickup" &&
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {profile && <TouchableOpacity style={{ marginEnd: 8 }} onPress={() => {
                        setAlert(true)
                    }}>
                        <Image
                            svg
                            width={20}
                            height={20}
                            uri={Trash}
                            fill={redColor}

                        />
                    </TouchableOpacity>}
                    <TouchableOpacity
                        onPress={() => edit ? navigation.navigate('Address Details', { user: user, data: data })
                            : navigation.navigate('UserLocation', { user: user, select })}>
                        <Image
                            svg
                            width={20}
                            height={20}
                            uri={editSVG2}
                            fill={redColor}

                        />
                    </TouchableOpacity>
                </View>
            }

        </View>
    );
};

export default LocationCard;