import { createAction } from 'redux-action';
import { CalculatePoints } from '../helpers/helpers';
export const GET_USER = 'GET_USER';
export const UPDATE_FAV_LIST = 'UPDATE_FAV_LIST';
export const FLUSH_USER = 'FLUSH_USER';
export const SET_WALLET_LOGS = 'SET_WALLET_LOGS';
export const SET_USER_VOUCHERS = 'SET_USER_VOUCHERS'

export const GetUser = createAction(GET_USER, (list) => {
    return list
});

export const FlushUser = createAction(FLUSH_USER, (list) => {
    return list
});

const initial = {};

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_USER:
            return action.payload ? { fav: state?.fav, vouchers: state?.vouchers, ...action.payload, locations: action.payload.locations } : false
        case UPDATE_FAV_LIST:
            let id = action.payload.id;
            let favourit = state.favourit || [];
            if (favourit.includes(id)) {
                favourit = favourit.filter((item) => item !== id)
            } else {
                favourit.push(id)
            }
            return { ...state, favourit }

        case SET_WALLET_LOGS:
            let total = CalculatePoints(action.payload)
            console.log("totaltotal", total);

            return { ...state, walletLogs: action.payload, totalPoints: total }
        case SET_USER_VOUCHERS:
            let list = action?.payload?.map(item => {
                switch (item?.type) {
                    case 'freeItem':
                        item.bgColor = '#E78028'
                        break;
                    case 'discount':
                        item.bgColor = '#723A2D'
                        break;
                    case 'freeDelivery':
                        item.bgColor = '#0298A9'
                        break;
                    default:
                        break;
                }
                return item

            })
            return { ...state, vouchers: list || [] }
        case FLUSH_USER:
            return {}
        default:
            return state;
    }
};
export default ex