import { View } from 'native-base';
import React, { useState } from 'react';
import { TouchableOpacity, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from './custom';
import CustomImage from '../shared/customImg'
import { Basket } from '../../constants/imgs';
import { useNavigation } from '@react-navigation/native';
import { colorTextDark, redColor } from '../../constants/color';
import { verifyLocation } from '../../helpers/helpers';
import { SetOrderLocation } from '../../reducers/order';
const BasketBtn = ({ setAlert }) => {
    const { user, order } = useSelector(state => state)
    const navigation = useNavigation();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    if (loading) {
        return (
            <View width={25} height={25}>
                <ActivityIndicator color={redColor} />
            </View>

        )
    }
    return (
        <TouchableOpacity onPress={async () => {
            if (order && order.items && Object.keys(order?.items || {}).length !== 0) {
                setLoading(true)
            } else {
                return
            }
            setTimeout(() => {
                let res = dispatch(verifyLocation(user, order));                
                setLoading(false)
                switch (res.type) {
                    case 'location':
                        // console.log('res.locationres.location', JSON.stringify(res.location));
                        dispatch(SetOrderLocation(res.location))
                        return navigation.navigate('Cart');
                    case 'pass':
                        return navigation.navigate('Cart');
                    case 'alert':
                        return setAlert(res);
                    default:
                        break;
                }
            }, 300);
        }}>
            {
                order && order.items && Object.keys(order?.items || {}).length !== 0 && <View style={{
                    position: 'absolute',
                    top: -2,
                    left: -4,
                    backgroundColor: redColor,
                    borderRadius: 20,
                    zIndex: 10000,
                    width: 15,
                    height: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }} >
                    <Text style={{ fontSize: 10, color: '#fff' }}>{Object.values(order?.items || {})?.reduce((value, currentValue) => {
                        value.count = (value.count ? value.count : 0) + currentValue.count
                        return value
                    }, {}).count}</Text>
                </View>
            }

            <CustomImage uri={Basket} svg={true} width={25} fill={colorTextDark} height={25} />
        </TouchableOpacity>
    );
};

export default BasketBtn;