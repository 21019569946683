import React from 'react';
import { ScrollView, View } from 'react-native';
import LoyaltyCard from '../../shared/LoyaltyCard';
import { useSelector } from 'react-redux';

const VoucherList = ({ route }) => {
    const { myVouchers } = route?.params || {}
    const { vouchers = [] } = useSelector(state => myVouchers ? state.user : state.helpers);

    return (
        <ScrollView style={{ flex: 1 }}>
            {
                vouchers?.map((item, index) => {
                    return <View key={index} style={{ marginVertical: 8,overflow:'hidden' }}>
                        <LoyaltyCard data={item} color={item?.valueColor} redeemed={myVouchers} />
                    </View>
                })
            }


        </ScrollView>
    );
};

export default VoucherList;