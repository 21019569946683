import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View, TouchableOpacity, Image, Linking } from 'react-native';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { backgroundColor, colorTextGrey, greenColor, redColor } from '../../constants/color';
import CartCard from '../shared/CartCard';
import LocationCard from '../shared/LocationCard';
import CustomImage from '../shared/customImg'
import { FilledDot, step1, step2, step3, step4, step1_ar, step2_ar, step3_ar, Watch, CallSVG } from '../../constants/imgs';
import { Text } from '../shared/custom'
import Alert from '../shared/Alert';
import { firestore } from '../../constants/firebase/config'
import { connect, useSelector } from 'react-redux';
import { translateSelectedSlot } from '../../helpers/helpers';
import { REACT_APP_ORDERS_COLLECTION } from '../../constants/variables/index';
import taybaLogo from '../../assets/logoTayba.png';


const StatusPage = ({ route, orders }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { allLocations, currency } = useSelector((state) => state.settings);
    const [orderItems, setItems] = useState({})
    const [alert, setAlert] = useState(false)
    const [data, setData] = useState(route?.params?.data || {})

    const steps = [step1, step2, step3, step4]
    const steps_ar = [step1_ar, step2_ar, step3_ar, step4]

    const [deliveryTime, setDeliveryTime] = useState(false)

    const paymentTypes = {
        'cash': { 'delivery': 'Cash On Delivery', 'pickup': 'Cash On Pickup' },
        'card': { 'delivery': 'Card On Delivery (POS Machine)', 'pickup': 'Card On Pickup (POS Machine)' },
        'wallet': { 'delivery': 'Apple Pay', 'pickup': 'Apple Pay' },
        'online': { 'delivery': 'Card Payment', 'pickup': 'Card Payment' },

    }
    useEffect(() => {
        let order = Object.values(orders || {})?.find(r => r.id === data.id)
        let { items } = order || data
        let sameDay = items && Object.values(items || {})?.filter(r => r.sameDay)
        let scdualed = items && Object.values(items || {})?.filter(r => !r.sameDay)
        setItems({ sameDay, scdualed })
        setData(order ? order : data)
    }, [orders])
    let { receipt } = data || {}
    let step = { new: 1, accepted: 2, active: 3, completed: 4, canceled: 4, rejected: 4 }
    let colors = { new: '#DE8329', accepted: '#32C5FF', active: '#6DD400', completed: '#6DD400', canceled: '#DE2934', rejected: '#DE2934' }

    useEffect(() => {
        (async () => {
            if (data?.address && allLocations) {
                if (data?.address?.locationId) {
                    setDeliveryTime(allLocations[data?.address?.locationId].deliveryTime)
                } else {
                    setDeliveryTime(false)
                }
            }
        })()

    }, [data?.address, allLocations])


    useEffect(() => {
        if (data?.driver) {
            console.log("datadatadatadata", data?.driver);

            firestore().collection('drivers').doc(data?.driver).get().then((doc) => {
                if (doc.exists) {
                    setData({ ...data, driverDetails: doc.data() })
                    console.log(doc.data())
                }
            })
        }
    }, [data?.driver])
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: backgroundColor }}>
            {
                alert && <Alert title={strings.warning} msg={strings.areYouSure} confirmText={strings.cancelOrder} onCancel={() => setAlert(false)} onConfirm={() => {
                    firestore().collection(REACT_APP_ORDERS_COLLECTION).doc(data.id).update({
                        status: 'canceled',
                        canceled: Date.now()
                    }).then(() => {
                        setAlert(false)
                    })
                }} />
            }

            <ScrollView showsHorizontalScrollIndicator={false} >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 16, paddingTop: 27, }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 19 }}>{strings.yourOrder}</Text>
                        <Text style={{ color: colors?.[data?.status], marginHorizontal: 4, fontSize: 19, fontFamily: 'semiBold' }}> {strings?.[data?.status]}</Text>
                    </View>
                    <View>
                        <Text cap={false} style={{ color: colors?.[data?.status] }}>{strings.order} {data?.orderNumber}</Text>
                    </View>

                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 16, opacity: data?.status == 'canceled' || data?.status == 'rejected' ? 0.1 : 1 }}>
                    <CustomImage
                        width={'90%'}
                        height={30}
                        svg
                        // fill={redColor}
                        // step={}
                        uri={locale === 'en' ? steps?.[step?.[data?.status] - 1] : steps_ar?.[step?.[data?.status] - 1]}
                    />
                </View>

                <View style={{ paddingTop: 32, paddingHorizontal: 16, width: '100%' }}>
                    <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold', paddingBottom: 16 }}>{data?.type === 'pickup' ? strings.pickupFrom : strings.deliveringTo}</Text>
                    <LocationCard disabled data={data?.address} />
                </View>
                {
                    data?.driverDetails ?
                        <View style={{ paddingTop: 24, paddingHorizontal: 16, width: '100%' }}>
                            <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold', paddingBottom: 16 }}>{data?.type === 'pickup' ? strings.pickupFrom : 'Assigned Delivery'}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 8, padding: 8, alignItems: 'center' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={{
                                        borderRadius: 50,
                                        borderWidth: 1,
                                        borderColor: '#F5F5F5',
                                        width: 50,
                                        height: 50,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                    }}>
                                        <Image
                                            style={{

                                                width: 40,
                                                height: 40,
                                            }}
                                            source={taybaLogo}
                                        />
                                    </View>


                                    <View style={{ paddingHorizontal: 8 }}>
                                        <Text
                                            style={{
                                                fontSize: 12,
                                                fontWeight: "600",
                                                fontFamily: "bold",
                                                textAlign: "left",
                                                paddingBottom: 2
                                            }}>{data?.driverDetails?.name}</Text>
                                        <Text
                                            style={{
                                                fontSize: 12,
                                                color: '#707070',
                                                textAlign: "left"
                                            }}>
                                            {data?.driverDetails?.number}
                                        </Text>
                                    </View>

                                </View>
                                <TouchableOpacity onPress={() => {
                                    let optimizedPhone = data?.driverDetails?.number.replace(/ /g, '')
                                    Linking.openURL(`tel:${optimizedPhone}`)
                                }}>
                                    <CustomImage
                                        svg
                                        uri={CallSVG}
                                        width={35}
                                        height={35}
                                    />

                                </TouchableOpacity>
                            </View>
                        </View>
                        : null
                }

                {
                    orderItems?.sameDay?.length ? <View style={{ paddingHorizontal: 16, paddingTop: 24 }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold' }}>{strings.fastDelivery}</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                            <CustomImage
                                fill={redColor}
                                svg
                                uri={Watch}
                                width={10}
                                height={10}
                            />
                            <Text style={{ textAlign: 'left', color: '#DE8329', paddingHorizontal: 4, fontSize: 12 }}>{strings.estDelivery}</Text>
                            <Text style={{ color: '#DE8329', fontSize: 12, fontFamily: 'semiBold' }}>{(deliveryTime?.value || '') + " " + (deliveryTime?.label || '')}</Text>
                        </View>
                        {
                            orderItems?.sameDay?.map((rec, index) =>
                                <View key={rec.key} style={{ paddingBottom: index === orderItems?.sameDay?.length - 1 ? 0 : 16 }}>
                                    <CartCard disabled data={rec} />
                                </View>

                            )
                        }
                    </View>
                        :
                        <View />
                }
                {
                    orderItems?.scdualed?.length ?
                        <View style={{ paddingHorizontal: 16, paddingTop: 24 }}>
                            <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold' }}>{strings?.["Scheduled Delivery"]}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                                <CustomImage
                                    fill={redColor}
                                    svg
                                    uri={Watch}
                                    width={10}
                                    height={10}
                                />
                                <Text style={{ textAlign: 'left', color: '#FF8900', paddingHorizontal: 4, fontSize: 12}}>{strings.estDelivery}</Text>
                                <Text style={{ color: '#FF8900', fontSize: 12, fontFamily: 'semiBold' }}>{translateSelectedSlot(data?.date) || '24 hours'}</Text>
                            </View>
                            {
                                orderItems?.scdualed.map(rec =>
                                    <View key={rec.key} style={{ paddingBottom: 16 }}>
                                        <CartCard disabled data={rec} />
                                    </View>

                                )
                            }
                        </View>
                        :
                        <View />

                }

                <View style={{ paddingHorizontal: 16, paddingVertical: 16 }}>
                    <Text style={{ textAlign: 'left', fontSize: 18, paddingBottom: 8, fontFamily: 'semiBold' }}>{strings.paymentMethod}</Text>
                    <View style={{ flexDirection: 'row', paddingBottom: 22 }}>
                        <CustomImage
                            svg
                            width={15}
                            height={15}
                            uri={FilledDot}
                            fill={redColor}
                        />
                        <Text

                            style={{
                                fontFamily: 'semiBold',
                                fontSize: 12,
                                paddingHorizontal: 6
                            }}>
                            {strings?.[paymentTypes?.[data?.paymentType]?.[data?.type]] || data?.paymentType}
                        </Text>
                    </View>

                </View>
                <View style={{ paddingHorizontal: 16 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{strings.subTotal}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.sub}</Text>
                    </View>
                    {/* <View style={{ flexDirection: 'row', paddingVertical: 6, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{strings.tax}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.tax}</Text>
                    </View> */}
                    {
                        receipt.deductedValue &&
                        <View style={{ flexDirection: 'row', paddingVertical: 6, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{strings.discount} "{receipt.code}"</Text>
                            <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.deductedValue}</Text>
                        </View>
                    }

                    {receipt?.deliveryFees && receipt.sub !== "0.00" ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12 }}>{strings.Delivery}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {Number(receipt?.deliveryFees).toFixed(2)}</Text>
                    </View> :
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12 }}>{strings.Delivery}</Text>
                            <Text style={{ fontSize: 13, fontFamily: 'semiBold', color: greenColor }}>{strings.free}</Text>
                        </View>}
                    <View style={{
                        flexDirection: 'row',
                        borderTopColor: '#F5F5F5',
                        borderTopWidth: 1,
                        borderBottomColor: '#F5F5F5',
                        borderBottomWidth: 1,
                        paddingVertical: 6,
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    }}>
                        <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{strings.total} <Text style={{ fontSize: 10, color: colorTextGrey, fontFamily: 'light' }}>{strings.allPricesIncludeVAT}</Text></Text>
                        <Text style={{ fontSize: 19, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.total}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 60 }}>
                    {
                        ['new'].includes(data?.status) ?
                            <TouchableOpacity
                                onPress={() => {
                                    setAlert(true)
                                }}>
                                <Text style={{ color: redColor, fontSize: 16, fontFamily: 'semiBold' }}>{strings.cancelOrder}</Text>
                            </TouchableOpacity>
                            :
                            <View />
                    }
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};
const mapStateToProps = ({ orders }) => {
    return { orders: orders || [] }
}

export default connect(mapStateToProps, {})(StatusPage);