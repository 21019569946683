import React, { useEffect, useState } from 'react';
import { Dimensions, Modal, TouchableOpacity, View, ScrollView } from 'react-native';
// import { ScrollView } from 'react-native-gesture-handler';
import { colorTextDark, mainColor, redColor } from '../../constants/color';
import { CheckIcon, Location } from '../../constants/imgs';
import Image from '../shared/customImg'
import { Text } from '../shared/custom'
import { connect, useDispatch, useSelector } from 'react-redux';
import { SetOrderLocation } from '../../reducers/order';
import { useNavigation } from '@react-navigation/core';
import moment from 'moment';
import { CheckLocationIsServed, getResturantLocations } from '../../helpers/helpers';
import { Spinner } from 'native-base';

const OrderOption = ({ dismiss, user, SetOrderLocation, seletedLocation }) => {
    const [selectedOption, setOption] = useState('Delivery')
    const [pickupLocations, setPickupLocation] = useState([])
    const [closed, setClosed] = useState(false);
    const { allLocations, country } = useSelector(state => state.settings)
    const { locale, strings } = useSelector((state) => state.locale);
    const navigation = useNavigation()
    const [userLocations, setUserLocations] = useState(user?.locations);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            let { PickupLocations } = await getResturantLocations({ country })
            setPickupLocation(PickupLocations)
            if (PickupLocations && Object.values(PickupLocations || {})?.length) {
                let today = moment().format('dddd')
                let timings = Object.values(PickupLocations || {})?.[0]?.timings
                let currentDay = timings?.[today]

                if (currentDay.isOpen) {
                    let open = currentDay.timings.find(async rec => {
                        let startTime = moment(rec.startTime)
                            .set('year', moment().year())
                            .set('month', moment().month())
                            .set('date', moment().date()).seconds(0).milliseconds(0);

                        let endTime = moment(rec.endTime)
                            .set('year', moment().year())
                            .set('month', moment().month())
                            .set('date', moment(rec.endTime).hours() === 0 ? moment().date() + 1 : moment().date()).seconds(0).milliseconds(0);

                        return moment().isBetween(startTime, endTime)
                    })

                    if (open) {
                        setClosed(false)
                    } else {
                        setClosed(true)
                    }
                } else {
                    setClosed(true)

                }
            }
        })()

    }, [])

    useEffect(() => {
        if (user?.locations) {
            (async () => {
                setLoading(true)
                let userLocations = user?.locations?.map(rec => {
                    let { place_id, cluster } = dispatch(CheckLocationIsServed(rec));
                    return ({
                        ...rec,
                        active: !!place_id,
                        // active: true,
                        params: (allLocations ? allLocations[cluster] : {}),
                        branch: allLocations?.[cluster]?.branch
                    })
                })
                setLoading(false);
                setUserLocations(userLocations);
            })()
        }
    }, [user?.locations, allLocations, country])

    return (
        <Modal
            key={"locations"}
            animationType='slide'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={true}>
            {
                loading ?
                    <View
                        position={'absolute'}
                        backgroundColor='#00000050'
                        zIndex={999999999}
                        height={'100%'}
                        width='100%'
                        justifyContent='center'
                        alignItems={'center'}
                        flex={1}>
                        <Spinner size={'lg'} />
                    </View>
                    :
                    <View />
            }

            <TouchableOpacity onPressIn={() => dismiss()} style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0, 0, 0, 0.5)', justifyContent: 'center', alignItems: 'flex-end', width: Dimensions.get('window').width > 900 ? '30%' : '100%', height: '100%' }}>
                <View style={{ backgroundColor: mainColor, height: '70%', width: '100%', borderRadius: 16 }}>
                    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 16, paddingHorizontal: 16, paddingBottom: 24 }}>
                        {
                            ["Delivery", "Pickup"].map(rec => <View
                                key={rec}
                                style={{
                                    width: '48%',
                                    backgroundColor: selectedOption === rec ? redColor : 'transparent',
                                    borderRadius: 6
                                }}>
                                <TouchableOpacity
                                    style={{ paddingVertical: 8 }}
                                    onPress={() => setOption(rec)} >
                                    <Text style={{ color: selectedOption === rec ? "#fff" : colorTextDark, fontFamily: 'semiBold', textAlign: 'center' }}>{strings[rec]}</Text>
                                </TouchableOpacity>
                            </View>)
                        }
                    </View>
                    <ScrollView showsHorizontalScrollIndicator={false} >
                        {selectedOption === 'Delivery' ?
                            userLocations?.map(rec => <TouchableOpacity key={rec.id + rec.active}
                                onPress={() => {
                                    if (rec.active) {
                                        rec.type = "delivery"
                                        SetOrderLocation(rec)
                                        dismiss()
                                    }
                                }}
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingHorizontal: 16,
                                    paddingVertical: 12,
                                    opacity: rec?.active ? 1 : 0.5
                                }}>
                                <View style={{ width: '60%' }}>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Image
                                            svg
                                            width={15}
                                            height={15}
                                            uri={Location}
                                            fill={redColor}
                                        />
                                        <Text style={{ paddingHorizontal: 4, fontFamily: 'semiBold' }}>{rec?.label}</Text>
                                    </View>
                                    <Text style={{ fontSize: 12, paddingTop: 8, textAlign: 'left' }}>
                                        {rec?.formatted_address}
                                    </Text>
                                </View>
                                {
                                    seletedLocation?.id === rec.id && <View>
                                        <Image
                                            svg
                                            width={15}
                                            height={15}
                                            uri={CheckIcon}
                                            fill={redColor}
                                        />
                                    </View>
                                }



                            </TouchableOpacity>) : (closed ?
                                <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text style={{ textAlign: 'center', width: '60%', lineHeight: 20, paddingVertical: 150, fontFamily: 'semiBold' }}>{strings.closedMsg}</Text>
                                </View>
                                : Object.values(pickupLocations || {})?.map((rec, index) => <TouchableOpacity key={index}
                                    onPress={() => {
                                        rec.type = "pickup"                                        
                                        SetOrderLocation({ ...rec, id: rec.key })
                                        dismiss()
                                    }
                                    }
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingHorizontal: 16,
                                        paddingVertical: 12
                                    }}>
                                    <View style={{ width: '60%' }}>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Image
                                                svg
                                                width={15}
                                                height={15}
                                                uri={Location}
                                                fill={redColor}
                                            />
                                            <Text style={{ paddingHorizontal: 4, fontFamily: 'semiBold' }}>{rec?.area}</Text>

                                            {/* <Text style={{ textAlign: 'left', paddingHorizontal: 4, fontFamily: 'semiBold' }}>{rec?.address}</Text> */}
                                        </View>
                                        <Text style={{ fontSize: 12, paddingTop: 8, textAlign: 'left' }}>
                                            {rec?.address}
                                        </Text>

                                    </View>
                                    {
                                        seletedLocation?.id === rec.id && <View>
                                            <Image
                                                svg
                                                width={15}
                                                height={15}
                                                uri={CheckIcon}
                                                fill={redColor}
                                            />
                                        </View>
                                    }



                                </TouchableOpacity>)
                            )

                        }
                        {selectedOption === 'Delivery' && <TouchableOpacity onPressIn={() => {

                            navigation.navigate('Choose your location')

                            dismiss()
                            navigation.navigate('Choose your location')
                        }} style={{ position: 'relative', padding: 16 }}>
                            <Text style={{ color: redColor, fontFamily: 'semiBold', fontSize: 14 }}>{strings.addNewAddress}</Text>
                        </TouchableOpacity>}
                    </ScrollView>
                </View>
            </TouchableOpacity>
        </Modal>
    );
};
const mapStateToProps = ({ order }) => {
    return { seletedLocation: order?.location }
}

export default connect(mapStateToProps, { SetOrderLocation })(OrderOption);