import React from 'react';
import { redColor } from '../../constants/color';
import { CoinSVG, OfferBGSVG } from '../../constants/imgs';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import Image from "./customImg";
import { Text } from './custom';
import { Spinner } from 'native-base';
import { useSelector } from 'react-redux';
import { Localize } from '../../helpers/helpers';
const Voucher = ({ item, loading, onPress }) => {
    const { width } = Dimensions.get('screen');
    const { strings } = useSelector(state => state.locale)
    return (
        <View style={{
            padding: 8,
            borderRadius: 8,
            backgroundColor: `${redColor}20`,
            width: width / 1.2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Image
                    svg
                    uri={OfferBGSVG}
                    width={40}
                    height={40}

                />
                <View style={{
                    paddingHorizontal: 8
                }}>
                    <Text style={{ fontSize: 16, fontFamily: 'medium' }}>{Localize(item, 'description')}</Text>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        <Image
                            svg
                            uri={CoinSVG}
                            width={16}
                            height={16}
                        />
                        <Text style={{ fontSize: 12, fontFamily: 'medium', paddingHorizontal: 4 }}>{item?.numberOfPoints}</Text>
                    </View>
                </View>
            </View>
            <View>
                <TouchableOpacity
                    onPress={onPress}
                    style={{
                        backgroundColor: `${redColor}`,
                        paddingVertical: 4,
                        borderRadius: 70,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingHorizontal: 16,
                        minWidth: 60

                    }}>
                    {
                        loading ?
                            <Spinner color='#fff' size={12} style={{
                                paddingHorizontal: 16,
                                paddingVertical: 4,

                            }} />
                            :
                            <Text style={{
                                fontSize: 12,
                                color: '#fff',
                                fontFamily: 'medium'
                            }}>
                                {strings?.["apply"]}
                            </Text>
                    }
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default Voucher;