import React, { useEffect, useRef, useState, createRef } from 'react';
import { View, FlatList, TouchableOpacity, ScrollView, SectionList, TextInput, ActivityIndicator, Image, Platform, SafeAreaView } from 'react-native';
import { backgroundColor, colorLightGrey, colorTextDark, mainColor, redColor, secColor } from '../../constants/color';
import { GridSVG, ListSVG, BackSVG, ArrowRight, SearchSVG, Basket } from '../../constants/imgs';
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import CustomImage from '../shared/customImg'
import ProductCard from '../shared/productCard';
import ProductCardList from '../shared/productCardList'
import { Text } from '../shared/custom'
import { connect, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/core';
import Alert from '../shared/Alert';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import BasketBtn from '../shared/BasketBtn';

const Record = ({ data, navigation }) => {
    const { locale } = useSelector((state) => state.locale);
    const imageUrl = data?.img?.length ? data.img[0] : null;

    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('Results', { category: data.id, filterType: 'category' })}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
                marginBottom: 1,
                paddingVertical: 14,
                backgroundColor: mainColor,
                width: '100%'
            }}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                {imageUrl ? <Image
                    width={33}
                    height={33}
                    style={{
                        width: 33,
                        height: 33,
                        borderRadius: 8
                    }}
                    resizeMode="cover"
                    source={{ uri: imageUrl, cache: 'force-cache' }}
                    onError={(error) => console.log('Image loading error:', error.nativeEvent.error)}

                /> : <View
                    style={{
                        width: 33,
                        height: 33,
                        borderRadius: 8,
                        backgroundColor: 'grey', // Placeholder background
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
                }
                <Text style={{ fontSize: 12, fontFamily: 'semiBold', paddingHorizontal: 12 }}>{locale === 'en' ? data?.name : data?.name_ar}</Text>
            </View>

            <CustomImage
                width={15}
                height={15}
                uri={locale === 'en' ? ArrowRight : BackSVG}
                svg
            />

        </TouchableOpacity>
    )

}
const TagView = ({ data, updateView, filter, updateFilter, viewType }) => {
    const { locale } = useSelector((state) => state.locale);

    return (
        <TouchableOpacity
            style={{
                backgroundColor: filter === data.id ? redColor : mainColor,
                paddingHorizontal: 16,
                paddingVertical: 8,
                marginRight: 8,
                borderRadius: 6,
                borderWidth: 1,
                borderColor: filter === data.id || data.head ? 'transparent' : colorTextDark,
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onPress={() => data.head ? updateView(viewType === 'list' ? 'grid' : 'list') : updateFilter(data.id)}
        >
            {
                data.head ?
                    <CustomImage
                        uri={viewType === 'list' ? GridSVG : ListSVG}
                        width={20}
                        fill={colorTextDark}
                        height={20}
                        svg
                    />
                    :
                    <Text style={{ textTransform: 'capitalize', color: filter === data.id ? '#fff' : colorTextDark }} >
                        {locale === 'en' ? data?.name : data?.name_ar || data?.name}
                    </Text>
            }


        </TouchableOpacity>
    )
}
const ResultsView = ({ helpers, route, order, user }) => {
    let categoryID = route?.params?.category
    let { filterType, brand } = route?.params || {}
    const scrollRef = useRef(null)
    const elRefs = useRef([]);
    const insets = useSafeAreaInsets();
    const [loading, setLoading] = useState(true)
    const [viewType, setView] = useState('grid')
    const [tags, setTags] = useState([{ id: viewType, head: viewType }])

    const [filter, setFilter] = useState('')
    const [faild, setFaild] = useState(false)
    const [search, setSearch] = useState(false)
    const navigation = useNavigation()

    const [list, setList] = useState([])
    const { locale, strings } = useSelector((state) => state.locale);
    const [alert, setAlert] = useState(false)

    if (elRefs.current.length !== (list.length + 1)) {
        elRefs.current = Array(list.length + 1).fill().map((_, i) => elRefs.current[i] || createRef());
    }

    useEffect(() => {
        let tags = [{ id: viewType, head: viewType }]
        let selectedList;
        switch (filterType) {
            case 'brand':
                selectedList = helpers?.prds?.filter(r => r.brand === brand.id)
                setTags(false)
                if (search) {
                    selectedList = selectedList?.filter(r => r.name.toLowerCase().includes(search))
                }
                setList([{ ...brand, data: selectedList?.reduce((r, e, i) => (i % 2 ? r[r.length - 1].push(e) : r.push([e])) && r, []) }])
                break;
            case 'prdsCategory':
                selectedList = helpers?.categories?.sort((a, b) => a.index - b.index)
                setTags(false)
                if (search) {
                    selectedList = selectedList?.filter(r => r.name.toLowerCase().includes(search))
                }
                setList([{ data: selectedList?.reduce((r, e, i) => (i % 2 ? r[r.length - 1].push(e) : r.push([e])) && r, []) }])
                break;
            case 'category':
                selectedList = helpers?.categories?.find(r => r.id === categoryID)
                if (selectedList) {
                    let { subcategories } = selectedList
                    let optimizedsubcategories = subcategories?.map((rec, index) => {
                        let { items, name, name_ar, id } = rec;
                        let optimizedList = items?.map(itm => {
                            let itemObject = helpers?.prds?.find(r => r.id === itm.key)
                            if (itemObject) {
                                switch (search) {
                                    case false: case '':
                                        return itemObject
                                    default:
                                        let includes = itemObject.name.toLowerCase().includes(search) || itemObject.name_ar.toLowerCase().includes(search)
                                        switch (includes) {
                                            case true:
                                                return itemObject
                                            default:
                                                return false
                                        }
                                }
                            }
                        })?.filter(r => !!r)
                        if (optimizedList.length) {
                            tags.push({ name, name_ar, id })
                        }

                        return {
                            ...rec, index: index, data: optimizedList?.reduce((r, e, i) => (i % 2 ? r[r.length - 1].push(e) : r.push([e])) && r, [])
                        }
                    })?.filter(r => r?.data?.length);
                    setTags(tags || []);
                    setList(optimizedsubcategories || []);
                }
                break;
            default: break;
        }
        setLoading(false)

    }, [categoryID, search, helpers?.prds])
    useEffect(() => {
        if (faild) {
            let py = (faild.errorIndex * 290) + (filter.index * 45)
            scrollRef.current._wrapperListRef._listRef.scrollToOffset({ offset: py, animated: false })
            setTimeout(() => {
                scrollRef.current.scrollToLocation({
                    animated: false,
                    itemIndex: 0,
                    sectionIndex: filter.index,
                    viewPosition: 0,
                })
            }, 100);
        }
    }, [faild, filter])
    return (
        <>
            {
                loading && <ActivityIndicator size="large" style={{ position: 'absolute', flex: 1, zIndex: 999, backgroundColor: '#00000050', width: widthPercentageToDP('100%'), height: heightPercentageToDP('100%') }} />
            }
            {/* {
                <View style={{ flex: 0, backgroundColor: mainColor, height: insets.top > 4 ? insets.top - 4 : insets.top }} />

            } */}
            <SafeAreaView />
            <View style={{ flex: 1, backgroundColor: backgroundColor }}>
                {
                    alert && (user?.uid && Object.keys(order?.items || {})?.length === 0 ? <Alert
                        title={strings.alert}
                        alert
                        cancelText={'dismiss'}
                        msg={strings.addItemsMsg}
                        onCancel={() => setAlert(false)} onConfirm={() => {
                            setAlert(false)
                        }} /> : user?.uid ? <Alert
                            title={strings.alert}
                            confirmText={strings.completeProfile}
                            msg={strings.completeProfileMsg}
                            onCancel={() => setAlert(false)} onConfirm={() => {
                                setAlert(false)
                                navigation.navigate('Choose your location')
                            }} /> : <Alert title={strings.alert}
                                msg={strings.loginMsg}
                                onConfirm={() => { setAlert(false); navigation.navigate('Login') }}
                                confirmText={strings.login}
                                onCancel={() => setAlert(false)} />)
                }
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: mainColor,
                    justifyContent: 'space-between',
                }}>

                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: mainColor,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingVertical: 16,
                        paddingHorizontal: 16
                    }}>
                        <TouchableOpacity
                            onPress={() => navigation.navigate('Home')}
                        >
                            <CustomImage uri={locale === 'en' ? BackSVG : ArrowRight} width={24} height={24} svg />

                        </TouchableOpacity>
                        <Text style={{
                            fontSize: 18,
                            fontFamily: 'semiBold',
                            paddingHorizontal: 16,
                        }}>
                            {
                                filterType === 'brand' ?
                                    (locale === 'en' ? brand?.name : (brand?.name_ar || brand?.name)) :
                                    filterType === 'category' ?
                                        (locale === 'en' ? helpers.categories?.find(r => r.id === categoryID)?.name
                                            : (helpers?.categories?.find(r => r.id === categoryID)?.name_ar || helpers?.categories?.find(r => r.id === categoryID)?.name))

                                        : strings.results

                            }
                        </Text>
                        <BasketBtn setAlert={() => setAlert(true)} />
                    </View>
                    <View style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', outline: 'none',
                        paddingHorizontal: 8,
                        marginHorizontal: 16,
                        height: 40,
                        // width: '100%',
                        borderRadius: 8,
                        color: '#fff',
                        backgroundColor: colorLightGrey,
                        marginBottom: 10
                    }}>
                        <CustomImage uri={SearchSVG} fill={colorTextDark} svg={true} width={20} height={20} />
                        <TextInput
                            placeholder={strings.search}
                            placeholderTextColor={colorTextDark}
                            onChangeText={(text) => setSearch(text.toLowerCase())}
                            style={{
                                // textAlign:'left',
                                outline: 'none',
                                paddingHorizontal: 8,
                                height: 40,
                                paddingVertical: 8,

                                width: '90%',
                                borderRadius: 8,
                                color: colorTextDark,
                            }} />
                    </View>
                </View>
                <View
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{
                        paddingBottom: 180
                    }}
                >
                    {
                        tags && <View style={{ width: 'auto', alignItems: 'flex-start', paddingTop: 16 }}>
                            <FlatList
                                data={tags}
                                // style={{ width: '100%' }}
                                horizontal={true}
                                contentContainerStyle={{ paddingHorizontal: 16, paddingBottom: 8, alignItems: 'flex-start' }}
                                showsHorizontalScrollIndicator={false}
                                getItemLayout={(data, index) => (
                                    { length: 65, offset: 65 * index, index }
                                )}
                                renderItem={(i) => <TagView
                                    viewType={viewType}
                                    updateView={(type) => setView(type)}
                                    data={i.item}
                                    filter={filter.id}
                                    updateFilter={async (id) => {
                                        setFilter({ id, index: i.index - 1 });
                                        setFaild(false)
                                        scrollRef.current.scrollToLocation({
                                            animated: false,
                                            itemIndex: 0,
                                            sectionIndex: i.index - 1,
                                            viewPosition: 0,
                                        })
                                        // elRefs.current[i.index - 1].measure((fx, fy, width, height, px, py) => {
                                        //     scrollRef.current.scrollTo({ x: 0, y: py - 200, animated: true })
                                        // })
                                    }}
                                />}
                                keyExtractor={item => item.id}
                            />
                        </View>
                    }

                    <SectionList

                        ref={scrollRef}
                        style={Platform.OS === 'web' ? { height: '100vh' } : {}}
                        initialNumToRender={3}
                        contentContainerStyle={{ paddingTop: 8, paddingHorizontal: 12, paddingBottom: 200 }}
                        sections={list}
                        removeClippedSubviews={true}
                        maxToRenderPerBatch={4}
                        keyExtractor={(item, index) => item + index}
                        onScrollToIndexFailed={(error) => {
                            setFaild({ errorIndex: error.index })
                        }}
                        renderItem={(i) =>
                            filterType === 'prdsCategory' ?
                                <View key={i.index}>
                                    {
                                        i.item.map(item => <View key={item.id} style={{ width: '100%' }}>
                                            <Record key={item} index={item.id} data={item} navigation={navigation} />
                                        </View>
                                        )
                                    }
                                </View>
                                :
                                <View key={i.index} >
                                    {
                                        viewType === 'list' ? <View key={i.item.id}>
                                            {
                                                i.item.map(item => <View key={item.id} style={{ width: '100%' }}>
                                                    <ProductCardList key={item.id} index={item.id} data={item || {}} />
                                                </View>)
                                            }
                                        </View> :
                                            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 270, marginBottom: 20 }}>
                                                {
                                                    i.item.map(item => <View key={item.id} style={{ width: '50%' }}>
                                                        <ProductCard key={item.id} index={item.id} data={item || {}} />
                                                    </View>)
                                                }
                                            </View>
                                    }
                                </View>
                        }
                        stickySectionHeadersEnabled={false}
                        renderSectionHeader={({ section: { name, name_ar, index } }) => {
                            if (filterType === 'prdsCategory') {
                                return null
                            }
                            return (
                                <View ref={el => elRefs.current[index] = el} >
                                    <Text style={{ fontSize: 18, textTransform: 'capitalize', fontFamily: 'semiBold', paddingHorizontal: filterType !== 'prdsCategory' ? 8 : 0, paddingTop: filterType !== 'prdsCategory' ? 24 : 0, textAlign: 'left' }}>{locale === 'en' ? name : name_ar || name}</Text>
                                </View>
                            )
                        }}
                    />
                    {/* {
                        list?.map((rec, i) => <View key={rec.id} ref={el => elRefs.current[i] = el}>
                            <Text style={{ fontSize: 18, textTransform: 'capitalize', fontFamily: 'semiBold', paddingHorizontal: filterType !== 'prdsCategory' ? 16 : 0, paddingTop: filterType !== 'prdsCategory' ? 24 : 0, textAlign: 'left' }}>{locale === 'en' ? rec.name : rec.name_ar || rec.name}</Text>
                            {
                                (filterType === 'prdsCategory' ?
                                    <FlatList
                                        data={rec.data}
                                        style={{ flex: 1, backgroundColor: backgroundColor }}
                                        renderItem={(i) => <Record key={i.item} index={i.item.id} data={i.item} navigation={navigation} />}
                                        keyExtractor={item => item.id}
                                    />
                                    :
                                    <FlatList
                                        key={rec.id + viewType}
                                        listKey={rec.id}
                                        initialNumToRender={3}
                                        contentContainerStyle={{ paddingTop: 8, paddingHorizontal: 12 }}
                                        data={rec.data}
                                        showsHorizontalScrollIndicator={false}
                                        // scrollEnabled={false}
                                        numColumns={viewType === 'list' ? 1 : 2}
                                        renderItem={(i) => {
                                            return (
                                                <View key={i.index + rec?.id} style={{ width: viewType === 'list' ? '100%' : '50%' }} >
                                                    {
                                                        viewType === 'list' ? <ProductCardList key={i.item?.key + rec.id} index={i?.item?.id} data={i.item} /> : <ProductCard key={i.item?.key + rec.id} index={i?.item?.id} data={i.item} />
                                                    }
                                                </View>
                                            )
                                        }}
                                        keyExtractor={item => item?.id}
                                    />
                                )}

                        </View>)
                    } */}

                </View>
            </View>
        </>
    );
};
const mapStateToProps = ({ helpers, order, user }) => {
    return { helpers, order, user }
}

export default connect(mapStateToProps, {})(ResultsView);