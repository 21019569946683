import { createAction } from 'redux-action';
const SET_LOADING = 'SET_LOADING';
const SET_SERVING_AREAS = 'SET_SERVING_AREAS';
const SET_PICKUP_AREAS = 'SET_PICKUP_AREAS';
const SET_CURRENCY = 'SET_CURRENCY';

const SET_APP_PLACES = 'SET_APP_PLACES';
const SET_COUNTRY = 'SET_COUNTRY';

const SET_ALERT = 'SET_ALERT';
const SET_LOGIN = 'SET_LOGIN';
const ORDER_RATE_DISMISSED = 'ORDER_RATE_DISMISSED';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_GHADENI_STATUS = 'SET_GHADENI_STATUS';

export const SetLoading = createAction(SET_LOADING, (data) => {
    return data
});

export const SetAlertMessage = createAction(SET_ALERT, (data) => {
    return data
});

export const SetServingAreas = createAction(SET_SERVING_AREAS, (data) => {
    return data
});
export const SetPickupAreas = createAction(SET_PICKUP_AREAS, (data) => {
    return data
});
export const SetAppPlaces = createAction(SET_APP_PLACES, (data) => {
    return data
});

export const SetLoginRequired = createAction(SET_LOGIN, (data) => {
    return data
});


const initial = {
    loading: false,
    currency: 'AED',
    // country: 'ae'
};
const getPolygons = (servingAreas, places) => {
    let places_ids = (Object.keys(servingAreas || {}) || [])?.reduce((val, currentKey) => {
        let currentVal = servingAreas[currentKey];
        if (currentVal?.active) {
            let list = currentVal?.areas_list?.map(r => {
                return { cluster: currentKey, ...r }
            }
            )
            val = val.concat(list?.filter(r => !!r) || [])
        }
        return val
    }, [])

    let polygons = places_ids?.map(r => {
        let placeData = places?.find(rec => rec?.id === r?.id);
        return { ...placeData, cluster: r.cluster }
    }
    )
    return polygons
}

let ex = (state = initial, action) => {
    let polygons = [];
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload }

        case SET_SERVING_AREAS:
            polygons = getPolygons(action.payload?.allLocations, state?.places)
            return { ...state, ...action.payload, polygons }

        case SET_ALERT:
            return { ...state, alertObject: action.payload }

        case SET_LOGIN:
            return { ...state, loginRequired: action.payload }
        case SET_APP_PLACES:
            polygons = getPolygons(state?.allLocations, action.payload)
            return { ...state, places: action.payload, polygons }
        case SET_PICKUP_AREAS:
            return { ...state, pickupLocations: action.payload }
        case SET_CURRENCY:
            return { ...state, currency: action.payload }
        case SET_COUNTRY:
            let currency = action.payload === 'sa' ? 'SAR' : 'AED';
            return { ...state, country: action.payload, currency }
        case SET_SETTINGS:
            return { ...state, ...action.payload }
        case SET_GHADENI_STATUS:
            return { ...state, ghadeniStatus: action.payload }
        case ORDER_RATE_DISMISSED:
            return { ...state, orderRateDismissed: action.payload }
        default:
            return state;
    }
};
export default ex